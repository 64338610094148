<template>
    <AccountLayout>
        <template v-slot:title>
            <h2>Edit profile</h2>
        </template>
        <template v-slot:content>
            <div class="profile-edit">
                <form>
                    <div class="profile-edit-form">

                        <div class="profile-edit-account">
                            <div class="profile-edit-account-data">
                                <div class="profile-edit-account-data-avatar">
                                    <div class="img-upload">
                                        <label for="avatar">
                                            <avatar :img="form.avatar"/>
                                        </label>
                                        <input id="avatar" type="file" @change="uploadFile">
                                        <div class="img-upload-info">Click to change</div>
                                    </div>
                                </div>
                                <div class="profile-edit-account-data-title">
                                    <div class="name">
                                        {{User.login}}
                                    </div>
                                    <div class="subscribe">
                                        {{User.partnerProgramName}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-edit-form-data">
                            <div class="form-input-group" :class="{ 'form-group--error': $v.form.email.$error }">
                                <label for="email" class="input-title">Email</label>
                                <input id="email"
                                       type="email"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.email.$model"
                                       :class="{ 'active-input': $v.form.telegramm.$model}"
                                >
                                <div class="desc">
                                    <label class="desc-text" v-if="!$v.form.email.minLength || !$v.form.email.required">Please
                                        enter your email</label>
                                </div>
                            </div>
                            <div class="form-input-group" :class="{ 'form-group--error': $v.form.telegramm.$error }">
                                <label for="telegram" class="input-title">Telegram</label>
                                <input id="telegram"
                                       type="text"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.telegramm.$model"
                                       :class="{ 'active-input': $v.form.telegramm.$model}"
                                >
                                <div class="desc">
                                    <label class="desc-text"
                                           v-if="!$v.form.telegramm.minLength || !$v.form.telegramm.required">
                                        Please enter your telegram
                                    </label>
                                </div>
                            </div>

                            <div class="form-input-group minPay"
                                 :class="{ 'form-group--error': $v.form.minPayment.$error }">
                                <label for="minPay" class="input-title">Min pay</label>
                                <input id="minPay"
                                       type="text"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.minPayment.$model"
                                       :class="{ 'active-input': $v.form.minPayment.$model}"
                                >
                                <div class="value">BTC</div>
                                <div class="desc">
                                    <label class="desc-text"
                                           v-if="!$v.form.minPayment.required || !$v.form.minPayment.minPayAmount">
                                        Please enter min pay > 0.005 BTC
                                    </label>
                                </div>
                            </div>

                            <div class="form-input-group" :class="{ 'form-group--error': $v.form.address.$error }">
                                <label for="address" class="input-title">Bitcoin address</label>
                                <input id="address"
                                       type="text"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.address.$model"
                                       :class="{ 'active-input': $v.form.address.$model}"
                                >
                                <div class="desc">
                                    <label class="desc-text" v-if="!$v.form.address.required">
                                        Please enter your Bitcoin address
                                    </label>
                                </div>
                            </div>

                            <div class="buttons" :class="{ 'right': isEditPassword }">
                                <router-link :to="{ name: 'Change Password' }" class="btn-change-password">
                                    <img alt="Vue logo" src="~@/assets/img/lock.svg">
                                    Change password
                                </router-link>
                                <button class="btn-edit"
                                        @click.prevent="confirmEdit"
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </template>
    </AccountLayout>
</template>

<script>
    import {email, required, minLength} from 'vuelidate/lib/validators'
    import AccountLayout from "../../components/AccountLayout";
    import Avatar from "../../components/Avatar";
    import UserService from "../../services/user.service"
    import {MAIN_DOMAIN} from "../../store/config";


    export default {
        name: "EditProfile",
        components: {Avatar, AccountLayout},
        data() {
            return {
                file: '',
                passwordType: 'password',
                isEditPassword: false,
                mainDomain: MAIN_DOMAIN,
                form: {
                    email: '',
                    telegramm: '',
                    address: '',
                    avatar: '',
                    submitStatus: null,
                    oldPassword: '',
                    newPassword: '',
                    password_confirm: '',
                    minPayment: '',
                },
            }
        },
        validations: {
            form: {
                email: {
                    email,
                    required
                },
                telegramm: {
                    required,
                    minLength: minLength(6)
                },
                minPayment: {
                    required, minPayAmount(value) {
                        return value >= 0.005;
                    }

                },
                address: {
                    required,
                }
            }
        },
        computed: {
            User: {
                get() {
                    return this.$store.state.user;
                },
                set(value) {
                    this.$store.commit('setter', {user: value});
                },
            }
        },
        mounted() {
            setTimeout(() => {
                this.updateFormData();
            }, 1000)
        },
        methods: {
            confirmEdit() {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    this.$store.dispatch('editProfile', this.form).then(() => {
                        this.$router.push({name: 'Profile'})
                    })
                }
            },
            uploadFile(e) {
                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }
                this.addImage(files[0]);
            },
            addImage(file) {
                const reader = new FileReader();

                reader.onload = () => {
                    this.file = file;
                    this.uploadAvatar();
                };
                reader.readAsBinaryString(file);

            },
            uploadAvatar() {
                UserService.uploadFile(this.file).then((result) => {
                    this.form.avatar = result.data.result;
                })
            },
            updateFormData() {
                this.form.email = this.User.partnerProfile.email;
                this.form.avatar = this.User.partnerProfile.avatar;
                this.form.minPayment = this.User.partnerProfile.minPayment;
                this.form.address = this.User.partnerProfile.address;
                this.form.telegramm = this.User.partnerProfile.telegramm;
            },
        },
    }
</script>

<style lang="scss">
    .profile {
        &-edit {
            max-width: 536px;
            margin: 40px auto 0;

            @media (max-width: 764px) {
                margin: 0 !important;
            }

            &-form {
                background: white;
                border-radius: 8px;
                border: 1px solid #DFE0EB;

                &-data {
                    padding: 0 48px 48px 48px;

                    @media (max-width: 764px) {
                        padding: 20px !important;
                    }

                    input:focus {
                        outline: none !important;
                        border: none;
                        box-shadow: inset 0 0 0 1.5px #673DFD;
                        transition: .3s ease-in-out;
                    }

                    .minPay {
                        position: relative;
                        width: 100%;

                        input {
                            padding-right: 50px;
                        }

                        .value {
                            position: absolute;
                            right: 10px;
                            top: 31px;
                            font-size: 14px;
                            line-height: 18px;
                            color: #9FA2B4;

                        }
                    }

                    .form-input-group {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 28px;
                        position: relative;

                        &:last-child {
                            padding-bottom: 48px;
                        }

                        .active-input {
                            background: rgba(103, 61, 253, 0.03);
                        }

                        .input-title {
                            font-size: 14px;
                            color: #673DFD;
                            padding-bottom: 8px;
                            line-height: 1;
                        }

                        .desc {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            &-text {
                                font-size: 12px;
                                color: #9FA2B4;
                                padding-top: 6px;
                                line-height: 1;
                            }

                            .errors {
                                display: none;
                            }

                            .forgot {
                                font-size: 12px;
                                color: #673DFD;
                                padding-top: 6px;
                                line-height: 1;
                            }
                        }

                        .icon {
                            position: absolute;
                            right: 20px;
                            top: 26px;

                            &-hide {
                                opacity: 0.5;
                            }
                        }
                    }

                    .margin {
                        margin-top: 30px;
                    }

                    .form-group--error {

                        .input-title {
                            color: #FF783E;
                        }

                        .form-control {
                            box-shadow: inset 0 0 0 1.5px #FF783E;
                            transition: .3s ease-in-out;
                            border: none;
                        }

                        .errors {
                            display: block;
                            color: #FF783E;
                            font-size: 12px;
                            padding-top: 6px;
                            line-height: 1;
                        }

                        .desc {
                            &-text {
                                color: #FF783E;
                            }
                        }

                        .icon {
                            img {
                                filter: invert(53%) sepia(56%) saturate(1015%) hue-rotate(333deg) brightness(101%) contrast(101%);
                            }
                        }
                    }

                    .buttons {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .btn-edit {
                            background: #673DFD;
                            color: white;
                            padding: 14px 29px;
                            border: none;
                            border-radius: 4px;
                            font-size: 14px;
                        }

                        .btn-change-password {
                            padding: 14px 29px;
                            color: #673DFD;
                            text-align: center;
                            font-size: 14px;
                            background: none;
                            border: none;
                            outline: none;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            @media (max-width: 764px) {
                                padding: 0 !important;
                            }

                            img {
                                padding-right: 8px;
                            }
                        }
                    }

                    .right {
                        justify-content: end;
                    }
                }
            }

            &-account {
                padding: 32px 48px;

                @media (max-width: 764px) {
                    padding: 20px !important;
                }

                &-data {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &-avatar {
                        .img-upload {
                            img {
                                max-width: 80px;
                                width: 100%;
                                height: 80px;
                                cursor: pointer;
                            }

                            &-info {
                                font-size: 12px;
                                color: #9FA2B4;
                                padding-top: 8px;
                            }
                        }

                        .img-upload > input {
                            display: none;
                        }
                    }

                    &-avatar img {
                        -webkit-border-radius: 90px;
                        -moz-border-radius: 90px;
                        border-radius: 90px;
                        -khtml-border-radius: 90px;
                        border: 2px solid #02C076;
                        box-shadow: inset 0 0 0 3px white;
                        height: 100px;
                        max-width: 100px;
                        width: 100%;
                    }

                    &-title {
                        padding-left: 28px;

                        .name {
                            font-size: 20px;
                            font-weight: 700;
                            padding-bottom: 8px;
                            line-height: 24px;
                        }

                        .subscribe {
                            color: #02C076;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 0.2px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
</style>