<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9417 12.4667C15.3236 12.4667 15.6635 12.3177 15.9615 12.0198C16.2594 11.7219 16.4083 11.3743 16.4083 10.9771C16.4083 10.5951 16.2594 10.259 15.9615 9.96875C15.6635 9.67847 15.3236 9.53333 14.9417 9.53333C14.5597 9.53333 14.2198 9.67847 13.9219 9.96875C13.624 10.259 13.475 10.5951 13.475 10.9771C13.475 11.3743 13.624 11.7219 13.9219 12.0198C14.2198 12.3177 14.5597 12.4667 14.9417 12.4667ZM4.125 16.6604V17.875V4.125V16.6604ZM4.125 19.25C3.77361 19.25 3.4566 19.1125 3.17396 18.8375C2.89132 18.5625 2.75 18.2417 2.75 17.875V4.125C2.75 3.77361 2.89132 3.4566 3.17396 3.17396C3.4566 2.89132 3.77361 2.75 4.125 2.75H17.875C18.2417 2.75 18.5625 2.89132 18.8375 3.17396C19.1125 3.4566 19.25 3.77361 19.25 4.125V7.19583H17.875V4.125H4.125V17.875H17.875V14.8271H19.25V17.875C19.25 18.2417 19.1125 18.5625 18.8375 18.8375C18.5625 19.1125 18.2417 19.25 17.875 19.25H4.125ZM12.3292 15.2854C11.8097 15.2854 11.3972 15.1326 11.0917 14.8271C10.7861 14.5215 10.6333 14.1167 10.6333 13.6125V8.41042C10.6333 7.89097 10.7861 7.48229 11.0917 7.18438C11.3972 6.88646 11.8097 6.7375 12.3292 6.7375H18.5167C19.0361 6.7375 19.4486 6.88646 19.7542 7.18438C20.0597 7.48229 20.2125 7.89097 20.2125 8.41042V13.6125C20.2125 14.1167 20.0597 14.5215 19.7542 14.8271C19.4486 15.1326 19.0361 15.2854 18.5167 15.2854H12.3292ZM18.8375 13.9104V8.1125H12.0083V13.9104H18.8375Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagWallets"
}
</script>

<style scoped>

</style>
