<template>
  <div class="api-block">
    <div class="title">
      Get Rate
    </div>
    <div class="desc">
      This request is using API Key from collection partner
    </div>
    <div class="table-title">
      Parameters:
    </div>
    <div class="table_block">
      <table class="table table_parameters">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required or optional</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>From</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>To</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>Mode</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>From Network</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>To Network</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>Mode</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        <tr>
          <td>Amount Result</td>
          <td>number</td>
          <td>required</td>
          <td>*</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="getRate">curl --location --request GET 'https://api.swapuz.com/api/home/v1/rate/?from=BTC&to=BNB&amount=1&mode=fix&fromNetwork=BTC&toNetwork=ETH&amountResult=2'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>

    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json">{
  "result": {
    "result": 70.0373204,
    "amount": 1,
    "rate": 70.0373204,
    "withdrawFee": 0,
    "minAmount": 0.00514,
    "maxAmount": 5.14288
  },
  "status": 200
}</code></pre>
    </div>
  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "GetRate",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("getRate").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style lang="scss">

</style>
