<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_558_17157)">
      <path d="M18 2H9C7.9 2 7 2.9 7 4V16C7 17.1 7.9 18 9 18H18C19.1 18 20 17.1 20 16V4C20 2.9 19.1 2 18 2ZM18 16H9V4H18V16ZM3 15V13H5V15H3ZM3 9.5H5V11.5H3V9.5ZM10 20H12V22H10V20ZM3 18.5V16.5H5V18.5H3ZM5 22C3.9 22 3 21.1 3 20H5V22ZM8.5 22H6.5V20H8.5V22ZM13.5 22V20H15.5C15.5 21.1 14.6 22 13.5 22ZM5 6V8H3C3 6.9 3.9 6 5 6Z" fill="white" fill-opacity="0.38"/>
    </g>
    <defs>
      <clipPath id="clip0_558_17157">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "copyText"
}
</script>

<style scoped>

</style>
