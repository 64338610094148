<template>
  <div class="footer">
    <div class="container-l ">
      <div class="ft-row">
        <div class="ft-col logo-wrap">
          <router-link :to="{name: 'Home'}" class="logo">
            <logo-desktop/>
          </router-link>
          <div class="ft-text">
            Do you want to make operations with Swapuz as handy as possible? Add it to bookmarks in one click!
          </div>
        </div>
        <div class="ft-col links-group">
          <div class="ft-link-list">
            <span>Company</span>
            <a href="https://swapuz.com/about-us/">About</a>
            <a href="https://swapuz.com/contact/">Contacts</a>
          </div>
          <div class="ft-link-list">
            <span>Support</span>
            <router-link :to="{name: 'HowToUse'}">How to use</router-link>
          </div>
        </div>
        <div class="ft-col social-links-wrap">
          <span>Follow us</span>
          <div class="social-links">
            <a href="https://www.linkedin.com/company/swapuz" target="_blank" rel="nofollow">
              <svg width="20" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="#1B1A1C"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
              </svg>
            </a>
            <a href="https://twitter.com/Swapuz_team" target="_blank" rel="nofollow">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.25078 17.1292C13.7984 17.1292 17.9258 10.8762 17.9258 5.45417C17.9258 5.27656 17.9221 5.09971 17.9142 4.92378C18.7153 4.34455 19.4117 3.62174 19.961 2.79898C19.2258 3.12583 18.4345 3.34571 17.6046 3.44504C18.4517 2.93692 19.1022 2.13339 19.4089 1.17545C18.6161 1.64542 17.7381 1.98691 16.8033 2.17139C16.0546 1.37381 14.9884 0.875 13.808 0.875C11.5421 0.875 9.70446 2.71262 9.70446 4.97779C9.70446 5.29991 9.74047 5.61302 9.81081 5.91346C6.40047 5.7418 3.37632 4.10911 1.35269 1.62619C1.00037 2.23258 0.797121 2.93708 0.797121 3.68873C0.797121 5.11222 1.52161 6.36909 2.62314 7.10426C1.94992 7.08351 1.3176 6.89873 0.764772 6.59111C0.764162 6.60835 0.764162 6.62513 0.764162 6.64344C0.764162 8.63075 2.1785 10.29 4.05625 10.666C3.7114 10.7598 3.34855 10.8103 2.97425 10.8103C2.71027 10.8103 2.45301 10.7844 2.20292 10.7363C2.72538 12.3667 4.24027 13.5531 6.03624 13.5864C4.63182 14.6871 2.86256 15.3428 0.939791 15.3428C0.60898 15.3428 0.282136 15.3239 -0.0390625 15.286C1.77689 16.45 3.93342 17.1293 6.25093 17.1293"
                    fill="#1B1A1C"/>
              </svg>
            </a>
            <a href="https://t.me/swapuz" target="_blank" rel="nofollow">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.81232 10.396L7.2998 16.6143L10.5382 13.3759L16.0905 17.7891L20 0.210938L0 8.54163L4.81232 10.396ZM14.285 5.25427L8.1633 10.8379L7.40082 13.7116L5.99228 10.1896L14.285 5.25427Z"
                    fill="#1B1A1C"/>
              </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCly2yn0VN2ijlD9lZAVAINg" target="_blank">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.0853 2.30838C18.8672 1.4872 18.2246 0.840467 17.4088 0.620987C15.9299 0.222168 10 0.222168 10 0.222168C10 0.222168 4.07019 0.222168 2.59133 0.620987C1.77546 0.840501 1.13289 1.4872 0.914805 2.30838C0.518555 3.79682 0.518555 6.90231 0.518555 6.90231C0.518555 6.90231 0.518555 10.0078 0.914805 11.4962C1.13289 12.3174 1.77546 12.9372 2.59133 13.1567C4.07019 13.5555 10 13.5555 10 13.5555C10 13.5555 15.9299 13.5555 17.4088 13.1567C18.2246 12.9372 18.8672 12.3174 19.0853 11.4962C19.4815 10.0078 19.4815 6.90231 19.4815 6.90231C19.4815 6.90231 19.4815 3.79682 19.0853 2.30838ZM8.06064 9.72186V4.08276L13.0169 6.90238L8.06064 9.72186Z"
                    fill="#1B1A1C"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="ft-row">
        <span class="copyright">Copyright © 2020 - 2022 Swapuz!</span>
        <div class="ft-terms">
          <a class="term-of-use" href="https://swapuz.com/terms-of-use/">Terms of Use</a>
          <a class="privacy-policy" href="https://swapuz.com/privacy-policy/">Privacy policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoDesktop from "@/assets/img/svg/logoDesktop.vue";

export default {
  name: 'SiteFooter',
  components: {LogoDesktop}
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 320px;
  background: #29282A;
  box-sizing: border-box;
  padding-top: 48px;

  @media (max-width: 1079px) {
    margin-top: 0;
    padding: 40px 32px 48px;
    height: auto;
    border-radius: 24px 24px 0px 0px;
  }

  .container-l {
    max-width: 1080px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .logo {
    color: #fff;
    text-decoration: none;
    font-weight: 900;
    font-size: 34px;

    img {
      width: 46px;
      position: relative;
      top: 4px;
      margin-right: 15px;
    }

    &_symbol {
      color: #02C076;
    }
  }

  .ft-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #EEEDEE;
    margin-top: 35px;
  }

  .ft-row {
    display: flex;
    @media (max-width: 1079px) {
      max-width: 300px;
      margin: auto;
      flex-direction: column;
    }

    & + .ft-row {
      margin-top: 32px;
    }

    .ft-col {
      &.logo-wrap {
        max-width: 400px;
        @media (max-width: 1079px) {
          text-align: center;
        }
      }

      &.links-group {
        display: flex;
        margin-left: auto;

        @media (max-width: 1079px) {
          margin-top: 32px;
          gap: 40px;
          order: 9;
        }
      }

      &.social-links-wrap {
        display: flex;
        flex-direction: column;
        margin-left: 48px;
        @media (max-width: 1079px) {
          margin-left: 0;
          margin-top: 20px;
          text-align: center;
        }

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.38);
        }
      }
    }

    .ft-terms {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 22px;
      flex-wrap: wrap;
      margin-left: 32px;

      a {
        position: relative;

        &:after {
          content: '';
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -2px;
          border-width: 0 0 1px;
          border-style: solid;
          color: rgba(255, 255, 255, 0.38);
        }
      }

      @media (max-width: 1079px) {
        margin-left: 0;
      }
    }
  }

  .social-links {
    display: flex;
    margin-top: 16px;

    @media (max-width: 1079px) {
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 12px;
      text-decoration: none;
      background-color: #02C076;

      & + a {
        margin-left: 12px;
      }
    }

    &-wrap {
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }

  .ft-link-list {
    display: flex;
    flex-direction: column;
    min-width: 120px;

    @media (max-width: 1079px) {
      text-align: center;
    }

    span {
      display: block;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.38);
      margin-bottom: 12px;
    }

    a {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      text-decoration: none;
      margin-bottom: 12px;
      display: block;
    }
  }

  .copyright {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.38);
    @media (max-width: 1079px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .term-of-use,
  .privacy-policy {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #02C076;
    text-decoration-color: rgba(255, 255, 255, 0.38);
  }
}
</style>
