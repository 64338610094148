<template>
  <div class="api-block last">
    <div class="title">
      List Transactions
    </div>
    <div class="table-title">
      Type status:
    </div>
    <div class="table_block">
      <table class="table">
        <thead>
        <tr>
          <th>Number</th>
          <th>Status</th>

        </tr>
        </thead>
        <tbody>
        <tr>
          <td>0</td>
          <td>Remaining to send funds</td>
        </tr>
        <tr>
          <td>1-5</td>
          <td>Transferring</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Completed</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Overdue</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="listTransactions">curl --location -g --request GET 'https://api.swapuz.com/api/partner/partnerPaginator?page={page}'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>
    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json">{
  "result": {
    "maxPage": 0,
    "currentPage": 0,
    "result": [
      {
        "id": 0,
        "createDate": "2022-12-22T00:09:56.865Z",
        "from": "string",
        "to": "string",
        "partnerFee": 0,
        "amountResult": 0,
        "amount": 0,
        "amountBTC": 0,
        "startAmount": 0,
        "status": 0,
        "withdrawalTransactionID": "string",
        "wTxId": "string",
        "depositTransactionID": "string",
        "dTxId": "string"
      }
    ]
  },
  "status": 0
}</code></pre>
    </div>
  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "ListTransactions",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("listTransactions").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style lang="scss">
</style>
