<script>
export default {
  name: "okxLogo"
}
</script>

<template>
  <svg width="133" height="48" viewBox="0 0 133 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3258_148)">
      <path d="M47.1679 11H20.6175C20.4537 11 20.2966 11.0609 20.1808 11.1692C20.0651 11.2776 20 11.4245 20 11.5778V36.4212C20 36.5745 20.0651 36.7216 20.1808 36.83C20.2966 36.9381 20.4537 36.999 20.6175 36.999H47.1679C47.3319 36.999 47.4888 36.9381 47.6047 36.83C47.7205 36.7216 47.7856 36.5745 47.7856 36.4212V11.5778C47.7856 11.4245 47.7205 11.2776 47.6047 11.1692C47.4888 11.0609 47.3319 11 47.1679 11ZM38.5237 27.755C38.5237 27.9082 38.4586 28.0551 38.3428 28.1635C38.227 28.2718 38.07 28.3327 37.9062 28.3327H29.8793C29.7155 28.3327 29.5585 28.2718 29.4427 28.1635C29.3269 28.0551 29.2618 27.9082 29.2618 27.755V20.2441C29.2618 20.0909 29.3269 19.9439 29.4427 19.8356C29.5585 19.7272 29.7155 19.6664 29.8793 19.6664H37.9062C38.07 19.6664 38.227 19.7272 38.3428 19.8356C38.4586 19.9439 38.5237 20.0909 38.5237 20.2441V27.755Z" fill="white"/>
      <path d="M102.75 19.667H94.7234C94.3826 19.667 94.106 19.9257 94.106 20.2448V27.7556C94.106 28.0747 94.3826 28.3333 94.7234 28.3333H102.75C103.091 28.3333 103.368 28.0747 103.368 27.7556V20.2448C103.368 19.9257 103.091 19.667 102.75 19.667Z" fill="white"/>
      <path d="M93.4904 11H85.4636C85.1226 11 84.8462 11.2587 84.8462 11.5778V19.0886C84.8462 19.4077 85.1226 19.6664 85.4636 19.6664H93.4904C93.8315 19.6664 94.1081 19.4077 94.1081 19.0886V11.5778C94.1081 11.2587 93.8315 11 93.4904 11Z" fill="white"/>
      <path d="M112.018 11H103.99C103.65 11 103.373 11.2587 103.373 11.5778V19.0886C103.373 19.4077 103.65 19.6664 103.99 19.6664H112.018C112.359 19.6664 112.635 19.4077 112.635 19.0886V11.5778C112.635 11.2587 112.359 11 112.018 11Z" fill="white"/>
      <path d="M93.4904 28.334H85.4636C85.1226 28.334 84.8462 28.5926 84.8462 28.9117V36.4227C84.8462 36.7417 85.1226 37.0004 85.4636 37.0004H93.4904C93.8315 37.0004 94.1081 36.7417 94.1081 36.4227V28.9117C94.1081 28.5926 93.8315 28.334 93.4904 28.334Z" fill="white"/>
      <path d="M112.018 28.334H103.99C103.65 28.334 103.373 28.5926 103.373 28.9117V36.4227C103.373 36.7417 103.65 37.0004 103.99 37.0004H112.018C112.359 37.0004 112.635 36.7417 112.635 36.4227V28.9117C112.635 28.5926 112.359 28.334 112.018 28.334Z" fill="white"/>
      <path d="M79.5869 11H71.56C71.219 11 70.9424 11.2587 70.9424 11.5778V19.0886C70.9424 19.4077 71.219 19.6664 71.56 19.6664H79.5869C79.9279 19.6664 80.2043 19.4077 80.2043 19.0886V11.5778C80.2043 11.2587 79.9279 11 79.5869 11Z" fill="white"/>
      <path d="M79.5869 28.334H71.56C71.219 28.334 70.9424 28.5926 70.9424 28.9117V36.4227C70.9424 36.7417 71.219 37.0004 71.56 37.0004H79.5869C79.9279 37.0004 80.2043 36.7417 80.2043 36.4227V28.9117C80.2043 28.5926 79.9279 28.334 79.5869 28.334Z" fill="white"/>
      <path d="M70.9398 20.2376C70.9398 20.0844 70.8747 19.9374 70.759 19.8291C70.6432 19.7207 70.4861 19.6599 70.3224 19.6599H61.6779V11.5778C61.6779 11.4245 61.6128 11.2776 61.4971 11.1692C61.3813 11.0609 61.2242 11 61.0605 11H53.0337C52.8697 11 52.7129 11.0609 52.5969 11.1692C52.4812 11.2776 52.416 11.4245 52.416 11.5778V36.4083C52.416 36.5616 52.4812 36.7085 52.5969 36.8168C52.7129 36.9252 52.8697 36.9861 53.0337 36.9861H61.0605C61.2242 36.9861 61.3813 36.9252 61.4971 36.8168C61.6128 36.7085 61.6779 36.5616 61.6779 36.4083V28.3262H70.3224C70.4861 28.3262 70.6432 28.2653 70.759 28.157C70.8747 28.0486 70.9398 27.9017 70.9398 27.7485V20.2376Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_3258_148">
        <rect width="92.6347" height="26" fill="white" transform="translate(20 11)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped>

</style>
