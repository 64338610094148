<template>
  <svg width="200" height="23" viewBox="0 0 200 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.442 16.9771C42.442 16.1509 42.1492 15.519 41.5632 15.0816C40.9768 14.6345 39.922 14.1679 38.3981 13.6818C36.8743 13.1861 35.6679 12.7 34.779 12.2237C32.3565 10.9211 31.1452 9.16651 31.1452 6.95991C31.1452 5.81288 31.4676 4.7922 32.1123 3.89787C32.7667 2.99384 33.6996 2.28909 34.9109 1.78361C36.1319 1.27813 37.4995 1.02539 39.0135 1.02539C40.5372 1.02539 41.8952 1.30243 43.0868 1.85651C44.2788 2.40088 45.2016 3.17367 45.856 4.17491C46.5204 5.17616 46.8524 6.31348 46.8524 7.58691H42.4568C42.4568 6.61483 42.1492 5.86148 41.5336 5.32684C40.9184 4.78248 40.054 4.51028 38.9403 4.51028C37.8657 4.51028 37.0306 4.73872 36.4347 5.1956C35.8389 5.64276 35.5409 6.23572 35.5409 6.97447C35.5409 7.66467 35.8877 8.24303 36.5812 8.70963C37.2845 9.17623 38.3151 9.61367 39.6729 10.0219C42.1736 10.7704 43.9952 11.6988 45.1384 12.8069C46.2812 13.9151 46.8524 15.2955 46.8524 16.948C46.8524 18.7852 46.154 20.2287 44.7572 21.2786C43.3604 22.3187 41.48 22.8388 39.1161 22.8388C37.475 22.8388 35.9805 22.5423 34.6325 21.9493C33.2844 21.3466 32.2539 20.5252 31.5408 19.4851C30.8375 18.445 30.4858 17.2396 30.4858 15.869H34.8962C34.8962 18.2117 36.3028 19.383 39.1161 19.383C40.1612 19.383 40.9768 19.174 41.5632 18.7561C42.1492 18.3283 42.442 17.7354 42.442 16.9771Z" fill="white"/>
    <path d="M66.4582 16.4224L69.3298 1.31641H73.711L68.9782 22.5465H64.5534L61.0806 8.3445L57.6082 22.5465H53.183L48.4503 1.31641H52.8314L55.7178 16.3933L59.2346 1.31641H62.9562L66.4582 16.4224Z" fill="white"/>
    <path d="M87.4988 18.1722H79.7916L78.3264 22.5465H73.6524L81.594 1.31641H85.6672L93.6528 22.5465H88.9788L87.4988 18.1722ZM80.9784 14.629H86.312L83.6304 6.68227L80.9784 14.629Z" fill="white"/>
    <path d="M100.042 15.0664V22.5465H95.6462V1.31641H103.969C105.571 1.31641 106.977 1.60803 108.189 2.19127C109.41 2.77452 110.347 3.60564 111.002 4.68463C111.656 5.75391 111.983 6.97387 111.983 8.3445C111.983 10.4247 111.265 12.0675 109.83 13.2729C108.403 14.4686 106.425 15.0664 103.895 15.0664H100.042ZM100.042 11.5232H103.969C105.131 11.5232 106.015 11.251 106.621 10.7067C107.236 10.1623 107.544 9.38462 107.544 8.37366C107.544 7.33355 107.236 6.49271 106.621 5.85115C106.005 5.20955 105.155 4.87907 104.071 4.85963H100.042V11.5232Z" fill="white"/>
    <path d="M128.818 17.4583C128.818 16.5543 128.604 15.7912 128.174 15.1691C127.754 14.5372 127.085 13.9831 126.166 13.5068C125.258 13.0208 123.881 12.525 122.034 12.0195C120.188 11.5141 118.801 11.0037 117.873 10.4885C116.955 9.97335 116.281 9.37551 115.851 8.69507C115.421 8.01459 115.206 7.18835 115.206 6.21628C115.206 4.67068 115.861 3.42155 117.17 2.46892C118.479 1.50657 120.184 1.02539 122.284 1.02539C123.651 1.02539 124.877 1.29271 125.961 1.82735C127.055 2.35227 127.9 3.09591 128.496 4.05826C129.092 5.01088 129.39 6.08992 129.39 7.29527H128.599C128.599 5.7108 128.013 4.40335 126.84 3.37295C125.668 2.33283 124.149 1.81277 122.284 1.81277C120.408 1.81277 118.889 2.23076 117.727 3.06675C116.574 3.89301 115.998 4.93312 115.998 6.18712C115.998 7.46051 116.501 8.48607 117.507 9.26371C118.513 10.0414 120.174 10.7315 122.489 11.3343C124.804 11.9272 126.499 12.6174 127.573 13.4048C128.941 14.406 129.624 15.7475 129.624 17.4292C129.624 18.4985 129.322 19.4462 128.716 20.2725C128.11 21.089 127.251 21.7209 126.137 22.168C125.024 22.6152 123.773 22.8388 122.386 22.8388C120.843 22.8388 119.456 22.586 118.225 22.0805C116.994 21.5653 116.066 20.8363 115.441 19.8934C114.826 18.9504 114.518 17.8374 114.518 16.5543H115.309C115.309 18.2943 115.964 19.6455 117.272 20.6078C118.582 21.5702 120.286 22.0514 122.386 22.0514C124.252 22.0514 125.79 21.6285 127.002 20.7828C128.213 19.9274 128.818 18.8192 128.818 17.4583Z" fill="#FBFF5C"/>
    <path d="M134.592 13.5791V22.5465H133.8V1.31641H140.467C142.646 1.31641 144.336 1.86563 145.537 2.96407C146.748 4.0528 147.354 5.56923 147.354 7.51339C147.354 9.4381 146.763 10.9254 145.581 11.9752C144.409 13.0251 142.738 13.5597 140.57 13.5791H134.592ZM134.592 12.7917H140.467C142.392 12.7917 143.886 12.33 144.951 11.4065C146.025 10.4831 146.563 9.19506 146.563 7.54254C146.563 5.83171 146.025 4.49995 144.951 3.54732C143.886 2.58496 142.392 2.10378 140.467 2.10378H134.592V12.7917Z" fill="#FBFF5C"/>
    <path d="M161.039 16.2037H150.592L148.218 22.5465H147.354L155.34 1.31641H156.306L164.292 22.5465H163.428L161.039 16.2037ZM150.885 15.4164H160.746L155.823 2.26418L150.885 15.4164Z" fill="#FBFF5C"/>
    <path d="M182.065 16.0439C181.86 18.2311 181.103 19.9128 179.794 21.089C178.485 22.2555 176.707 22.8388 174.46 22.8388C172.946 22.8388 171.603 22.4596 170.431 21.7014C169.269 20.9335 168.365 19.8496 167.72 18.4499C167.076 17.0403 166.748 15.4315 166.739 13.6235V10.3427C166.739 8.53467 167.056 6.92103 167.691 5.5018C168.326 4.07284 169.23 2.96954 170.402 2.19188C171.584 1.41422 172.937 1.02539 174.46 1.02539C176.688 1.02539 178.46 1.60864 179.779 2.77513C181.098 3.94162 181.86 5.6136 182.065 7.79103H181.274C181.049 5.83716 180.365 4.35475 179.222 3.34379C178.08 2.32311 176.492 1.81277 174.46 1.81277C172.39 1.81277 170.714 2.59043 169.435 4.14575C168.165 5.70108 167.53 7.78615 167.53 10.4011V13.5214C167.53 15.1934 167.813 16.6758 168.38 17.9687C168.946 19.2518 169.757 20.253 170.812 20.9724C171.877 21.6917 173.093 22.0514 174.46 22.0514C176.502 22.0514 178.094 21.5508 179.237 20.5495C180.39 19.5483 181.069 18.0464 181.274 16.0439H182.065Z" fill="#FBFF5C"/>
    <path d="M198.506 12.0189H187.809V21.7592H200V22.5465H187.018V1.31641H200V2.10378H187.809V11.2316H198.506V12.0189Z" fill="#FBFF5C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4939 11.7469C22.4939 17.9584 17.4584 22.9939 11.2469 22.9939C5.03543 22.9939 0 17.9584 0 11.7469C0 5.53543 5.03543 0.5 11.2469 0.5C17.4584 0.5 22.4939 5.53543 22.4939 11.7469ZM17.9951 7.17563C17.9951 7.9771 17.3454 8.62682 16.5439 8.62682C15.7424 8.62682 15.0927 7.9771 15.0927 7.17563C15.0927 6.37415 15.7424 5.72439 16.5439 5.72439C17.3454 5.72439 17.9951 6.37415 17.9951 7.17563ZM11.1744 7.17555C12.6171 7.17555 13.7866 6.39587 13.7866 5.43407C13.7866 4.4723 12.6171 3.69262 11.1744 3.69262C9.7317 3.69262 8.56218 4.4723 8.56218 5.43407C8.56218 6.39587 9.7317 7.17555 11.1744 7.17555Z" fill="#FBFF5C"/>
  </svg>
</template>

<script>
export default {
  name: "swapspaceImg"
}
</script>

<style scoped>

</style>
