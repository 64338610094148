import service from '../plugins/request';

class OrderService {
    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getOrderList(response) {
        return service.get('/api/partner/lastOrder', response);
    }

    /**
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<T>>}
     */
    getOrderStatusById(id, status) {
        return service.patch(`/api/Order/${id}/status`, status);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    recalcTransactionById(id) {
        return service.get(`/api/Order/calc/${id}`);
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getEmptyDeposits() {
        return service.get('/api/Order/listDeposit');
    }

    /**
     * @param idOrder
     * @param idDeposit
     * @returns {Promise<AxiosResponse<T>>}
     */
    setDeposit({idOrder, idDeposit}) {
        return service.patch('/api/Order/setDeposit', {idOrder, idDeposit});
    }

    /**
     * @param id
     * @param order
     * @returns {Promise<AxiosResponse<T>>}
     */
    editOrderById(id, order) {
        return service.patch(`/api/Order/${id}/edit`, order);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getOrderById(id) {
        return service.get(`/api/Order/${id}`);
    }

}

export default new OrderService();
