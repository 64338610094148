<template>
  <landing-layout>
    <template #landing>
      <hero-block/>
      <LetsStart/>
      <achievements-block/>
      <tools-block/>
      <profit-calc/>
      <features-block/>
      <f-a-qpage/>
      <are-you-ready/>
      <partners-list />
      <div class="button_block">
        <router-link :to="{name: 'Login'}" class="button-mob">
          Join now
        </router-link>
      </div>
      <technical-partners />
    </template>
  </landing-layout>
</template>

<script>
import LandingLayout from "@/components/LandingLayout";
import HeroBlock from "@/pages/landing/partials/HeroBlock";
import LetsStart from "@/pages/landing/partials/LetsStart";
import AchievementsBlock from "@/pages/landing/partials/AchievementsBlock";
import AreYouReady from "@/pages/landing/partials/AreYouReady";
import FAQpage from "@/pages/landing/partials/FAQpage";
import ToolsBlock from "@/pages/landing/partials/ToolsBlock";
import ProfitCalc from "@/pages/landing/partials/ProfitCalc.vue";
import FeaturesBlock from "@/pages/landing/partials/FeaturesBlock.vue";
import PartnersList from "@/pages/landing/partials/PartnersList.vue";
import TechnicalPartners from "@/pages/landing/partials/TechnicalPartners.vue";

export default {
  name: "LandingPage",
  components: {
    TechnicalPartners,
    PartnersList,
    FeaturesBlock,
    ProfitCalc,
    ToolsBlock, FAQpage, AreYouReady, AchievementsBlock, LetsStart, HeroBlock, LandingLayout
  },
  mounted() {
    const item = document.querySelector('.button-mob');
    // eslint-disable-next-line no-self-assign
    window.addEventListener('resize', () => item.innerHTML = item.innerHTML);
  }
}
</script>

<style lang="scss">
.button_block {
  padding: 0 24px;
  width: 100%;
  display: none;
  margin-bottom: 48px;
  z-index: 999;
  position: sticky;
  bottom: 20px;

  @media (max-width: 1079px) {
    display: flex;
  }

  .button-mob {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #29282A;
    padding: 18px 0;
    width: 100%;
    background: #02C076;
    backdrop-filter: blur(13.5914px);
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(27, 26, 28, 0.64);
  }
}
</style>
