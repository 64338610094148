<template>
    <div class="language-select input">
        <div @click="showLangs()" class="language-select_selected">
            {{ getNameByCode(selectedLang) }}
            <select-arrow/>
        </div>
        <div v-if="isShowLangs" class="language-select_dropdown">
            <div v-for="(lang, index) in langs" :key="index"
                 class="language-select_dropdown--item"
                 :class="{'language-select_dropdown--item-active': lang.name === getNameByCode(selectedLang)}"
                 @click="changeLang(lang)">
                {{ lang.name }}

                <svg v-if="lang.name === getNameByCode(selectedLang)" width="24" height="24" viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_864_4286)">
                        <path d="M9.00012 16.2003L5.50012 12.7003C5.11012 12.3103 4.49012 12.3103 4.10012 12.7003C3.71012 13.0903 3.71012 13.7103 4.10012 14.1003L8.29012 18.2903C8.68012 18.6803 9.31012 18.6803 9.70012 18.2903L20.3001 7.70031C20.6901 7.31031 20.6901 6.69031 20.3001 6.30031C19.9101 5.91031 19.2901 5.91031 18.9001 6.30031L9.00012 16.2003Z"
                              fill="#0A68F7"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_864_4286">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>

import SelectArrow from "@/icons/selectArrow.vue";

export default {
    name: 'LanguageSelect',
    components: {SelectArrow},
    props: {
        selectedLang: {
            type: String
        }
    },
    data() {
        return {
            isShowLangs: false,
            langs: [
                {
                    name: "English",
                    code: 'en'
                },
                {
                    name: "Русский",
                    code: 'ru'
                },
                {
                    name: "Deutsch",
                    code: 'de'
                },
                {
                    name: "Español",
                    code: 'es'
                },
                {
                    name: "Français",
                    code: 'fr'
                },
                {
                    name: "Poland",
                    code: 'pl'
                },
            ]
        }
    },
    methods: {
        showLangs() {
            this.isShowLangs = !this.isShowLangs;
        },
        changeLang(el) {
            this.$emit('update:lang', el);
            this.showLangs()
        },
        getNameByCode(code) {
            const lang = this.langs.find((item) => item.code === code);
            return lang ? lang.name : '';
        },
    }
}

</script>

<style lang="scss">
.language-select {
  position: relative;
  z-index: 9;

  &_selected {
      cursor: pointer;
  }

  &_dropdown {
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(15, 0, 58, 0.12);
    border-radius: 12px;
    padding: 8px 0;
    width: 100%;

    &--item {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #1B1A1C;
      position: relative;
      padding: 8px 16px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        background: rgba(103, 61, 253, 0.03);
      }

      &-active {
          &:hover {
              background: none;
              cursor: default;
          }
      }

      svg {
        position: absolute;
        top: 8px;
        right: 12px;
      }
    }

  }
}
</style>
