import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../pages/auth/Login.vue'
// import Registration from "../pages/auth/Registration.vue";
import Forgot from "../pages/auth/Forgot.vue";
import Dashboard from '../pages/dashboard/Dashboard'
import Profile from '../pages/profile/Profile'
import EditProfile from '../pages/profile/EditProfile'
import Payments from '../pages/payments/Payments'
import ChangePassword from "../pages/profile/ChangePassword";
import Promo from "../pages/promo/Promo";
import LastTransactions from "../pages/lastTransactions/LastTransactions";
import Page404 from "../pages/page404/Page404";
import LandingPage from "@/pages/landing/LandingPage";
import SignIn from "@/pages/auth/partials/SignIn.vue";
import SignUp from "@/pages/auth/partials/SignUp.vue";
import ApiDocs from "@/pages/apiDocumentation/ApiDocs.vue";
import WidgetPage from "@/pages/widget/WidgetPage.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: LandingPage
        },
        // {
        //     path: '/login-2',
        //     name: 'Login Page',
        //     component: Login
        // },
        {
            path: '/login',
            name: 'Login',
            component: SignIn
        },
        {
            path: '/sign-up',
            name: 'Registration',
            component: SignUp
        },
        // {
        //     path: '/sign-up-2',
        //     name: 'SignIn',
        //     component: Registration
        // },
        {
            path: '/forgot',
            name: 'Forgot',
            component: Forgot
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/api-docs',
            name: 'API',
            component: ApiDocs
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/profile/edit',
            name: 'Edit Profile',
            component: EditProfile
        },
        {
            path: '/profile/edit/password',
            name: 'Change Password',
            component: ChangePassword
        },
        {
            path: '/payments',
            name: 'Payments',
            component: Payments
        },
        {
            path: '/widget',
            name: 'Widget',
            component: WidgetPage
        },
        {
            path: '/transactions',
            name: 'Last Transactions',
            component: LastTransactions
        },
        {
            path: '/promo',
            name: 'Promo',
            component: Promo
        },
        {
            path: '*',
            name: Page404,
            component: Page404
        }
    ],
});

export default router
