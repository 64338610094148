<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7513 18.3337C11.7041 18.3337 9.97005 17.6232 8.54922 16.2024C7.12839 14.7816 6.41797 13.0475 6.41797 11.0003C6.41797 8.9531 7.12839 7.21908 8.54922 5.79824C9.97005 4.37741 11.7041 3.66699 13.7513 3.66699C15.7985 3.66699 17.5326 4.37741 18.9534 5.79824C20.3742 7.21908 21.0846 8.9531 21.0846 11.0003C21.0846 13.0475 20.3742 14.7816 18.9534 16.2024C17.5326 17.6232 15.7985 18.3337 13.7513 18.3337ZM6.7388 18.0587C5.01241 17.8448 3.61068 17.0541 2.53359 15.6868C1.45651 14.3194 0.917969 12.7573 0.917969 11.0003C0.917969 9.24338 1.45651 7.68123 2.53359 6.31387C3.61068 4.94651 5.01241 4.15588 6.7388 3.94199V5.27116C5.39436 5.5156 4.31727 6.18401 3.50755 7.27637C2.69783 8.36873 2.29297 9.61005 2.29297 11.0003C2.29297 12.3906 2.69783 13.6319 3.50755 14.7243C4.31727 15.8166 5.39436 16.485 6.7388 16.7295V18.0587ZM13.7513 16.9587C15.386 16.9587 16.7878 16.3743 17.9565 15.2055C19.1253 14.0368 19.7096 12.635 19.7096 11.0003C19.7096 9.3656 19.1253 7.96387 17.9565 6.79512C16.7878 5.62637 15.386 5.04199 13.7513 5.04199C12.1166 5.04199 10.7148 5.62637 9.54609 6.79512C8.37734 7.96387 7.79297 9.3656 7.79297 11.0003C7.79297 12.635 8.37734 14.0368 9.54609 15.2055C10.7148 16.3743 12.1166 16.9587 13.7513 16.9587Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagCoinsWebsites"
}
</script>

<style scoped>

</style>
