<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3799 15.7212H11.7549V14.6899H12.8549C13.0841 14.6899 13.2713 14.5982 13.4164 14.4149C13.5615 14.2316 13.6341 14.0253 13.6341 13.7962V11.1607C13.6341 10.9163 13.5615 10.7062 13.4164 10.5305C13.2713 10.3548 13.0841 10.267 12.8549 10.267H9.73828V8.68574H13.6341V7.31074H11.7549V6.27949H10.3799V7.31074H9.25703C9.02786 7.31074 8.82161 7.40241 8.63828 7.58574C8.45495 7.76908 8.36328 7.98296 8.36328 8.22741V10.8399C8.36328 11.0844 8.45495 11.2791 8.63828 11.4243C8.82161 11.5694 9.02786 11.642 9.25703 11.642H12.2591V13.3149H8.36328V14.6899H10.3799V15.7212ZM3.20703 18.3337C2.84036 18.3337 2.51953 18.1962 2.24453 17.9212C1.96953 17.6462 1.83203 17.3253 1.83203 16.9587V5.04199C1.83203 4.67533 1.96953 4.35449 2.24453 4.07949C2.51953 3.80449 2.84036 3.66699 3.20703 3.66699H18.7904C19.157 3.66699 19.4779 3.80449 19.7529 4.07949C20.0279 4.35449 20.1654 4.67533 20.1654 5.04199V16.9587C20.1654 17.3253 20.0279 17.6462 19.7529 17.9212C19.4779 18.1962 19.157 18.3337 18.7904 18.3337H3.20703ZM3.20703 16.9587H18.7904V5.04199H3.20703V16.9587ZM3.20703 16.9587V5.04199V16.9587Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagPaymentSystems"
}
</script>

<style scoped>

</style>
