<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.9987 42.4L35.2654 35.2667L42.3987 32L35.2654 28.7333L31.9987 21.6L28.732 28.7333L21.5987 32L28.732 35.2667L31.9987 42.4ZM9.33203 52C8.26536 52 7.33203 51.6 6.53203 50.8C5.73203 50 5.33203 49.0667 5.33203 48V16C5.33203 14.9333 5.73203 14 6.53203 13.2C7.33203 12.4 8.26536 12 9.33203 12H54.6654C55.732 12 56.6654 12.4 57.4654 13.2C58.2654 14 58.6654 14.9333 58.6654 16V48C58.6654 49.0667 58.2654 50 57.4654 50.8C56.6654 51.6 55.732 52 54.6654 52H9.33203ZM9.33203 48H54.6654V16H9.33203V48Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "toolsBanners"
}
</script>

<style scoped>

</style>
