<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import './store/mixin'

export default {
  watch: {
    $route() {
      this.setTitle(this.$route.name)
    }
  },
  mounted() {
    this.setTitle(this.$route.name);
    // this.checkAuth();

  },
  methods: {
    checkAuth() {
      const token = localStorage.token;
      const checkRouteNames = ['Login', 'Registration', 'Home', 'HowToUse'];
      const routeName = this.$route.name;

      if (!checkRouteNames.includes(routeName) && !token) {
        this.$router.push({
          name: 'Home'
        });
      }

      if (token && checkRouteNames.includes(routeName)) {
        this.$router.push({
          name: 'Dashboard'
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/globals";
</style>
