<template>
    <div class="status">
        <div class="status-item" v-if="status < 6">
            <div class="status-item-waiting">
                <img src="~@/assets/img/icons/waiting.svg" alt="">
                Waiting
            </div>
        </div>
        <div class="status-item" v-if="status === 6">
            <div class="status-item-complete">
                <img src="~@/assets/img/icons/completed.svg" alt="">
                Completed
            </div>
        </div>
        <div class="status-item" v-if="status === 10">
            <div class="status-item-overdue">
                <img src="~@/assets/img/icons/banned.svg" alt="">
                Overdue
            </div>
        </div>
        <div class="status-item" v-if="status === 12">
            <div class="status-item-overdue">
                <img src="~@/assets/img/icons/banned.svg" alt="">
                Hold
            </div>
        </div>
        <div class="status-item" v-if="status === 13">
            <div class="status-item-overdue">
                <img src="~@/assets/img/icons/banned.svg" alt="">
                Profit
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "TransactionStatus",
    props: {
        status: Number
    }
}
</script>

<style lang="scss">
.status {
  &-item {
    img {
      margin-right: 6px;
    }


    &-complete {
      background: #02C076;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }

    &-waiting {
      background: #673DFD;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }

    &-overdue {
      background: #FF783E;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }
  }
}
</style>
