<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2 33.2L8 24L17.2 14.8L19.3 17L12.3 24L19.3 31L17.2 33.2ZM10 34.7H13V38.5H35V34.7H38V43C38 43.8 37.7 44.5 37.1 45.1C36.5 45.7 35.8 46 35 46H13C12.2 46 11.5 45.7 10.9 45.1C10.3 44.5 10 43.8 10 43V34.7ZM13 12.7H10V5C10 4.2 10.3 3.5 10.9 2.9C11.5 2.3 12.2 2 13 2H35C35.8 2 36.5 2.3 37.1 2.9C37.7 3.5 38 4.2 38 5V12.7H35V9.5H13V12.7ZM13 41.5V43H35V41.5H13ZM13 6.5H35V5H13V6.5ZM30.8 33.2L28.7 31L35.7 24L28.7 17L30.8 14.8L40 24L30.8 33.2ZM13 6.5V5V6.5ZM13 41.5V43V41.5Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "implementIconSm"
}
</script>

<style scoped>

</style>
