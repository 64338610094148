<template>
    <div class="empty">
        <div class="bg">
            <img src="~@/assets/img/notransactions.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "empty"
    }
</script>

<style lang="scss">
    .empty {
        .bg {
            display: flex;
            justify-content: center;
            padding: 12px 0 59px;
        }
    }
</style>