<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.013 42.8429L31.9991 50.8568C29.3907 53.4652 26.248 54.7695 22.571 54.7695C18.8941 54.7695 15.7514 53.4652 13.1429 50.8568C10.5345 48.2484 9.23028 45.1057 9.23028 41.4287C9.23028 37.7518 10.5345 34.6091 13.1429 32.0006L21.1568 23.9867L23.9852 26.8152L15.9714 34.829C14.1486 36.6518 13.2372 38.8517 13.2372 41.4287C13.2372 44.0057 14.1486 46.2056 15.9714 48.0284C17.7941 49.8511 19.994 50.7625 22.571 50.7625C25.148 50.7625 27.3479 49.8511 29.1707 48.0284L37.1846 40.0145L40.013 42.8429ZM26.1066 40.7216L23.2781 37.8932L37.8917 23.2796L40.7201 26.1081L26.1066 40.7216ZM42.8414 40.0145L40.013 37.1861L48.0269 29.1722C49.8496 27.3494 50.761 25.1495 50.761 22.5725C50.761 19.9955 49.8496 17.7956 48.0269 15.9729C46.2041 14.1501 44.0042 13.2387 41.4272 13.2387C38.8502 13.2387 36.6503 14.1501 34.8275 15.9729L26.8137 23.9867L23.9852 21.1583L31.9991 13.1444C34.6075 10.536 37.7502 9.23178 41.4272 9.23178C45.1042 9.23178 48.2469 10.536 50.8553 13.1444C53.4637 15.7529 54.768 18.8956 54.768 22.5725C54.768 26.2495 53.4637 29.3922 50.8553 32.0006L42.8414 40.0145Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "toolsReferal"
}
</script>

<style scoped>

</style>
