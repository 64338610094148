<template>
  <div class="api-block">
    <div class="api-block-title">
      Admin
    </div>
    <div class="title">
      Authorization
    </div>
    <div class="desc">
      This request is using Bearer Token from folder Admin
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="authAdmin">curl --location --request POST 'https://api.swapuz.com/api/User/login'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>

    <div class="api-block_code-title">
      Body:
    </div>
    <div class="api-block_code">
      <pre v-highlightjs><code class="json">{
  "login": "string",
  "password": "string"
}</code></pre>
    </div>

    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json">{
  "result": {
    "token": "token",
    "username": "testfront",
    "expires": "2022-12-22T07:09:06.7494883Z"
  },
  "status": 200
}</code></pre>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/monokai-sublime.css'
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "AuthorizationBlock",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("authAdmin").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style lang="scss">

</style>
