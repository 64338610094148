<template>
  <div class="faq-page">
    <div class="container-l">
      <div class="head-text">
        FAQ
      </div>
      <div class="items">
        <div
            v-for="(item, index) in faqItems"
            :key="index"
            class="item"
            :class="{'item_active': currentIndex === index}"
            @click="openItemHandler(index)"
        >
          <div class="item_head">
            <div class="title">
              {{ item.title }}
            </div>
            <transition name="slide-fade">
              <p v-if="currentIndex === index" class="desc" v-html="item.desc">
              </p>
            </transition>
          </div>
          <faq-arrow/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FaqArrow from "@/assets/img/svg/faqArrow";

export default {
  name: "FAQpage",
  components: {FaqArrow},
  data() {
    return {
      faqItems: [
        {
          title: 'How can I start partnership with Swapuz?',
          desc: `Everyone can become a Swapuz partner and start earning with us. Just create a Swapuz affiliate account, or log in if you already have one. You can also contact <a href="mailto:partnership@swapuz.com" target="_blank">partnership@swapuz.com</a> with any suggestions.`
        },
        {
          title: 'What is RS and CPA?',
          desc: `RS (Revenue Share) is a form of cooperation in which we share with you a portion of our profits from each exchange. CPA (Cost Per Action) is a form of cooperation in which we pay a certain amount of money for a specific user action or conversion on Swapuz.`
        },
        {
          title: 'What are the fees for Swapuz?',
          desc: `Our commissions can be set according to any needs of the partner starting from 0.5%. For setting custom fees you need to contact us. By default, we set 1% for floating rate and 2% for fixed rate partner.`
        },
        {
          title: 'What is the minimum amount to send affiliate profits and turnaround time?',
          desc: `We do not set specific requirements for the minimum affiliate profit withdrawal, it can be an amount equivalent to $ 100, do not worry that we do not pay attention to low-volume partners, we support everyone and try to develop together. Payments are made instantly, the profit accrued to your affiliate account is already confirmed and is not disputed.`
        },
        {
          title: 'Why should I choose Swapuz?',
          desc: `Swapuz provides a unique offer among competitors for swaps, we support the largest number of coins and possible trading pairs, also Swapuz is glad to each partner and interested in development of the partner and his services, we provide 24/7 partner support and all kinds of partnership options. We are glad to hear feedback on how to improve our cooperation and implement it in our system.`
        },
      ],
      currentIndex: false
    }
  },
  methods: {
    openItemHandler(index) {
      if (this.currentIndex === index) {
        this.currentIndex = false
      } else {
        this.currentIndex = index
      }
    }
  }
}
</script>

<style lang="scss">
.faq-page {
  width: 100%;
  margin-bottom: 96px;

  @media (max-width: 1079px) {
    margin-bottom: 0;
  }

  .container-l {
    @media (max-width: 1079px) {
      padding: 0;
    }
  }

  .head-text {
    font-weight: 900;
    font-size: 56px;
    line-height: 62px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 32px;

    @media (max-width: 1079px) {
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 16px;
    }
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .item {
      padding: 24px 24px 24px 48px;
      transition: all .5s ease-in-out;
      position: relative;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 12px;

      @media (max-width: 1079px) {
        padding: 16px 16px 20px 16px;
        height: 100%;
        border-radius: 0;
      }

      &_head {
        position: relative;
        width: 90%;

        @media (max-width: 1079px) {
          width: 100%;
        }

        .title {
          font-weight: 900;
          font-size: 24px;
          line-height: 28px;
          color: #FFFFFF;
          transition: all .5s ease-in-out;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          @media (max-width: 1079px) {
            font-size: 18px;
            line-height: 24px;
            max-width: 80%;
            width: 100%
          }
        }

        .desc {
          overflow: hidden;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #FFFFFF;
          max-width: 956px;
          position: relative;
          height: 100%;
          transition: all .3s ease-in-out;
          filter: blur(5px);

          a {
            color: #02C076;

            &:hover {
              color: #00ff9a;
            }
          }

          @media (max-width: 1079px) {
            position: relative;
          }
        }

        svg {
          transition: all .5s ease-in-out;

          @media (max-width: 1079px) {
            width: 24px;
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }

      @media (min-width: 1080px) {
        &:hover {
          cursor: pointer;
          background: rgba(255, 255, 255, 0.04);
          border-radius: 12px;

          &_head {
            @media (max-width: 1079px) {
              width: 100%;
            }
          }

          @media (max-width: 1079px) {
            border-radius: 0;
          }

          .title {
            color: #02C076;
          }

          svg {
            @media (max-width: 1079px) {
              width: 24px;
              position: absolute;
              top: 16px;
              right: 16px;
            }
          }
        }
      }

      &_active {
        background: rgba(255, 255, 255, 0.04);
        min-height: 78px;
        transition: all .3s ease-in-out;

        @media (max-width: 1079px) {
          height: 100%;
        }

        .title {
          color: #02C076;
          margin-bottom: 20px;

          @media (max-width: 1079px) {
            margin-bottom: 12px;
          }
        }

        .desc {
          filter: blur(0);
        }

        svg {
          transform: rotate(180deg);

          @media (max-width: 1079px) {
            width: 24px;
            position: absolute;
            top: 16px;
            right: 16px;
          }

          path {
            fill: #02C076;
            fill-opacity: 1;
          }
        }
      }
    }
  }
}
</style>
