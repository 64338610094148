<template>
  <div class="hero-block">
    <div class="container-ld">
      <div class="head-text">
        Join the Swapuz Affiliate Program! <br>
        Start monetizing your traffic & <br>
        <router-link :to="{ name: 'Registration' }"><b>earning just now</b></router-link>
      </div>
      <div class="img">
        <img srcset="../../../assets/img/png/heroImg.png 1x,
                  ../../../assets/img/png/heroImg2x.png 2x,
                  ../../../assets/img/png/heroImg4x.png 4x"
             src="../../../assets/img/png/heroImg2x.png"
             alt="">
      </div>
      <div class="rates">
        <div class="rates-item">
          <swap-money-icon/>
          <div class="rates-item_cash">
            $
            <ICountUp
                :delay="delay"
                :endVal="endVal1"
                :options="options"
                @ready="onReadyFirst"
            />
            <!--            65,300,045-->
          </div>
          <div class="rates-item_desc">
            Total affiliate money volume
          </div>
        </div>
        <div class="rates-item">
          <incomes-icon/>
          <div class="rates-item_cash">
            $
            <ICountUp
                :delay="delay"
                :endVal="endVal2"
                :options="options"
                @ready="onReadySecond"
            />
<!--            545,250-->
          </div>
          <div class="rates-item_desc">
            Net income of our partners
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwapMoneyIcon from "@/assets/img/svg/swapMoneyIcon";
import IncomesIcon from "@/assets/img/svg/incomesIcon";
import ICountUp from 'vue-countup-v2';

export default {
  name: "HeroBlock",
  components: { IncomesIcon, SwapMoneyIcon, ICountUp},
  data() {
    return {
      delay: 200,
      endVal1: 65300045,
      endVal2: 545250,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },
  methods: {
    onReadyFirst(instance) {
      instance.update(this.endVal1 + 100);
    },
    onReadySecond(instance) {
      instance.update(this.endVal2 + 100);
    }
  }
}
</script>

<style lang="scss">
.hero-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../assets/img/png/heroBkgrnd.png");
  background-size: 1440px;
  background-repeat: no-repeat;
  background-position: center 150px;
  background-blend-mode: overlay;
  padding-top: 24px;

  @media (max-width: 1079px) {
    background-size: 1000px;
    background-position: center 186px;
    overflow: hidden;
  }

  .head-text {
    font-weight: 900;
    font-size: 56px;
    line-height: 62px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 43px;

    @media (max-width: 1079px) {
      font-size: 36px;
      line-height: 38px;
    }

    b {
      color: #02C076;
      animation-name: headAnimate;
      animation-iteration-count: infinite;
      animation-duration: 4s;

      @keyframes headAnimate {
        0% {
          text-shadow: none;
        }

        50% {
          text-shadow: 0 0 38px rgba(2, 192, 118, 0.42);
        }

        100% {
          text-shadow: none;
        }
      }
    }
  }

  .img {
    display: grid;
    place-items: center;
    padding-bottom: 32px;

    @media (max-width: 1079px) {
      img {
        max-width: 380px;
      }
    }
  }

  .rates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;

    @media (max-width: 1079px) {
      gap: 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 380px;

      @media (max-width: 1079px) {
        min-width: 164px;

        svg {
          width: 48px;
          height: 48px;
        }
      }

      &_cash {
        margin: 8px 0;
        font-weight: 300;
        font-size: 56px;
        color: rgba(255, 255, 255, 0.38);

        @media (max-width: 1079px) {
          font-size: 24px;
          line-height: 28px;
          margin: 12px 0 8px;
        }

        span {
          line-height: 56px;
          font-weight: 900;
          font-size: 56px;
          text-align: center;
          color: #FFFFFF;

          @media (max-width: 1079px) {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }

      &_desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #02C076;

        @media (max-width: 1079px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
