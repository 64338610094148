<template>
  <AccountLayout>
    <template v-slot:title>
      <h2>Payments</h2>
    </template>
    <template v-slot:content>
      <div class="payments">
        <div class="payments-table">
          <div class="col-4">
            <div class="payments-table-title">Date/Time</div>
          </div>
          <div class="col-2">
            <div class="payments-table-title">Address</div>
          </div>
          <div class="col-3">
            <div class="payments-table-title">Sum</div>
          </div>
          <div class="col-2">
            <div class="payments-table-title">Status</div>
          </div>
        </div>
        <div class="items">
          <ul class="items-list">
            <li class="item" v-for="(item, index) in payments" :key="index">
              <div class="payments-table">
                <div class="col-4">
                  <div class="item-date">
                    {{ dataFormatting(item.createDate) }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="item-address">
                    {{ item.address }}
                  </div>
                </div>
                <div class="col-3">
                  <div class="item-sum"
                       :class="{ 'error': item.status === 10 }"
                  >
                    {{ item.amount }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="item-status">
                    <PaymentStatus :status="item.status"/>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/AccountLayout";
import PaymentService from '../../services/payment.service'
import PaymentStatus from '../../pages/payments/partials/PaymentStatus'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "Payments",
  components: {
    AccountLayout,
    PaymentStatus
  },
  data() {
    return {
      payments: [],
      dateFormat: 'DD MMMM YYYY',
    }
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      PaymentService.userPayment().then((response) => {
        this.payments = response.data.result;
      })
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormat)
    },
  }
}
</script>

<style lang="scss">
.payments {
  background: white;
  border-radius: 16px;
  border: 1px solid #DFE0EB;

  &-table {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 14px;
      letter-spacing: 0.2px;
      font-weight: 400;
      color: #9FA2B4;
      padding-top: 32px;
      padding-bottom: 12px;
    }
  }

  .items {
    &-list {
      padding: 0;
      list-style: none;
    }

    .item {
      border-top: 1px solid #DFE0EB;
      padding: 14px 0;

      .error {
        color: #FF783E;
      }

      &-date, &-address {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1B1A1C;
        padding-bottom: 5px;
      }

      &-time {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }

      &-sum {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1B1A1C;
        font-weight: 700;
        padding-bottom: 5px;
      }

      &-btc {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }

      &-convert {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }
    }
  }
}
</style>
