<template>
    <AuthLayout>
        <template v-slot:main>
            <form @submit.prevent="submitForm">
                <div class="form-input">
                    <h2 class="title">Reset password</h2>
                    <p class="title-desc">Please enter your e-mail and we will send an instruction how to reset a
                        password</p>
                    <div class="form-input-group" :class="{ 'form-group--error': $v.form.email.$error }">
                        <label for="email" class="input-title">E-mail</label>
                        <input
                                id="email"
                                type="email"
                                v-model="$v.form.email.$model"
                                class="form-control"
                                :class="{ 'active-input': form.email.length}"
                        >
                        <div class="desc">
                            <label class="desc-text">Please enter your email</label>
                        </div>
                    </div>

                    <div class="buttons">
                        <router-link :to="{name: 'Login'}" class="col btn-link">Cancel</router-link>
                        <button class="col btn-submit" type="submit">Sign on</button>
                    </div>
                </div>
            </form>
        </template>
    </AuthLayout>
</template>

<script>
    import {email, required} from 'vuelidate/lib/validators'
    import AuthLayout from "../../components/AuthLayout";

    export default {
        name: "Forgot",
        components: {AuthLayout},
        data() {
            return {
                form: {
                    email: ''
                },
                submitStatus: null
            }
        },
        validations: {
            form: {
                email: {
                    email,
                    required
                },
            }
        },
        methods: {
            submitForm() {
                this.$v.$touch();
            },
        },
    }
</script>

<style scoped>

</style>
