<template>
  <div class="container-l">
    <div id="referralLinks" class="feature-widget">
      <div class="feature-widget_block">
        <div class="feature-widget_block-text">
          <div class="info">
            <div class="info-title">
              Referral links
            </div>
            <img class="info-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-media-buyers/>
                <div class="info-tags__item-text">
                  Media buyers
                </div>
              </div>

            </div>
            <div class="info-text">
              One of the easiest ways to convert a user is to give them a referral link.
              <br>
              <br>
              Don't worry about the user's session cycle, because we use the link ID in cookies, so every time the user
              visits directly, Swapuz will identify him as a referral, allowing you to earn even more.
            </div>
            <div class="info-list">
              <div v-for="(item, index) in infoList" :key="index" class="info-list--item">
                <list-icon/>
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <img class="feature-widget_block-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
      </div>
      <div class="feature-widget_block_bottom">
        <div class="feature-widget_block feature-widget_block-large">
          <div class="feature-widget_block--item">
            <add-link-icon-sm/>
            <div class="text">
              <div class="title">
                Add link
              </div>
              <div class="desc">
                Share the link on your sources
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob1"/>
          <div class="feature-widget_block--item">
            <share-it-icon-sm/>
            <div class="text">
              <div class="title">
                Share it
              </div>
              <div class="desc">
                Convert your active clients to successful action
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob2"/>
          <div class="feature-widget_block--item">
            <payouts-icon-sm/>
            <div class="text">
              <div class="title">
                Get payouts
              </div>
              <div class="desc">
                Get up to $100 per customer action
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TagCoinsWebsites from "@/assets/img/svg/tags/tagCoinsWebsites.vue";
import ListIcon from "@/assets/img/svg/listIcon.vue";
import ArrowRight from "@/assets/img/svg/arrowRight.vue";
import ShareItIconSm from "@/assets/img/svg/shareItIconSm.vue";
import PayoutsIconSm from "@/assets/img/svg/payoutsIconSm.vue";
import TagNewsWebsites from "@/assets/img/svg/tags/tagNewsWebsites.vue";
import TagMediaBuyers from "@/assets/img/svg/tags/tagMediaBuyers.vue";
import AddLinkIconSm from "@/assets/img/svg/addLinkIconSm.vue";
import ArrowRightMob from "@/assets/img/svg/arrowRightMob.vue";

export default {
  name: "ReferralLinks",
  components: {
    ArrowRightMob,
    AddLinkIconSm,
    TagMediaBuyers,
    TagNewsWebsites,
    PayoutsIconSm,
    ShareItIconSm,
    ArrowRight, ListIcon, TagCoinsWebsites,
  },
  data() {
    return {
      infoList: [
        'All you have to do convert is click "Copy".',
        'Saves cookies for the user`s next visits.',
      ]
    }
  }
}
</script>


