<template>
    <div class="order-create">
        <div class="order-create-block">
            <div class="title">
                <div class="title-text">Order payment</div>
                <div @click="closeOrderPayment" class="close">
                    <img src="../../../assets/img/close.svg" alt="">
                </div>
            </div>
            <div class="desc">
                Please enter summ which you want to withdraw
            </div>
            <div class="input" :class="{error : isErrorPay}">
                <div class="input-title">
                    Sum
                </div>
                <div class="input-pay">
                    <input
                            type="text"
                            v-model="amount"
                            :placeholder="minPay">
                    <div class="value">{{currentCurrency}}</div>
                </div>
                <div v-if="amount >= User.unpaid" class="input-desc">
                    Not enough coins
                </div>
                <div v-else class="input-desc">
                    Minimum {{minPay}} {{currentCurrency}}
                </div>
                <div v-if="amount.length && !isValidAmount(amount)" class="input-desc">
                    Invalid value, only digits allowed
                </div>
            </div>
            <div class="buttons">
                <div @click="closeOrderPayment" class="cancel">
                    Cancel
                </div>
                <div @click="createOrder" class="confirm">
                    Send order
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PaymentService from '../../../services/payment.service'

    export default {
        name: "OrderPaymentModal",
        data() {
            return {
                amount: '',
                minPay: 0.002,
                currentCurrency: 'BTC',
                isErrorPay: false
            }
        },
        computed: {
            User() {
                return this.$store.state.user;
            },
        },
        methods: {
            closeOrderPayment() {
                this.$store.commit('setter', {isPaymentModal: false})
            },
            isValidAmount(amount) {
                return /^[0-9]{1,10}([,.][0-9]{1,10})?$/.test(amount);
            },
            createOrder() {
                const validAmount =  this.amount >= this.minPay
                    && this.amount <= this.User.unpaid
                    && this.isValidAmount(this.amount);

                if (validAmount) {
                    const query = {
                        address: this.User.partnerProfile.address,
                        amount: this.amount
                    };
                    PaymentService.createPayment(query).then(() => {
                        this.closeOrderPayment();
                        this.confirmPayment();
                    });
                } else {
                    this.isErrorPay = true;
                }
            },
            confirmPayment() {
                this.$store.commit('setter', {isCompleteModal: true})
            }
        },
    }
</script>

<style lang="scss">
    .order-create {
        background: rgba(27, 26, 28, 0.12);
        position: fixed;
        width: 100%;
        min-height: 100vh;
        top: 0;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-block {
            background: #FFFFFF;
            padding: 32px 40px;
            border-radius: 12px;
            width: 100%;
            max-width: 480px;

            @media (max-width: 1079px) {
                padding: 32px 25px;
            }

            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                &-text {
                    font-size: 20px;
                    line-height: 24px;
                    color: #1B1A1C;
                    font-weight: 700;
                }

                .close {
                    position: relative;
                    bottom: 15px;
                    right: -15px;

                    @media (max-width: 1079px) {
                        bottom: 20px;
                        right: -6px;
                    }

                    img {
                        cursor: pointer;
                    }
                }
            }

            .desc {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #1B1A1C;
                margin-top: 32px;
            }

            .error {
                .input-pay {
                    input {
                        border-color: #F64E60;
                    }
                }

                .input-desc {
                    color: #F64E60;
                }
            }

            .input {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 32px 0 56px;

                &-title {
                    color: #673DFD;
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                &-pay {
                    position: relative;
                    width: 100%;

                    input {
                        background: #FFFFFF;
                        border: 2px solid #673DFD;
                        box-sizing: border-box;
                        border-radius: 8px;
                        padding: 11px 50px 11px 16px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #1B1A1C;
                        width: 100%;
                        outline: none;
                    }

                    .value {
                        position: absolute;
                        right: 16px;
                        bottom: 13px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #9FA2B4;
                    }
                }

                &-desc {
                    margin-top: 6px;
                    font-size: 12px;
                    line-height: 14px;
                    color: #9FA2B4;
                    opacity: 0.8;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .cancel {
                    border-radius: 8px;
                    padding: 14px;
                    max-width: 192px;
                    width: 100%;
                    color: #673DFD;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(103, 61, 253, 0.05);
                        transition: background-color .5s;
                    }
                }

                .confirm {
                    background: #673DFD;
                    color: #FFFFFF;
                    border-radius: 8px;
                    padding: 14px;
                    max-width: 192px;
                    width: 100%;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
</style>