<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.7276 15.3542H9.8526V13.9792H5.7276V15.3542ZM14.8943 15.3542H16.2693V13.9792H14.8943V15.3542ZM5.7276 11.6875H9.8526V10.3125H5.7276V11.6875ZM14.8943 11.6875H16.2693V6.64583H14.8943V11.6875ZM5.7276 8.02083H9.8526V6.64583H5.7276V8.02083ZM3.02344 19.25C2.65677 19.25 2.33594 19.1125 2.06094 18.8375C1.78594 18.5625 1.64844 18.2417 1.64844 17.875V4.125C1.64844 3.75833 1.78594 3.4375 2.06094 3.1625C2.33594 2.8875 2.65677 2.75 3.02344 2.75H18.9734C19.3401 2.75 19.6609 2.8875 19.9359 3.1625C20.2109 3.4375 20.3484 3.75833 20.3484 4.125V17.875C20.3484 18.2417 20.2109 18.5625 19.9359 18.8375C19.6609 19.1125 19.3401 19.25 18.9734 19.25H3.02344ZM3.02344 17.875H18.9734V4.125H3.02344V17.875ZM3.02344 17.875V4.125V17.875Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagNewsWebsites"
}
</script>

<style scoped>

</style>
