<template>
  <AccountLayout>
    <template v-slot:title>
      <h2>Overview</h2>
    </template>
    <template v-slot:content>
      <div class="dashboard">
        <Cards />
        <Statistics />
        <Transactions />

      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/AccountLayout";
import Cards from "./partials/Cards";
import Statistics from "./partials/Statistics";
import Transactions from "./partials/Transactions";

export default {
  name: "Dashboard",
  components: { Transactions, Statistics, Cards, AccountLayout},
}
</script>

<style lang="scss">

</style>
