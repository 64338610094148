<template>
  <div class="header">
    <div class="container-ld">
      <div class="header-block header-block_desktop">
        <div class="header-block-main">
          <router-link :to="{name: 'Home'}">
            <logo-desktop/>
          </router-link>
          <!--        <div class="lang">-->
          <!--          <div class="lang-item">-->
          <!--            Poland-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
        <div class="nav-menu">
          <a class="item item-main" href="https://swapuz.com/">
            <main-site-icon/>
            Main site</a>
          <router-link :to="{ name: 'Home'}" class="item">
            Affiliate program
          </router-link>
          <router-link :to="{ name: 'API'}" class="item">
            API
          </router-link>
          <a class="item" href="https://swapuz.com/contact/">Contacts</a>
        </div>
        <div class="buttons">
          <router-link :to="{name: 'Login'}" class="buttons-item buttons-item_log-in">
            Log in
            <log-in-icon/>
          </router-link>
          <router-link :to="{name: 'Registration'}" class="buttons-item buttons-item_registration">
            Registration
          </router-link>
        </div>
      </div>
      <div class="header-block header-block_mob">
        <div v-if="isMenuOpen" class="menu-block ">
          <div class="menu">
            <div @click="menuToggleMob" class="burger">
              <close-icon/>
            </div>
          </div>
          <div class="nav-menu">
            <div @click="routerHandler('Home')" class="item">
              Affiliate program
            </div>
            <div @click="routerHandler('API')" class="item">
              API
            </div>

            <a class="item" href="https://swapuz.com/contact/">Contacts</a>

            <a class="item item-colored" href="https://swapuz.com/">
              <main-site-icon/>
              Main site</a>
          </div>
          <div class="buttons buttons__mobile">
            <div @click="routerHandler('Login')" class="buttons-item buttons-item_log-in">
              Log in
              <log-in-icon/>
            </div>
            <div @click="routerHandler('Registration')" class="buttons-item buttons-item_registration">
              Registration
            </div>
          </div>
        </div>
        <div class="menu">
          <div @click="menuToggleMob" class="burger">
            <burger-icon/>
          </div>
          <router-link :to="{name: 'Home'}">
            <logo-desktop/>
          </router-link>
        </div>
        <div class="buttons">
          <router-link :to="{name: 'Login'}" class="buttons--item buttons--item_sign-in">
            Sign in/up
            <profile-btn-mob/>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LogoDesktop from "@/assets/img/svg/logoDesktop";
import LogInIcon from "@/assets/img/svg/logInIcon";
import BurgerIcon from "@/assets/img/svg/burgerIcon";
import ProfileBtnMob from "@/assets/img/svg/profileBtnMob";
import CloseIcon from "@/assets/img/svg/closeIcon.vue";
import MainSiteIcon from "@/assets/img/icons/mainSiteIcon.vue";

export default {
  name: "SiteHeader",
  components: {MainSiteIcon, CloseIcon, ProfileBtnMob, BurgerIcon, LogInIcon, LogoDesktop},
  data() {
    return {
      isApiDrop: false,
      isMenuOpen: false,
      isApiMobDrop: false
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    if (window.innerWidth < 1079) {
      this.fixedButtons()
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.body.classList.remove('popup-open');
  },
  methods: {
    handleClickOutside(event) {
      if (this.$refs.partners && !this.$refs.partners.contains(event.target)) {
        this.isApiDrop = false
      }
    },
    fixedButtons() {
      const item = document.querySelector('.buttons__mobile');
      // eslint-disable-next-line no-self-assign
      window.addEventListener('resize', () => item.innerHTML = item.innerHTML);
    },
    dropdownApi() {
      this.isApiDrop = !this.isApiDrop
    },
    menuToggleMob() {
      this.isMenuOpen = !this.isMenuOpen

      if (this.isMenuOpen) {
        document.body.classList.add('popup-open');
      } else {
        document.body.classList.remove('popup-open');
      }
    },
    mobileLogoHandler() {
      if (this.$route.path !== '/') {
        this.isMenuOpen = !this.isMenuOpen
        this.$router.push({name: 'Home'})
      } else {
        this.isMenuOpen = !this.isMenuOpen
      }
    },
    routerHandler(path) {
      if (this.$route.name !== path) {
        this.isMenuOpen = !this.isMenuOpen
        this.$router.push({name: path})
        document.body.classList.remove('popup-open');
      } else {
        this.isMenuOpen = !this.isMenuOpen
        document.body.classList.remove('popup-open');
      }
    }
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;

  &-block {
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &-main {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 36.06px;
      padding-bottom: 8px;
      box-sizing: border-box;

      .lang {
        &-item {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }
    }

    .nav {
      padding-top: 4px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: max-content;
        z-index: 999;

        .item {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
          transition: all .3s ease-in-out;

          &:hover {
            color: #02C076;
            cursor: pointer;
          }

          &_dropdown {
            display: flex;
            gap: 4px;
            flex-direction: row;
            position: relative;

            &--block {
              position: absolute;
              top: calc(100% + 16px);
              left: 0;
              min-width: max-content;
              padding: 8px 0;
              box-sizing: border-box;
              background: #29282A;
              border-radius: 8px;
              display: flex;
              flex-direction: column;

              .item {
                color: white;
                padding: 10px 14px 10px 16px;
                transition: all .3s ease-in-out;
                display: flex;
                align-items: center;
                gap: 6px;

                &-new {
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 16px;
                  color: #29282A;
                  background: #FF783E;
                  border-radius: 4px;
                  box-sizing: border-box;
                  padding: 1px 4px;
                }

                &:hover {
                  cursor: pointer;
                  background: #363537;
                  backdrop-filter: blur(13.5914px);
                }
              }
            }
          }

          &-colored {
            color: #02C076 !important;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            height: 56px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            position: relative;
            gap: 12px;
          }

          &-main {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
          }
        }

        .dropdown-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            padding: 8px 0 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: rgba(255, 255, 255, 0.72);

            &-new {
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              color: #29282A;
              background: #FF783E;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 1px 4px;
            }
          }
        }
      }
    }

    &_desktop {
      display: flex;

      @media (max-width: 1079px) {
        display: none;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &-item {
          backdrop-filter: blur(13.5914px);
          border-radius: 8px;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 0 16px;
          transition: all .3s ease-in-out;

          svg {
            transition: all .3s ease-in-out;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 2px 32px rgba(2, 192, 118, 0.2);
            cursor: pointer;

            svg {
              transform: rotate(180deg);
            }
          }

          &_log-in {
            background: #363537;
            color: #02C076;
            gap: 8px;
          }

          &_registration {
            background: #02C076;
            color: #1B1A1C;
          }
        }
      }
    }

    &_mob {
      display: none;
      padding: 12px 0;

      .menu-block {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        background: #1B1A1C;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .menu {
          padding: 12px;
          justify-content: flex-end;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 9;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 8px;
          padding: 16px;

          &-item {
            backdrop-filter: blur(13.5914px);
            border-radius: 8px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 48px;
            padding: 0 16px;
            transition: all .3s ease-in-out;

            svg {
              transition: all .3s ease-in-out;
            }

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 2px 32px rgba(2, 192, 118, 0.2);
              cursor: pointer;

              svg {
                transform: rotate(180deg);
              }
            }

            &_log-in {
              background: #363537;
              color: #02C076;
              gap: 8px;
            }

            &_registration {
              background: #02C076;
              color: #1B1A1C;
            }
          }

          &__mobile {
            width: 100%;
            padding-bottom: 0;
            position: fixed;
            bottom: 16px;
          }
        }
      }

      .nav-menu {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
        position: relative;

        .item {
          box-sizing: border-box;
          height: 56px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;


          &_dropdown {
            display: flex;
            flex-direction: column;

            .text {
              position: relative;

              svg {
                position: absolute;
                left: calc(100% + 4px);
              }
            }

            &--block {
              position: relative;
              background: none;
              top: 0;

              .item {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.72);
              }
            }

            &-open {
              svg {
                transition: all .3s ease-in-out;
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      @media (max-width: 1079px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &--item {
          background: #363537;
          backdrop-filter: blur(13.5914px);
          border-radius: 8px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #02C076;
          gap: 4px;
          padding: 0 10px 0 12px;
          box-sizing: border-box;

          svg {
            path {
              fill: #02C076;
            }
          }

          &_profile {
            background: #02C076;
          }
        }
      }
    }
  }
}
</style>
