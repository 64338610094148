<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.3421 23.1842C32.9461 23.1842 33.4523 22.9799 33.8609 22.5714C34.2694 22.1628 34.4737 21.6566 34.4737 21.0526C34.4737 20.4487 34.2694 19.9424 33.8609 19.5339C33.4523 19.1253 32.9461 18.9211 32.3421 18.9211C31.7382 18.9211 31.2319 19.1253 30.8234 19.5339C30.4148 19.9424 30.2105 20.4487 30.2105 21.0526C30.2105 21.6566 30.4148 22.1628 30.8234 22.5714C31.2319 22.9799 31.7382 23.1842 32.3421 23.1842ZM15.2895 17.8553H25.9474V14.6579H15.2895V17.8553ZM7.82895 44.5C6.62105 40.45 5.43092 36.4089 4.25855 32.3766C3.08618 28.3444 2.5 24.2145 2.5 19.9868C2.5 16.7184 3.63684 13.9474 5.91053 11.6737C8.18421 9.4 10.9553 8.26316 14.2237 8.26316H24.8816C25.9118 6.91316 27.1641 5.86513 28.6385 5.11908C30.1128 4.37303 31.7026 4 33.4079 4C34.2961 4 35.051 4.31086 35.6727 4.93257C36.2944 5.55428 36.6053 6.30921 36.6053 7.19737C36.6053 7.41053 36.5786 7.62368 36.5253 7.83684C36.472 8.05 36.4099 8.24539 36.3388 8.42303C36.1967 8.81382 36.0635 9.21349 35.9391 9.62204C35.8148 10.0306 35.7171 10.448 35.6461 10.8743L40.4954 15.7237H45.1316V30.5914L39.1099 32.5632L35.5395 44.5H23.8158V40.2368H19.5526V44.5H7.82895ZM10.227 41.3026H16.3553V37.0395H27.0132V41.3026H33.1415L36.4987 30.1118L41.9342 28.2467V18.9211H39.1632L32.3421 12.1C32.3776 11.2118 32.4931 10.3503 32.6885 9.51546C32.8839 8.68059 33.0882 7.83684 33.3013 6.98421C31.9513 7.33947 30.6724 7.86349 29.4645 8.55625C28.2566 9.24901 27.3329 10.2171 26.6934 11.4605H14.2237C11.8789 11.4605 9.87171 12.2954 8.20197 13.9651C6.53224 15.6349 5.69737 17.6421 5.69737 19.9868C5.69737 23.6461 6.2125 27.2253 7.24276 30.7247C8.27303 34.224 9.26776 37.75 10.227 41.3026Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "payoutsIconSm"
}
</script>

<style scoped>

</style>
