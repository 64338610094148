<template>
  <svg width="209" height="37" viewBox="0 0 209 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2434_513)">
      <path d="M28.4711 2.42559C29.3171 2.07305 30.2831 2.27047 30.9318 2.91209L35.4443 7.42459C35.8673 7.84764 36.107 8.41875 36.107 9.01807C36.107 9.61738 35.8673 10.1885 35.4443 10.6115L30.9318 15.124C30.2831 15.7727 29.3171 15.9631 28.4711 15.6105C27.625 15.258 27.075 14.4401 27.075 13.5235V11.2814H24.8187C24.1066 11.2814 23.4368 11.6128 23.0137 12.1839L20.0242 16.1676L17.2039 12.4095L19.4038 9.47637C20.6799 7.77008 22.6894 6.76887 24.8187 6.76887H27.075V4.51262C27.075 3.60307 27.625 2.77812 28.4711 2.42559ZM11.5633 19.9327L14.3836 23.6907L12.1837 26.6239C10.9076 28.3302 8.89809 29.3314 6.76875 29.3314H2.25625C1.00826 29.3314 0 28.3231 0 27.0751C0 25.8271 1.00826 24.8189 2.25625 24.8189H6.76875C7.48088 24.8189 8.1507 24.4875 8.57375 23.9164L11.5633 19.9327ZM30.9247 33.1881C30.2761 33.8368 29.3101 34.0272 28.464 33.6746C27.6179 33.3221 27.0679 32.5042 27.0679 31.5876V29.3314H24.8187C22.6894 29.3314 20.6799 28.3302 19.4038 26.6239L8.57375 12.1839C8.1507 11.6128 7.48088 11.2814 6.76875 11.2814H2.25625C1.00826 11.2814 0 10.2731 0 9.02512C0 7.77713 1.00826 6.76887 2.25625 6.76887H6.76875C8.89809 6.76887 10.9076 7.77008 12.1837 9.47637L23.0137 23.9164C23.4368 24.4875 24.1066 24.8189 24.8187 24.8189H27.075V22.5626C27.075 21.6531 27.625 20.8281 28.4711 20.4756C29.3171 20.123 30.2831 20.3205 30.9318 20.9621L35.4443 25.4746C35.8673 25.8976 36.107 26.4687 36.107 27.0681C36.107 27.6674 35.8673 28.2385 35.4443 28.6615L30.9318 33.174L30.9247 33.1881Z" fill="#EB4D27"/>
    </g>
    <path d="M67.8815 5.60547V10.2061H60.1081V31.5876H54.6437V10.2061H46.835V5.60547H67.8815Z" fill="white"/>
    <path d="M78.352 17.0101C76.3307 17.0101 74.9735 17.6682 74.2801 18.9843C73.8923 19.7247 73.6984 20.8645 73.6984 22.404V31.5876H68.6395V12.3742H73.434V15.7233C74.2096 14.4424 74.8853 13.567 75.4611 13.0969C76.4012 12.3096 77.6234 11.9159 79.1275 11.9159C79.2215 11.9159 79.2979 11.9218 79.3567 11.9335C79.4272 11.9335 79.5741 11.9394 79.7974 11.9512V17.0982C79.4801 17.063 79.198 17.0395 78.9513 17.0277C78.7045 17.016 78.5047 17.0101 78.352 17.0101Z" fill="white"/>
    <path d="M98.7816 14.8067C100.403 16.8397 101.214 19.2429 101.214 22.0162C101.214 24.8365 100.403 27.2514 98.7816 29.2608C97.1599 31.2586 94.698 32.2574 91.3959 32.2574C88.0938 32.2574 85.6319 31.2586 84.0102 29.2608C82.3885 27.2514 81.5777 24.8365 81.5777 22.0162C81.5777 19.2429 82.3885 16.8397 84.0102 14.8067C85.6319 12.7738 88.0938 11.7573 91.3959 11.7573C94.698 11.7573 97.1599 12.7738 98.7816 14.8067ZM91.3783 16.0054C89.9094 16.0054 88.7754 16.5283 87.9763 17.5742C87.1889 18.6083 86.7953 20.0889 86.7953 22.0162C86.7953 23.9434 87.1889 25.4299 87.9763 26.4758C88.7754 27.5216 89.9094 28.0446 91.3783 28.0446C92.8472 28.0446 93.9753 27.5216 94.7626 26.4758C95.55 25.4299 95.9436 23.9434 95.9436 22.0162C95.9436 20.0889 95.55 18.6083 94.7626 17.5742C93.9753 16.5283 92.8472 16.0054 91.3783 16.0054Z" fill="white"/>
    <path d="M121.45 19.2664H116.303C116.209 18.5495 115.968 17.9032 115.58 17.3274C115.016 16.5518 114.141 16.164 112.954 16.164C111.261 16.164 110.104 17.0042 109.481 18.6847C109.152 19.5778 108.988 20.7646 108.988 22.2453C108.988 23.6555 109.152 24.7895 109.481 25.6473C110.08 27.2455 111.209 28.0446 112.866 28.0446C114.041 28.0446 114.875 27.7273 115.369 27.0927C115.862 26.4582 116.162 25.6356 116.268 24.625H121.397C121.279 26.1526 120.727 27.598 119.74 28.9612C118.165 31.1587 115.833 32.2574 112.742 32.2574C109.652 32.2574 107.378 31.3408 105.921 29.5076C104.463 27.6744 103.735 25.2948 103.735 22.3687C103.735 19.0666 104.54 16.4989 106.15 14.6657C107.76 12.8325 109.981 11.9159 112.813 11.9159C115.222 11.9159 117.19 12.4565 118.718 13.5376C120.257 14.6187 121.168 16.5283 121.45 19.2664Z" fill="white"/>
    <path d="M125.645 14.4542C126.973 12.762 129.253 11.9159 132.484 11.9159C134.588 11.9159 136.456 12.3331 138.09 13.1674C139.723 14.0018 140.54 15.5764 140.54 17.8915V26.7049C140.54 27.316 140.552 28.0563 140.575 28.9259C140.61 29.584 140.71 30.0306 140.875 30.2656C141.039 30.5006 141.286 30.6945 141.615 30.8473V31.5876H136.151C135.998 31.1998 135.892 30.8355 135.833 30.4947C135.775 30.1539 135.728 29.7661 135.692 29.3313C134.999 30.0834 134.2 30.7239 133.295 31.2527C132.214 31.8755 130.992 32.1869 129.629 32.1869C127.89 32.1869 126.45 31.6934 125.31 30.7063C124.182 29.7074 123.618 28.2972 123.618 26.4758C123.618 24.1138 124.529 22.404 126.35 21.3463C127.349 20.7705 128.818 20.3592 130.757 20.1125L132.467 19.9009C133.395 19.7834 134.059 19.6365 134.459 19.4603C135.175 19.1547 135.534 18.6788 135.534 18.0325C135.534 17.2451 135.258 16.7046 134.705 16.4108C134.165 16.1053 133.366 15.9525 132.308 15.9525C131.121 15.9525 130.281 16.2463 129.787 16.8338C129.435 17.2686 129.2 17.8562 129.082 18.5965H124.235C124.341 16.9161 124.811 15.5353 125.645 14.4542ZM129.382 27.9564C129.852 28.3442 130.428 28.5381 131.109 28.5381C132.191 28.5381 133.184 28.2209 134.088 27.5863C135.005 26.9517 135.481 25.7942 135.516 24.1138V22.2453C135.199 22.4451 134.876 22.6096 134.547 22.7389C134.229 22.8564 133.789 22.968 133.225 23.0738L132.097 23.2853C131.039 23.4733 130.281 23.7025 129.823 23.9728C129.047 24.4311 128.659 25.142 128.659 26.1056C128.659 26.9635 128.9 27.5804 129.382 27.9564Z" fill="white"/>
    <path d="M162.503 5.64072V31.5876H157.62V28.9259C156.904 30.0658 156.087 30.8943 155.17 31.4113C154.254 31.9284 153.114 32.1869 151.751 32.1869C149.506 32.1869 147.614 31.2821 146.075 29.4724C144.547 27.6509 143.783 25.3183 143.783 22.4745C143.783 19.1959 144.535 16.6164 146.039 14.7362C147.555 12.856 149.577 11.9159 152.103 11.9159C153.266 11.9159 154.301 12.1744 155.205 12.6915C156.11 13.1968 156.845 13.9019 157.409 14.8067V5.64072H162.503ZM148.966 22.1043C148.966 23.8787 149.318 25.2948 150.023 26.3524C150.716 27.4218 151.774 27.9564 153.196 27.9564C154.618 27.9564 155.699 27.4276 156.439 26.37C157.18 25.3124 157.55 23.9434 157.55 22.2629C157.55 19.9127 156.956 18.2322 155.77 17.2216C155.041 16.6106 154.195 16.305 153.231 16.305C151.762 16.305 150.681 16.8632 149.988 17.9796C149.306 19.0842 148.966 20.4591 148.966 22.1043Z" fill="white"/>
    <path d="M183.109 14.8067C184.731 16.8397 185.541 19.2429 185.541 22.0162C185.541 24.8365 184.731 27.2514 183.109 29.2608C181.487 31.2586 179.025 32.2574 175.723 32.2574C172.421 32.2574 169.959 31.2586 168.338 29.2608C166.716 27.2514 165.905 24.8365 165.905 22.0162C165.905 19.2429 166.716 16.8397 168.338 14.8067C169.959 12.7738 172.421 11.7573 175.723 11.7573C179.025 11.7573 181.487 12.7738 183.109 14.8067ZM175.706 16.0054C174.237 16.0054 173.103 16.5283 172.304 17.5742C171.516 18.6083 171.123 20.0889 171.123 22.0162C171.123 23.9434 171.516 25.4299 172.304 26.4758C173.103 27.5216 174.237 28.0446 175.706 28.0446C177.175 28.0446 178.303 27.5216 179.09 26.4758C179.877 25.4299 180.271 23.9434 180.271 22.0162C180.271 20.0889 179.877 18.6083 179.09 17.5742C178.303 16.5283 177.175 16.0054 175.706 16.0054Z" fill="white"/>
    <path d="M198.815 17.0101C196.793 17.0101 195.436 17.6682 194.743 18.9843C194.355 19.7247 194.161 20.8645 194.161 22.404V31.5876H189.102V12.3742H193.897V15.7233C194.672 14.4424 195.348 13.567 195.924 13.0969C196.864 12.3096 198.086 11.9159 199.59 11.9159C199.684 11.9159 199.761 11.9218 199.819 11.9335C199.89 11.9335 200.037 11.9394 200.26 11.9512V17.0982C199.943 17.063 199.661 17.0395 199.414 17.0277C199.167 17.016 198.967 17.0101 198.815 17.0101Z" fill="white"/>
    <path d="M203.133 26.3348H208.474V31.5876H203.133V26.3348Z" fill="#EB4D27"/>
    <defs>
      <clipPath id="clip0_2434_513">
        <rect width="36.1" height="36.1" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "trocadorImg"
}
</script>

<style scoped>

</style>
