<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0065 21.4213L15.9996 25.4283C14.6953 26.7325 13.124 27.3846 11.2855 27.3846C9.44703 27.3846 7.87569 26.7325 6.57147 25.4283C5.26725 24.1241 4.61514 22.5527 4.61514 20.7142C4.61514 18.8758 5.26725 17.3044 6.57147 16.0002L10.5784 11.9933L11.9926 13.4075L7.98568 17.4144C7.0743 18.3258 6.61861 19.4257 6.61861 20.7142C6.61861 22.0027 7.0743 23.1027 7.98568 24.0141C8.89706 24.9254 9.99701 25.3811 11.2855 25.3811C12.574 25.3811 13.674 24.9254 14.5853 24.0141L18.5923 20.0071L20.0065 21.4213ZM13.0533 20.3607L11.6391 18.9465L18.9458 11.6397L20.36 13.0539L13.0533 20.3607ZM21.4207 20.0071L20.0065 18.5929L24.0134 14.586C24.9248 13.6746 25.3805 12.5747 25.3805 11.2861C25.3805 9.99764 24.9248 8.89769 24.0134 7.98631C23.1021 7.07493 22.0021 6.61924 20.7136 6.61924C19.4251 6.61924 18.3252 7.07493 17.4138 7.98631L13.4068 11.9933L11.9926 10.579L15.9996 6.5721C17.3038 5.26788 18.8751 4.61577 20.7136 4.61577C22.5521 4.61577 24.1234 5.26788 25.4276 6.5721C26.7319 7.87632 27.384 9.44767 27.384 11.2861C27.384 13.1246 26.7319 14.696 25.4276 16.0002L21.4207 20.0071Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "linkIcon"
}
</script>

<style scoped>

</style>
