<template>
  <div class="achievements">
    <div class="container-l">
      <div class="h1">
        Achievements
      </div>

      <div class="achievements-items achievements-items_desktop">
        <div class="achievements-item achievements-item1">
          <div class="text">
            <div class="h2">Reward</div>
            <div class="p">
              Choose which rewards you want to receive.
              <ul>
                <li>CPA - up to $100</li>
                <li>RS - starting 25% and higher</li>
              </ul>
            </div>
          </div>
          <img class="img-lg" srcset="../../../assets/img/png/item1s1x.png 1x,
                       ../../../assets/img/png/item1s2x.png 2x,
                       ../../../assets/img/png/item1s4x.png 4x"
               src="../../../assets/img/png/item1s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item2">
          <div class="text">
            <div class="h2">
              Support 5000+ <br>
              cryptocurrencies
            </div>
            <div class="p">
              Swapuz is the only service that can offer swap of 5k+ coins in different networks.
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item2s1x.png 1x,
                       ../../../assets/img/png/item2s2x.png 2x,
                       ../../../assets/img/png/item2s4x.png 4x"
               src="../../../assets/img/png/item2s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item3">
          <div class="text">
            <div class="h2">Various offers</div>
            <div class="p">
              Use your users traffic effectively - choose offers that are right for you: swaps/install apps, etc.
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item3s1x.png 1x,
                       ../../../assets/img/png/item3s2x.png 2x,
                       ../../../assets/img/png/item3s4x.png 4x"
               src="../../../assets/img/png/item3s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item4">
          <div class="text">
            <div class="h2">Payments in a convenient currency</div>
            <div class="p">
              Get rewards in your preferred cryptocurrency like:
              BTC / USDT / XMR / ETH
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item4s1x.png 1x,
                       ../../../assets/img/png/item4s2x.png 2x,
                       ../../../assets/img/png/item4s4x.png 4x"
               src="../../../assets/img/png/item4s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item5">
          <div class="text">
            <div class="h2">Fast Payments</div>
            <div class="p">
              Get your rewards instantly, without any limits or confirmations.
            </div>

          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item5s1x.png 1x,
                       ../../../assets/img/png/item5s2x.png 2x,
                       ../../../assets/img/png/item5s4x.png 4x"
               src="../../../assets/img/png/item5s2x.png" alt="">
        </div>
      </div>
      <div class="achievements-items achievements-items_mob">
        <div class="achievements-item achievements-item2">
          <div class="text">
            <div class="h2">Payments in a convenient currency</div>
            <div class="p">
              Get rewards in your preferred cryptocurrency like:
              BTC / USDT / XMR / ETH
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item4s1x.png 1x,
                       ../../../assets/img/png/item4s2x.png 2x,
                       ../../../assets/img/png/item4s4x.png 4x"
               src="../../../assets/img/png/item4s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item1">
          <div class="text">
            <div class="h2">Reward</div>
            <div class="p">
              Choose which rewards you want to receive.
              <ul>
                <li>CPA - up to $100</li>
                <li>RS - starting 25% and higher</li>
              </ul>
            </div>
          </div>
          <img class="img-lg" srcset="../../../assets/img/png/item1s1x.png 1x,
                       ../../../assets/img/png/item1s2x.png 2x,
                       ../../../assets/img/png/item1s4x.png 4x"
               src="../../../assets/img/png/item1s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item3">
          <div class="text">
            <div class="h2">
              Support 5000+ <br>
              cryptocurrencies
            </div>
            <div class="p">
              Swapuz is the only service that can offer swap of 5k+ coins in different networks.
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item2s1x.png 1x,
                       ../../../assets/img/png/item2s2x.png 2x,
                       ../../../assets/img/png/item2s4x.png 4x"
               src="../../../assets/img/png/item2s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item4">
          <div class="text">
            <div class="h2">Various offers</div>
            <div class="p">
              Use your users traffic effectively - choose offers that are right for you: swaps/install apps, etc.
            </div>
          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item3s1x.png 1x,
                       ../../../assets/img/png/item3s2x.png 2x,
                       ../../../assets/img/png/item3s4x.png 4x"
               src="../../../assets/img/png/item3s2x.png" alt="">
        </div>
        <div class="achievements-item achievements-item5">
          <div class="text">
            <div class="h2">Fast Payments</div>
            <div class="p">
              Get your rewards instantly, without any limits or confirmations.
            </div>

          </div>
          <img class="img-sm" srcset="../../../assets/img/png/item5s1x.png 1x,
                       ../../../assets/img/png/item5s2x.png 2x,
                       ../../../assets/img/png/item5s4x.png 4x"
               src="../../../assets/img/png/item5s2x.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AchievementsBlock"
}
</script>

<style lang="scss">
.achievements {
  width: 100%;
  margin-bottom: 120px;

  @media (max-width: 1079px) {
    margin-bottom: 48px;

    .container-l {
      .h1 {
        margin-bottom: 24px;
      }
    }
  }

  &-items {
    &_desktop {
      margin-top: 48px;
      width: 100%;
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 24px 24px;
      grid-template-areas: "item1 item2 item3"
                  "item1 item4 item5";

      @media (max-width: 1079px) {
        display: none;
      }
    }

    &_mob {
      display: none;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 0.5fr 1fr 1fr;
      gap: 4px 4px;
      grid-template-areas:
          "achievements-item1 achievements-item2"
          "achievements-item1 achievements-item2"
          "achievements-item3 achievements-item3"
          "achievements-item4 achievements-item5"
          "achievements-item4 achievements-item5";

      @media (max-width: 1079px) {
        display: grid;
      }

      .achievements-item {
        max-height: 306px;
      }

      .achievements-item2 {
        grid-area: achievements-item2;
      }

      .achievements-item1 {
        grid-area: achievements-item1;
      }

      .achievements-item3 {
        min-height: 152px;
        grid-area: achievements-item3;
      }

      .achievements-item4 {
        grid-area: achievements-item4;
      }

      .achievements-item5 {
        grid-area: achievements-item5;
      }
    }

  }

  &-item {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 20px;
    min-height: 240px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (max-width: 1079px) {
      min-height: 1px;
      box-sizing: border-box;
    }

    .text {
      box-sizing: border-box;
      padding: 24px;

      .h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        transition: all .3s ease-in-out;
        @media (max-width: 1079px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.38);
        max-width: 221px;
        width: 100%;
        transition: all .3s ease-in-out;

        @media (max-width: 1079px) {
          font-size: 12px;
          line-height: 18px;
        }

        ul {
          margin: 0;
          padding: 0;
          padding-inline-start: 1.5ch;

          li {
            padding-left: 3px;

            &::marker {
              width: 16px;
              color: #02C076;
            }
          }
        }
      }
    }

    .img {
      &-lg {
        max-width: 300px;
        margin: 0 auto;
        transition: all .3s ease-in-out;
      }

      &-sm {
        position: absolute;
        bottom: 0;
        right: 0;
        transition: all .3s ease-in-out;
      }
    }

    &:hover {
      cursor: pointer;

      .img {
        &-sm {
          -webkit-filter: drop-shadow(11px 10px 56px #02C076);
          filter: drop-shadow(11px 10px 56px #02C076);
        }

        &-lg {
          -webkit-filter: drop-shadow(0px 4px 117px rgba(2, 192, 118, 0.34));
          filter: drop-shadow(0px 4px 37px rgba(2, 192, 118, 0.44));
        }
      }

      .text {
        .h2 {
          color: #02C076;
        }

        .p {
          color: white;
        }
      }
    }
  }

  &-item1 {
    grid-area: item1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 37px;
  }

  &-item2 {
    grid-area: item2;
  }

  &-item3 {
    grid-area: item3;
  }

  &-item4 {
    grid-area: item4;
  }

  &-item5 {
    grid-area: item5;
  }
}
</style>
