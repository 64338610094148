<script>
export default {
    name: 'SelectArrow'
}
</script>

<template>
    <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_745_417)">
            <path d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"
                  fill="#673DFD"/>
        </g>
        <defs>
            <clipPath id="clip0_745_417">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped>

</style>
