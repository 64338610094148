import service from '../plugins/request';

class PartnerService {
    /**
     * @param range
     * @returns {Promise<AxiosResponse<any>>}
     */
    getStats(range) {
        return service.get('/api/partner/stat' + range);
    }

    partnerPaginator(page = 1) {
        return service.get('/api/partner/partnerPaginator?page=' + page);
    }
}

export default new PartnerService();
