import { render, staticRenderFns } from "./tagPaymentSystems.vue?vue&type=template&id=2601b733&scoped=true"
import script from "./tagPaymentSystems.vue?vue&type=script&lang=js"
export * from "./tagPaymentSystems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2601b733",
  null
  
)

export default component.exports