<template>
  <div class="container-l">
    <div id="bannersBlock" class="feature-widget">
      <div class="feature-widget_block">
        <div class="feature-widget_block-text">
          <div class="info">
            <div class="info-title">
              Banners
            </div>
            <img class="info-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-media-buyers/>
                <div class="info-tags__item-text">
                  Media buyers
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
            </div>
            <div class="info-text">
              Use a wide range of media tools to engage your audience. We are constantly updating our media kit and
              sharing it with all our partners.
              <br>
              <br>
              Our media kit is presented both statically and dynamically for a variety of products, almost no effort is
              required from you to convert users.
            </div>
            <div class="info-list">
              <div v-for="(item, index) in infoList" :key="index" class="info-list--item">
                <list-icon/>
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <img class="feature-widget_block-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
      </div>
      <div class="feature-widget_block_bottom">
        <div class="feature-widget_block feature-widget_block-large">
          <div class="feature-widget_block--item">
            <place-banner-icon-sm/>
            <div class="text">
              <div class="title">
                Place banner
              </div>
              <div class="desc">
                Use the banner in a place that is convenient for you
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob1"/>
          <div class="feature-widget_block--item">
            <share-it-icon-sm/>
            <div class="text">
              <div class="title">
                Share it
              </div>
              <div class="desc">
                Convert your active clients to successful action
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob2"/>
          <div class="feature-widget_block--item">
            <payouts-icon-sm/>
            <div class="text">
              <div class="title">
                Get payouts
              </div>
              <div class="desc">
                Get up to $100 per customer action
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ListIcon from "@/assets/img/svg/listIcon.vue";
import ArrowRight from "@/assets/img/svg/arrowRight.vue";
import ShareItIconSm from "@/assets/img/svg/shareItIconSm.vue";
import PayoutsIconSm from "@/assets/img/svg/payoutsIconSm.vue";
import TagMediaBuyers from "@/assets/img/svg/tags/tagMediaBuyers.vue";
import TagNewsWebsites from "@/assets/img/svg/tags/tagNewsWebsites.vue";
import PlaceBannerIconSm from "@/assets/img/svg/placeBannerIconSm.vue";
import ArrowRightMob from "@/assets/img/svg/arrowRightMob.vue";

export default {
  name: "BannersWidget",
  components: {
    ArrowRightMob,
    PlaceBannerIconSm,
    TagNewsWebsites,
    TagMediaBuyers,
    PayoutsIconSm,
    ShareItIconSm,
    ArrowRight, ListIcon
  },
  data() {
    return {
      infoList: [
        'Static and Dynamic Creatives.',
        'Regular updates of the media kit.',
      ]
    }
  }
}
</script>
