import service from '../plugins/request';

class PaymentService {
    /**
     * @param query
     * @returns {Promise<AxiosResponse<T>>}
     */
    userPayment(query) {
        return service.get('/api/PartnerPayment/userPayment', query);
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<T>>}
     */
    createPayment({address, amount}) {
        return service.post('/api/PartnerPayment/partner/create', {address, amount});
    }
}

export default new PaymentService();
