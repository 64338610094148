<template>
  <div class="img">
    <EmailInput v-if="icon === 'Email'"/>
    <PasswordInput v-if="icon === 'Password'"/>
    <login-input v-if="icon === 'Login'"/>
  </div>
</template>

<script>
import EmailInput from "@/assets/img/svg/EmailInput";
import PasswordInput from "@/assets/img/svg/PasswordInput";
import LoginInput from "@/assets/img/svg/LoginInput";
export default {
  name: "IconInputs",
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  components: {LoginInput, PasswordInput, EmailInput}
}
</script>

<style scoped>

</style>
