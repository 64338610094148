<template>
<div class="landing-layout">
  <SiteHeader/>
  <slot name="landing"></slot>
  <site-footer/>
</div>
</template>

<script>
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
export default {
  name: "LandingLayout",
  components: {SiteFooter, SiteHeader}
}
</script>

<style lang="scss">
@import "../assets/styles/globals";
  .landing {
    &-layout {
      width: 100%;
      background: #1B1A1C;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;

      form {
        width: 100%;
      }
    }
  }
</style>
