<template>
    <div class="hover-info">
        <polygon-hover />
            <div class="hover-info-text">
                {{ option }}
            </div>
    </div>
</template>

<script>
    import PolygonHover from "../../../../icons/polygonHover";
    export default {
        name: "hoverText",
        components: {PolygonHover},
        data() {
            return {
                selected: this.default
                    ? this.default
                    : this.options.length > 0
                        ? this.options[0]
                        : null,
                open: false,
            };
        },
        props: {
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
            option: {
                type: Object,
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss">

</style>