<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9346 44.267L10.668 32.0003L22.9346 19.7337L25.7346 22.667L16.4013 32.0003L25.7346 41.3337L22.9346 44.267ZM13.3346 46.267H17.3346V51.3337H46.668V46.267H50.668V57.3337C50.668 58.4003 50.268 59.3337 49.468 60.1337C48.668 60.9337 47.7346 61.3337 46.668 61.3337H17.3346C16.268 61.3337 15.3346 60.9337 14.5346 60.1337C13.7346 59.3337 13.3346 58.4003 13.3346 57.3337V46.267ZM17.3346 16.9337H13.3346V6.66699C13.3346 5.60033 13.7346 4.66699 14.5346 3.86699C15.3346 3.06699 16.268 2.66699 17.3346 2.66699H46.668C47.7346 2.66699 48.668 3.06699 49.468 3.86699C50.268 4.66699 50.668 5.60033 50.668 6.66699V16.9337H46.668V12.667H17.3346V16.9337ZM17.3346 55.3337V57.3337H46.668V55.3337H17.3346ZM17.3346 8.66699H46.668V6.66699H17.3346V8.66699ZM41.068 44.267L38.268 41.3337L47.6013 32.0003L38.268 22.667L41.068 19.7337L53.3346 32.0003L41.068 44.267ZM17.3346 8.66699V6.66699V8.66699ZM17.3346 55.3337V57.3337V55.3337Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "toolsApi"
}
</script>

<style scoped>

</style>
