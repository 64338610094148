<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4673 22.1334L5.33398 16L11.4673 9.86671L12.8673 11.3334L8.20065 16L12.8673 20.6667L11.4673 22.1334ZM6.66732 23.1334H8.66732V25.6667H23.334V23.1334H25.334V28.6667C25.334 29.2 25.134 29.6667 24.734 30.0667C24.334 30.4667 23.8673 30.6667 23.334 30.6667H8.66732C8.13398 30.6667 7.66732 30.4667 7.26732 30.0667C6.86732 29.6667 6.66732 29.2 6.66732 28.6667V23.1334ZM8.66732 8.46671H6.66732V3.33337C6.66732 2.80004 6.86732 2.33337 7.26732 1.93337C7.66732 1.53337 8.13398 1.33337 8.66732 1.33337H23.334C23.8673 1.33337 24.334 1.53337 24.734 1.93337C25.134 2.33337 25.334 2.80004 25.334 3.33337V8.46671H23.334V6.33337H8.66732V8.46671ZM8.66732 27.6667V28.6667H23.334V27.6667H8.66732ZM8.66732 4.33337H23.334V3.33337H8.66732V4.33337ZM20.534 22.1334L19.134 20.6667L23.8007 16L19.134 11.3334L20.534 9.86671L26.6673 16L20.534 22.1334ZM8.66732 4.33337V3.33337V4.33337ZM8.66732 27.6667V28.6667V27.6667Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "apiIcon"
}
</script>

<style scoped>

</style>
