<template>
  <div class="head_block">
    <div class="head_block_text">
      <div class="title">
        Instant <br>
        exchange API
      </div>
      <div class="desc">
        Redaction by 24 august 2022
      </div>
      <div class="text">
        Swapuz API provides an opportunity to get an access to the services of Swapuz platform. You can request more features by contacting our developers team
        <a href="mailto:support@swapuz.com">support@swapuz.com</a>
      </div>
    </div>
    <div class="buttons">
        <div class="buttons--item">
          <link-icon-black/>
        </div>
      <div class="buttons--item">
        <print-icon-black/>
      </div>
    </div>
  </div>
</template>

<script>
import LinkIconBlack from "@/assets/img/svg/linkIconBlack.vue";
import PrintIconBlack from "@/assets/img/svg/printIconBlack.vue";

export default {
  name: "TitleBlock",
  components: {PrintIconBlack, LinkIconBlack}
}
</script>

<style lang="scss">
  .head_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 64px;

    @media (max-width: 1079px) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-bottom: 20px;
    }

    &_text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1079px) {
        align-items: center;
      }

      .title {
        font-weight: 900;
        font-size: 64px;
        line-height: 72px;
        color: #FFFFFF;
        margin-bottom: 8px;

        @media (max-width: 1079px) {
          font-weight: 900;
          font-size: 40px;
          line-height: 44px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #FFFFFF;
        }
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.38);

        @media (max-width: 1079px) {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: rgba(255, 255, 255, 0.38);
        }
      }

      .text {
        margin-top: 24px;
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;

        a {
          color: #02C076;
        }

        @media (max-width: 1079px) {
          text-align: center;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;

      @media (max-width: 1079px) {
        display: none;
      }

      &--item {
        padding: 12px;
        background: #02C076;
        border-radius: 8px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>
