<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9993 21.2L17.6327 17.6333L21.1993 16L17.6327 14.3667L15.9993 10.8L14.366 14.3667L10.7993 16L14.366 17.6333L15.9993 21.2ZM4.66602 26C4.13268 26 3.66602 25.8 3.26602 25.4C2.86602 25 2.66602 24.5333 2.66602 24V8C2.66602 7.46667 2.86602 7 3.26602 6.6C3.66602 6.2 4.13268 6 4.66602 6H27.3327C27.866 6 28.3327 6.2 28.7327 6.6C29.1327 7 29.3327 7.46667 29.3327 8V24C29.3327 24.5333 29.1327 25 28.7327 25.4C28.3327 25.8 27.866 26 27.3327 26H4.66602ZM4.66602 24H27.3327V8H4.66602V24Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "bannerIcon"
}
</script>

<style scoped>

</style>
