<template>
  <div class="api-block auth-block">
    <div class="api-block-title">
      Authorization process
    </div>
    <div class="desc">
      For some requests, you need an authorization key. Of course, there are requests for which authorization is not mandatory, but it is needed to access some functions (assigning transactions to the user’s personal account).
    </div>
    <div class="desc">
      These parameters should be used in headers to set up API authorization:
    </div>
    <div class="table-title">
      Parameters:
    </div>
    <div class="table_block">
      <table class="table table_parameters">
        <thead>
        <tr>
          <th>Headers</th>
          <th></th>

        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Api-key</td>
          <td>{API Key}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="desc">
      *You must replace {API Key} with your personal API key.
    </div>
    <div class="desc">
      You can get {API Key} after <router-link :to="{name: 'Registration'}">register</router-link> or sending a request to Swapuz support team - <br>
      <a href="mailto:support@swapuz.com">support@swapuz.com</a>
    </div>
    <div class="desc">
      For <router-link :to="{name: 'Home'}">"affiliate program"</router-link> please pass the authorization headers to all requests.
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthorizationMain"
}
</script>

<style lang="scss">
  .auth-block {
    margin-bottom: 96px;
  }
</style>
