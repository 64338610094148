<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0013 15.167C12.718 15.167 11.6194 14.71 10.7055 13.7962C9.79158 12.8823 9.33464 11.7837 9.33464 10.5003V6.41699C9.33464 5.93088 9.50477 5.51769 9.84505 5.17741C10.1853 4.83713 10.5985 4.66699 11.0846 4.66699C11.3763 4.66699 11.6534 4.73505 11.9159 4.87116C12.1784 5.00727 12.3874 5.20171 12.543 5.45449C12.6985 5.20171 12.9076 5.00727 13.1701 4.87116C13.4326 4.73505 13.7096 4.66699 14.0013 4.66699C14.293 4.66699 14.5701 4.73505 14.8326 4.87116C15.0951 5.00727 15.3041 5.20171 15.4596 5.45449C15.6152 5.20171 15.8242 5.00727 16.0867 4.87116C16.3492 4.73505 16.6263 4.66699 16.918 4.66699C17.4041 4.66699 17.8173 4.83713 18.1576 5.17741C18.4978 5.51769 18.668 5.93088 18.668 6.41699V10.5003C18.668 11.7837 18.211 12.8823 17.2971 13.7962C16.3832 14.71 15.2846 15.167 14.0013 15.167ZM14.0013 13.417C14.818 13.417 15.5082 13.135 16.0721 12.5712C16.636 12.0073 16.918 11.317 16.918 10.5003V7.00033H11.0846V10.5003C11.0846 11.317 11.3666 12.0073 11.9305 12.5712C12.4944 13.135 13.1846 13.417 14.0013 13.417ZM4.66797 24.5003V21.7587C4.66797 21.0198 4.85269 20.3878 5.22214 19.8628C5.59158 19.3378 6.06797 18.9392 6.6513 18.667C7.95408 18.0837 9.20339 17.6462 10.3992 17.3545C11.5951 17.0628 12.7957 16.917 14.0013 16.917C15.2069 16.917 16.4027 17.0677 17.5888 17.3691C18.7749 17.6705 20.0194 18.1031 21.3221 18.667C21.9249 18.9392 22.411 19.3378 22.7805 19.8628C23.1499 20.3878 23.3346 21.0198 23.3346 21.7587V24.5003H4.66797ZM6.41797 22.7503H21.5846V21.7587C21.5846 21.4475 21.4923 21.151 21.3076 20.8691C21.1228 20.5871 20.8944 20.3781 20.6221 20.242C19.3777 19.6392 18.2402 19.226 17.2096 19.0024C16.1791 18.7788 15.1096 18.667 14.0013 18.667C12.893 18.667 11.8138 18.7788 10.7638 19.0024C9.7138 19.226 8.5763 19.6392 7.3513 20.242C7.07908 20.3781 6.85547 20.5871 6.68047 20.8691C6.50547 21.151 6.41797 21.4475 6.41797 21.7587V22.7503Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "LoginInput"
}
</script>

<style scoped>

</style>
