import service from '../plugins/request';

class UserService {
    /**
     * @param login
     * @param password
     * @return {Promise<AxiosResponse<any>>}
     */
    login({login, password}) {
        return service.post('/api/User/login', {login, password});
    }

    /**
     * @param login
     * @param password
     * @param email
     * @return {Promise<AxiosResponse<any>>}
     */
    register({login, password, email}) {
        return service.post('/api/User/partner', {login, password, email});
    }

    /**
     * @param login
     * @return {Promise<AxiosResponse<any>>}
     */
    checkLogin(login) {
        return service.get('/api/User/checkLogin', login)
    }

    /**
     * @return {Promise<AxiosResponse<any>>}
     */
    getProfile() {
        return service.get('/api/User/profile')
    }

    /**
     * @param oldPassword
     * @param newPassword
     * @return {Promise<AxiosResponse<any>>}
     */
    changePassword({oldPassword, newPassword}) {
        return service.patch('/api/User/password', {oldPassword, newPassword})
    }

    /**
     * @param email
     * @param telegramm
     * @param minPayment
     * @param avatar
     * @param address
     * @return {Promise<AxiosResponse<any>>}
     */
    editProfile({email, telegramm, minPayment, address, avatar}) {
        return service.patch('/api/User/editProfile', {email, telegramm, minPayment, address, avatar})
    }

    /**
     * @param file
     * @returns {Promise<AxiosResponse<T>>}
     */
    uploadFile(file) {
        const fileData = new FormData;

        fileData.append("UploadFile", file);


        return service.post('/api/Other/upload', fileData)
    }
}

export default new UserService();
