<template>
    <AccountLayout>
        <template v-slot:title>
            <h2>Change password</h2>
        </template>
        <template v-slot:content>
            <div class="profile-edit">
                <form>
                    <div class="profile-edit-form">

                        <div class="profile-edit-account">
                            <div class="profile-edit-account-data">
                                <div class="profile-edit-account-data-avatar">
                                    <div class="img-upload">
                                        <avatar :img="User.partnerProfile.avatar"/>
                                    </div>
                                </div>
                                <div class="profile-edit-account-data-title">
                                    <div class="name">
                                        {{User.login}}
                                    </div>
                                    <div class="subscribe">
                                        {{User.partnerProgramName}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-edit-form-data">
                            <div class="form-input-group margin"
                                 :class="{ 'form-group--error': $v.form.oldPassword.$error }">
                                <label for="old_password" class="input-title">Current password</label>
                                <input id="old_password"
                                       :type="passwordType"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.oldPassword.$model"
                                >
                                <i @click="changePasswordType"
                                   class="icon icon-show"
                                >
                                    <img alt="Vue logo" src="~@/assets/img/show.svg">
                                </i>
                                <div class="desc">
                                    <label class="desc-text" v-if="!$v.form.oldPassword.required">Push Your
                                        Tample</label>
                                </div>
                            </div>

                            <div class="form-input-group" :class="{ 'form-group--error': $v.form.newPassword.$error }">
                                <label for="password" class="input-title">New password</label>
                                <input id="password"
                                       type="password"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.newPassword.$model"
                                       :class="{ 'active-input': form.newPassword.length}"
                                >
                                <div class="desc">
                                    <label class="desc-text" v-if="!$v.form.newPassword.required">Push Your
                                        Tample</label>
                                </div>
                            </div>

                            <div class="form-input-group"
                                 :class="{ 'form-group--error': $v.form.password_confirm.$error }">
                                <label for="password_confirm" class="input-title">Confirm new password</label>
                                <input id="password_confirm"
                                       type="password"
                                       class="form-control"
                                       placeholder=""
                                       v-model.trim="$v.form.password_confirm.$model">
                                <div class="desc">
                                    <label class="desc-text" v-if="!$v.form.password_confirm.required">Push Your
                                        Tample</label>
                                </div>
                            </div>

                            <div class="buttons">
                                <router-link :to="{ name: 'Profile' }" class="btn-cancel">
                                    Cancel
                                </router-link>
                                <button class="btn-save"
                                        @click.prevent="confirmEdit"
                                >
                                    Save password
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </AccountLayout>
</template>

<script>
    import {required, minLength, sameAs} from 'vuelidate/lib/validators'
    import AccountLayout from "../../components/AccountLayout";
    import Avatar from "../../components/Avatar";

    export default {
        name: "ChangePassword",
        components: {Avatar, AccountLayout},
        data() {
            return {
                passwordType: 'password',
                form: {
                    submitStatus: null,
                    oldPassword: '',
                    newPassword: '',
                    password_confirm: '',
                },
            }
        },
        validations: {
            form: {
                oldPassword: {
                    required,
                    minLength: minLength(6)
                },
                newPassword: {
                    required,
                },
                password_confirm: {
                    required,
                    sameAsPassword: sameAs('newPassword')
                }
            }
        },
        computed: {
            User: {
                get() {
                    return this.$store.state.user;
                },
                set(value) {
                    this.$store.commit('setter', {user: value});
                },
            }
        },
        methods: {
            confirmEdit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$store.dispatch('changePassword', {
                        oldPassword: this.form.oldPassword,
                        newPassword: this.form.newPassword
                    }).then(() => {
                        this.$router.push({name: 'Profile'})
                    });
                }
            },
            changePasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            },
        },
    }
</script>

<style lang="scss">
    .profile {
        &-edit {
            max-width: 536px;
            margin: 40px auto 0;

            &-form {
                background: white;
                border-radius: 8px;
                border: 1px solid #DFE0EB;

                &-data {
                    padding: 0 48px 48px 48px;

                    input:focus {
                        outline: none !important;
                        border: none;
                        box-shadow: inset 0 0 0 1.5px #673DFD;
                        transition: .3s ease-in-out;
                    }

                    .form-input-group {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 28px;
                        position: relative;

                        &:last-child {
                            padding-bottom: 48px;
                        }

                        .active-input {
                            background: rgba(103, 61, 253, 0.03);
                        }

                        .input-title {
                            font-size: 14px;
                            color: #673DFD;
                            padding-bottom: 8px;
                            line-height: 1;
                        }

                        .desc {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            &-text {
                                font-size: 12px;
                                color: #9FA2B4;
                                padding-top: 6px;
                                line-height: 1;
                            }

                            .errors {
                                display: none;
                            }

                            .forgot {
                                font-size: 12px;
                                color: #673DFD;
                                padding-top: 6px;
                                line-height: 1;
                            }
                        }

                        .icon {
                            position: absolute;
                            right: 20px;
                            top: 27px;

                            &-hide {
                                opacity: 0.5;
                            }
                        }
                    }

                    .margin {
                        margin-top: 30px;
                    }

                    .form-group--error {

                        .input-title {
                            color: #FF783E;
                        }

                        .form-control {
                            box-shadow: inset 0 0 0 1.5px #FF783E;
                            transition: .3s ease-in-out;
                            border: none;
                        }

                        .errors {
                            display: block;
                            color: #FF783E;
                            font-size: 12px;
                            padding-top: 6px;
                            line-height: 1;
                        }

                        .desc {
                            &-text {
                                color: #FF783E;
                            }
                        }

                        .icon {
                            img {
                                filter: invert(53%) sepia(56%) saturate(1015%) hue-rotate(333deg) brightness(101%) contrast(101%);
                            }
                        }
                    }

                    .buttons {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;

                        @media (max-width: 764px) {
                            justify-content: space-between;
                        }

                        .btn-save {
                            background: #673DFD;
                            color: white;
                            padding: 14px 29px;
                            border: none;
                            border-radius: 4px;
                            font-size: 14px;
                        }

                        .btn-cancel {
                            padding: 14px 29px;
                            color: #673DFD;
                            text-align: center;
                            font-size: 14px;
                            background: none;
                            border: none;
                            outline: none;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            @media (max-width: 764px) {
                                padding: 14px 29px;
                            }

                            img {
                                padding-right: 8px;
                            }
                        }
                    }

                    .right {
                        justify-content: end;
                    }
                }
            }

            &-account {
                padding: 32px 48px;

                &-data {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &-avatar {
                        .img-upload > input {
                            display: none;
                        }

                        .img-upload img {
                            width: 80px;
                            cursor: pointer;
                        }
                    }

                    &-avatar img {
                        -webkit-border-radius: 90px;
                        -moz-border-radius: 90px;
                        border-radius: 90px;
                        -khtml-border-radius: 90px;
                        border: 2px solid #02C076;
                        box-shadow: inset 0 0 0 3px white;
                        height: auto;
                        max-width: 100%;
                        width: 100px;
                        max-height: 100px;

                    }

                    &-title {
                        padding-left: 28px;

                        .name {
                            font-size: 20px;
                            font-weight: 700;
                            padding-bottom: 8px;
                            line-height: 24px;
                        }

                        .subscribe {
                            color: #02C076;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 0.2px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
</style>