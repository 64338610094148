<template>
  <div class="container-l">
    <div class="lets-start">
      <div class="text">
        <div class="text-head">
          Lets start?
        </div>
        <div class="text-desc">
          Register. Choose a convenient tools. Convert your users. Get instant payouts. Simple, right?
        </div>
        <router-link :to="{name: 'Registration'}" class="button">
          Become our partner
        </router-link>
      </div>
      <div class="rocket rocket_desktop">
        <img class="rocket-smoke" src="../../../assets/img/png/rocketSmoke.png" alt="">
        <img class="rocket-img" src="../../../assets/img/png/rocket.png" alt="">
      </div>
      <div class="rocket rocket_mob">
        <img
            class="rocket-img"
            src="../../../assets/img/png/rocketMob2x.png"
            srcset="../../../assets/img/png/rocketMob1x.png 1x,
            ../../../assets/img/png/rocketMob2x.png 2x,
            ../../../assets/img/png/rocketMob4x.png 4x"
            alt="">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LetsStart"
}
</script>

<style lang="scss">
.lets-start {
  margin-top: 80px;
  margin-bottom: 120px;
  background: #02C076;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media (max-width: 1079px) {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  &:hover {
    .rocket {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
    }
  }

  .text {
    padding: 40px 0 48px 48px;
    box-sizing: border-box;
    max-width: 472px;
    width: 100%;

    @media (max-width: 1079px) {
      padding: 20px 0 16px 24px;
      position: relative;
      z-index: 9;
    }

    &-head {
      font-weight: 900;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -0.02em;
      color: #1B1A1C;

      @media (max-width: 1079px) {
        font-size: 36px;
        line-height: 38px;
      }
    }

    &-desc {
      margin-top: 12px;
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #29282A;

      @media (max-width: 1079px) {
        font-size: 14px;
        line-height: 22px;
        max-width: 200px;
        margin-bottom: 20px;
      }
    }

    .button {
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 236px;
      width: 100%;
      box-sizing: border-box;
      background: #29282A;
      backdrop-filter: blur(13.5914px);
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      transition: all .3s ease-in-out;

      @media (max-width: 1079px) {
        max-width: 196px;
        padding: 12px 0;
        transform: translateX(-8px);
      }

      &:hover {
        background: #333234;
        transform: translateY(-2px);
        cursor: pointer;
      }
    }
  }

  .rocket {
    width: 416px;
    height: 360px;
    position: absolute;
    top: 24px;
    right: 48px;

    &_mob {
      display: none;
    }

    &_desktop {
      display: block;
    }

    @media (max-width: 1079px) {
      width: 100%;
      height: 222px;
      right: 4px;
      top: unset;
      bottom: 0;

      &_mob {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    &-smoke {
      max-width: 180px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-img {
      max-width: 320px;
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 1079px) {
        top: unset;
        bottom: 0;
      }
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
</style>
