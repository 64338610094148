import { render, staticRenderFns } from "./payoutsIconSm.vue?vue&type=template&id=9b487ea2&scoped=true"
import script from "./payoutsIconSm.vue?vue&type=script&lang=js"
export * from "./payoutsIconSm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b487ea2",
  null
  
)

export default component.exports