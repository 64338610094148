<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.1 32.9499L32.95 30.8499L38.25 25.5499H6V22.5499H38.3L33 17.2499L35.1 15.1499L44 24.0499L35.1 32.9499Z" fill="white" fill-opacity="0.12"/>
  </svg>
</template>

<script>
export default {
  name: "arrowRight"
}
</script>

<style scoped>

</style>
