<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#29282A"/>
    <g clip-path="url(#clip0_1174_140)">
      <path d="M8 28H32V25.3333H8V28ZM8 21.3333H32V18.6667H8V21.3333ZM8 12V14.6667H32V12H8Z" fill="#02C076"/>
    </g>
    <defs>
      <clipPath id="clip0_1174_140">
        <rect width="32" height="32" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "burgerIcon"
}
</script>

<style scoped>

</style>
