<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#02C076"/>
    <path d="M16.1559 6.05868L8.6208 13.5938L5.85212 10.8251C5.71789 10.6909 5.50024 10.6909 5.36597 10.8251L4.55575 11.6353C4.42152 11.7696 4.42152 11.9872 4.55575 12.1215L8.37771 15.9434C8.51194 16.0777 8.72959 16.0777 8.86386 15.9434L17.4522 7.35505C17.5865 7.22082 17.5865 7.00317 17.4522 6.8689L16.642 6.05868C16.5078 5.92445 16.2901 5.92445 16.1559 6.05868Z" fill="#29282A"/>
  </svg>
</template>

<script>
export default {
  name: "listIcon"
}
</script>

<style scoped>

</style>
