<template>
  <div class="container-l">
    <div class="profit-calc">
      <div class="banner">
        <div class="banner_img">
          <img
              class="banner_img-desktop"
              srcset="../../../assets/img/png/profit2x.png 2x,
              ../../../assets/img/png/profit1x.png 1x,
              ../../../assets/img/png/profit4x.png 4x"
              src="../../../assets/img/png/profit2x.png" alt="">
          <img
              class="banner_img-mob"
              srcset="../../../assets/img/png/profitMob2x.png 2x,
              ../../../assets/img/png/profitMob1x.png 1x,
              ../../../assets/img/png/profitMob4x.png 4x"
              src="../../../assets/img/png/profitMob2x.png" alt="">
        </div>
        <div>
          <div class="banner_title">
            Profit calculator
          </div>
          <div class="banner_desc">
            It's easy to earn crypto with us! We created a profit сalculator that shows your earnings based on our
            statistics.
          </div>
        </div>
        <router-link :to="{name: 'Registration', savedPosition: false}" class="banner_button">
          Become our partner
        </router-link>
      </div>
      <div class="calc">
        <div class="calc_tabs">
          <div class="calc_tabs--item calc_tabs--item_active">
            <div class="calc_tabs--item_content">
              <api-icon/>

              <div class="calc_tabs--item_text">
                API
              </div>
            </div>
          </div>
          <div class="calc_tabs--item">
            <div class="soon">
              Soon
            </div>
            <div class="calc_tabs--item_content">
              <widget-icon/>

              <div class="calc_tabs--item_text">
                Widget
              </div>
            </div>
          </div>
          <div class="calc_tabs--item">
            <div class="soon">
              Soon
            </div>
            <div class="calc_tabs--item_content">
              <link-icon/>

              <div class="calc_tabs--item_text">
                Link
              </div>
            </div>
          </div>
          <div class="calc_tabs--item">
            <div class="soon">
              Soon
            </div>
            <div class="calc_tabs--item_content">
              <banner-icon/>

              <div class="calc_tabs--item_text">
                Banner
              </div>
            </div>
          </div>
        </div>
        <div class="calc_slider">
          <div>
            <div class="calc_slider-title">
              Transactions volume
            </div>
            <div class="calc_slider-amount">
              <span>{{ amountBTC }}</span> BTC
            </div>
            <div class="calc_slider-input">
              <Slider
                  v-model="amountBTC"
                  :tooltips="false"
                  class="slider-blue"
                  :min="0"
                  :max="1000"
                  :lazy="false"
                  @update="calcProfit"
              ></Slider>
              <div class="range">
                <div class="min">
                  0 BTC
                </div>
                <div class="max">
                  1000 BTC
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="calc_slider-title">
              You get
            </div>
            <div class="calc_slider-amount calc_slider-amount--big">
              <span> {{ amountResult }}</span> BTC
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'Registration', savedPosition: false}" class="button-mob">
        Become our partner
      </router-link>
    </div>

  </div>

</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import "@vueform/slider/themes/default.css"
import ApiIcon from "@/assets/img/svg/apiIcon.vue";
import WidgetIcon from "@/assets/img/svg/widgetIcon.vue";
import BannerIcon from "@/assets/img/svg/bannerIcon.vue";
import LinkIcon from "@/assets/img/svg/linkIcon.vue";

export default {
  name: "ProfitCalc",
  components: {
    LinkIcon,
    BannerIcon,
    WidgetIcon,
    ApiIcon,
    Slider,
  },
  data() {
    return {
      amountBTC: 100,
      amountResult: 0
    }
  },
  mounted() {
    this.calcProfit();
  },
  methods: {
    calcProfit() {
      this.amountResult = this.amountBTC * 0.0075;
      this.amountResult = this.amountResult.toFixed(4)
    }
  }
}
</script>

<style lang="scss">

.profit-calc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  min-height: 394px;
  margin-bottom: 120px;

  @media (max-width: 1079px) {
    flex-direction: column;
    margin-bottom: 36px;
  }

  .banner {
    background: #02C076;
    box-sizing: border-box;
    border-radius: 20px 0 0 20px;
    max-width: 580px;
    width: 100%;
    padding: 40px 48px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

    @media (max-width: 1079px) {
      border-radius: 20px 20px 0 0;
      align-items: center;
      padding: 24px 24px 36px;
    }

    &_title {
      font-weight: 900;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -0.02em;
      color: #1B1A1C;

      @media (max-width: 1079px) {
        margin-top: 16px;
        font-size: 36px;
        line-height: 38px;
        text-align: center;
      }
    }

    &_desc {
      margin-top: 12px;
      max-width: 424px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #29282A;

      @media (max-width: 1079px) {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }
    }

    &_img {
      @media (min-width: 1079px) {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
      }

      &-desktop {
        @media (max-width: 1079px) {
          display: none;
        }
      }

      &-mob {
        display: none;

        @media (max-width: 1079px) {
          display: block;
        }
      }
    }

    &_button {
      align-self: flex-end;
      position: relative;
      z-index: 1;
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 236px;
      width: 100%;
      box-sizing: border-box;
      background: #29282A;
      backdrop-filter: blur(13.5914px);
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      transition: all .3s ease-in-out;

      @media (max-width: 1079px) {
        display: none;
      }

      &:hover {
        background: #333234;
        transform: translate(-2px -8px);
        cursor: pointer;
      }
    }
  }

  .calc {
    max-width: 496px;
    width: 100%;
    background: #1F1E20;
    box-sizing: border-box;
    border-radius: 0 20px 20px 0;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1079px) {
      position: relative;
      z-index: 9;
      transform: translateY(-20px);
      border-radius: 20px;
      padding: 20px 16px 24px 16px;
    }

    &_tabs {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8px;

      @media (max-width: 1079px) {
        margin-bottom: 24px;
      }

      &--item {
        width: 102px;
        height: 80px;
        padding: 12px 0;
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(13.5914px);
        border-radius: 12px;
        display: grid;
        place-content: center;
        position: relative;

        .soon {
          position: absolute;
          top: -8px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: #FF783E;
          border-radius: 4px;
          max-width: 39px;
          width: 100%;
          height: 18px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }

        &_content {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

        }

        &_text {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: rgba(255, 255, 255, 0.38);
        }

        &_active {
          background: #02C076;

          .calc_tabs--item_content {
            svg {
              path {
                fill: #29282A;
                fill-opacity: 1;
              }
            }
          }

          .calc_tabs--item_text {
            color: #29282A;
          }
        }
      }
    }

    &_slider {
      display: flex;
      flex-direction: column;
      gap: 28px;

      @media (max-width: 1079px) {
        gap: 20px;
      }

      &-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #02C076;
        margin-bottom: 4px;
      }

      &-amount {
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.38);
        margin-bottom: 16px;

        span {
          font-weight: 900;
          color: white;
        }

        &--big {
          font-size: 56px;
          line-height: 50px;
          letter-spacing: -0.02em;
          margin-bottom: 0;

          @media (max-width: 1079px) {
            font-size: 36px;
            line-height: 38px;
          }
        }
      }

      &-input {
        .slider-base {
          background: rgba(255, 255, 255, 0.12);
        }

        .slider-handle {
          width: 20px;
          height: 20px;
          top: calc((var(--slider-handle-height, 18px) - var(--slider-height, 6px)) / 2 * -1 + -1px);
        }

        .range {
          margin-top: 14px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .min, .max {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: rgba(255, 255, 255, 0.38);

          }
        }
      }
    }


  }

  .button-mob {
    display: none;
    padding: 18px 0;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1B1A1C;
    background: #02C076;
    border-radius: 8px;
    transform: translateY(-12px);

    @media (max-width: 1079px) {
      display: block;
    }
  }
}
</style>
