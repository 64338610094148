<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6333 16.4667L15.5333 9.36672L22.6333 2.26672L29.7333 9.36672L22.6333 16.4667ZM4 14.0667V4.03339H14.0333V14.0667H4ZM17.9333 28.0001V17.9667H27.9667V28.0001H17.9333ZM4 28.0001V17.9667H14.0333V28.0001H4ZM6 12.0667H12.0333V6.03339H6V12.0667ZM22.7333 13.7667L27.0333 9.46672L22.7333 5.16672L18.4333 9.46672L22.7333 13.7667ZM19.9333 26.0001H25.9667V19.9667H19.9333V26.0001ZM6 26.0001H12.0333V19.9667H6V26.0001Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "widgetIcon"
}
</script>

<style scoped>

</style>
