<template>
  <div class="payouts">
    <div class="payouts-table">
      <div class="col-1 id">
        <div class="payouts-table-title">ID</div>
      </div>
      <div class="col-1 uid">
        <div class="payouts-table-title">uID</div>
      </div>
      <div class="col-1">
        <div class="payouts-table-title">Date/Time</div>
      </div>
      <div class="col-3 row justify-content-center align-items-center">
        <div class="col-3 payouts-table-title from">From</div>
        <div class="col-1 arrow"></div>
        <div class="col-6 payouts-table-title to">To</div>
      </div>
      <div class="col-1 profit">
        <div class="payouts-table-title">Profit</div>
      </div>
      <div class="col-1">
        <div class="payouts-table-title">Turnover</div>
      </div>
      <div class="col-1">
        <div class="payouts-table-title status">Status</div>
      </div>
    </div>
    <div class="items">
      <ul class="items-list">
        <li class="item" v-for="(item, index) in Transactions" :key="index">
          <div class="payouts-table">
            <div class="col-1 id">
              {{ item.id }}
            </div>
            <div class="col-1 uid">
              {{ item.uid }}
            </div>
            <div class="col-1">
              <div class="item-date">
                {{ dataFormatting(item.createDate) }}
              </div>
              <div class="item-time">{{ dataFormattingHours(item.createDate) }}</div>
            </div>
            <div class="col-3 row justify-content-center align-items-center">
              <div class="col-3 item-convert from">
                <div @mouseover="tokenFrom = index" @mouseleave="tokenFrom = false" class="item-convert-amount">
                  <a v-if="item.wTxId" :href="item.withdrawalTransactionID" target="_blank">
                    <token-hover/>
                  </a>

                  <transition name="fade">
                    <hover-text
                        v-if="tokenFrom === index && item.wTxId !== null"
                        :option="item.wTxId"
                    />
                  </transition>
                  <div class="text">
                    {{ item.amount }}
                  </div>
                </div>
                <div class="item-convert-coin">{{ item.from }}</div>
              </div>
              <div class="col-1 arrow">
                <img src="../../../../assets/img/icons/from-to.svg" alt="">
              </div>
              <div class="col-6 item-convert to">
                <div @mouseover="tokenTo = index" @mouseleave="tokenTo = false" class="item-convert-amount">

                  <div class="text">
                    {{ item.amountResult }}

<!--                    <div v-if="item.startAmount !== null && item.startAmount > 0 " class="text-startAmount">-->
<!--                      <div class="arrows">-->
<!--                        <div v-if="item.amountResult > item.startAmount" class="arrows-item">-->
<!--                          <img src="../../../../assets/img/icons/plus.svg" alt="">-->
<!--                        </div>-->
<!--                        <div v-if="item.amountResult < item.startAmount" class="arrows-item">-->
<!--                          <img src="../../../../assets/img/icons/minus.svg" alt="">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      est.-->
<!--                      <div class="startAmount">-->
<!--                        {{ item.startAmount }}-->
<!--                      </div>-->

<!--                    </div>-->


                  </div>
                  <a v-if="item.wTxId" :href="item.depositTransactionID" target="_blank">
                    <token-hover-to/>
                  </a>
                  <transition name="fade">
                    <hover-text
                        v-if="tokenTo === index && item.dTxId"
                        :option="item.dTxId"
                    />
                  </transition>
                </div>
                <div class="item-convert-coin">{{ item.to }}</div>
              </div>
            </div>
            <div class="col-1 profit">
              <div class="item-sum"
                   :class="{ 'error': item.status === 10, 'waiting': item.status < 6 }"
              >
                {{ item.partnerFee }}
              </div>
              <div class="item-btc">BTC</div>
            </div>
            <div class="col-1">
              <div class="item-sum"
                   :class="{ 'error': item.status === 10, 'waiting': item.status < 6 }"
              >
                {{ item.amountBTC }}
              </div>
              <div class="item-btc">BTC</div>
            </div>
            <div class="col-1">
              <div class="item-status">
                <TransactionStatus :status="item.status"/>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import TransactionStatus from "../../../lastTransactions/partials/TransactionStatus";
import TokenHover from "../../../../icons/TokenHover";
import TokenHoverTo from "../../../../icons/TokenHoverTo";
import HoverText from "./hoverText";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "Payouts",
  props: {
    transactions: {
      type: Array,
      default: null
    }
  },
  components: {HoverText, TokenHoverTo, TokenHover, TransactionStatus},
  data() {
    return {
      transactionsData: [],
      tokenFrom: false,
      tokenTo: false,
      dateFormat: 'DD MMMM YYYY',
      dateFormatHours: 'HH:mm',
    }
  },
  computed: {
      Transactions: {
          get() {
              return this.$store.state.transactions;
          },
      },
  },
  methods: {
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormat)
    },
    dataFormattingHours(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatHours)
    },
  }
}
</script>

<style lang="scss">
.payouts {
  @media (max-width: 1079px) {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-table {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1079px) {
      width: 1200px;
    }

    .id {
      width: 60px;
    }

    .profit {
      width: 150px;
    }

    .arrow {
      text-align: center;
    }

    &-title {
      font-size: 14px;
      letter-spacing: 0.2px;
      font-weight: 400;
      color: #9FA2B4;
      padding-top: 32px;
      padding-bottom: 12px;
    }

    .from {
      text-align: right;

      .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
      }

      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
      {
        opacity: 0;
      }
    }

    .to {
      .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
      }

      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
      {
        opacity: 0;
      }

      .text {
        display: flex;
        flex-direction: row;

        &-startAmount {
          display: flex;
          flex-direction: row;
          color: #9FA2B4;

          .startAmount {
            color: #1B1A1C;
            margin-left: 8px;
          }
        }

        .arrows {
          margin: 0 10px;
        }
      }

    }

    .status {
      text-align: right;
    }
  }

  .items {
    @media (max-width: 1079px) {
      width: 1200px;
    }

    &-list {
      padding: 0;
      list-style: none;
    }

    .item {
      border-top: 1px solid #DFE0EB;
      padding: 14px 0;

      .error {
        color: #FF783E;
      }

      .waiting {
        color: #9FA2B4;
      }

      &-date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1B1A1C;
        padding-bottom: 5px;
      }

      &-time {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }

      &-sum {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1B1A1C;
        font-weight: 700;
        padding-bottom: 5px;
      }

      &-btc {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }

      &-convert {
        &-amount {
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.2px;
          color: #1B1A1C;
          position: relative;
          cursor: pointer;
        }

        &-coin {
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.1px;
          color: #9FA2B4;
        }
      }

      .from {
        .item-convert-amount {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: right;

          .hover-info {
            position: absolute;
            bottom: -40px;
            right: 0;
            min-width: 50px;

            .polygon {
              line-height: 0;
              border-radius: 8px;
              position: relative;
              top: 4px;
              right: 10px;
            }

            &-text {
              background: #1B1A1C;
              color: #FFFFFF;
              padding: 7px 12px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
            }
          }

          .icon {
            margin-right: 4px;
            line-height: 0;
            transition: all 0.2s ease-in-out;

            svg {
              transition: all 0.4s ease-in-out;
            }

            &:hover {
              padding: 2px;
              background: rgba(103, 61, 253, 0.06);
              border-radius: 4px;
              cursor: pointer;

              svg {
                path {
                  fill: #673DFD;
                }
              }
            }
          }

          .text {
            line-height: 20px;
            letter-spacing: 0.2px;
          }
        }
      }

      .to {
        .item-convert-amount, .item-convert-coin {
          text-align: left;
        }

        .item-convert-amount {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left;

          .hover-info {
            position: absolute;
            bottom: -40px;
            left: 0;
            min-width: 50px;

            .polygon {
              line-height: 0;
              border-radius: 8px;
              position: relative;
              top: 4px;
              left: 10px;
            }

            &-text {
              background: #1B1A1C;
              color: #FFFFFF;
              padding: 7px 12px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
            }
          }

          .icon {
            margin-left: 4px;
            line-height: 0;
            transition: all 0.2s ease-in-out;

            svg {
              transition: all 0.4s ease-in-out;
            }

            &:hover {
              padding: 2px;
              background: rgba(103, 61, 253, 0.06);
              border-radius: 4px;
              cursor: pointer;

              svg {
                path {
                  fill: #673DFD;
                }
              }
            }
          }

          .text {
            line-height: 20px;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}
</style>
