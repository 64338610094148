import { render, staticRenderFns } from "./linkIcon.vue?vue&type=template&id=f468138a&scoped=true"
import script from "./linkIcon.vue?vue&type=script&lang=js"
export * from "./linkIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f468138a",
  null
  
)

export default component.exports