<template>
    <div class="payments-status">
        <div class="payments-status-item" v-if="status === 0">
            <div class="payments-status-item-draft">
                Draft
            </div>
        </div>
        <div class="payments-status-item" v-if="status === 1">
            <div class="payments-status-item-payment">
                Payment
            </div>
        </div>
        <div class="payments-status-item" v-if="status === 2">
            <div class="payments-status-item-waiting">
                Waiting
            </div>
        </div>
        <div class="payments-status-item" v-if="status === 3">
            <div class="payments-status-item-canceled">
                Canceled
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaymentStatus",
        props: {
            status: Number
        }
    }
</script>

<style lang="scss">
    .payments-status {
        &-item {
            img {
                margin-right: 6px;
            }


    &-payment {
      background: #02C076;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }

    &-waiting {
      background: #673DFD;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }

    &-hold, &-profit, &-canceled {
      background: red;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }

    &-draft {
      background: #FF783E;
      font-size: 12px;
      line-height: 20px;
      color: white;
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
      font-weight: 700;
      white-space: nowrap;
      display: inline;
    }
  }
}
</style>
