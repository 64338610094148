<template>
  <div class="partner-list">
    <div class="technical-partners">
      <div class="title">
        TECHNICAL PARTNERS
      </div>
      <div class="technical-partners_block">
        <a href="https://www.huobi.com" target="_blank" class="item">
          <huobi-logo/>
        </a>
        <a href="https://www.kucoin.com" target="_blank" class="item">
          <kukoin-logo />
        </a>
        <a href="https://whitebit.com/" target="_blank" class="item">
          <white-coin-logo/>
        </a>
        <a href="https://www.gate.io" target="_blank" class="item">
          <gatelogo/>
        </a>
        <a href="https://www.okx.com" target="_blank" class="item">
          <okx-logo />
        </a>
        <a href="https://getblock.io/" target="_blank" class="item">
          <get-block-logo />
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import HuobiLogo from "@/assets/img/svg/tech-partners/huobiLogo.vue";
import KukoinLogo from "@/assets/img/svg/tech-partners/kukoinLogo.vue";
import WhiteCoinLogo from "@/assets/img/svg/tech-partners/whiteCoinLogo.vue";
import Gatelogo from "@/assets/img/svg/tech-partners/gatelogo.vue";
import OkxLogo from "@/assets/img/svg/tech-partners/okxLogo.vue";
import GetBlockLogo from "@/assets/img/svg/tech-partners/getBlockLogo.vue";

export default {
  name: "TechnicalPartners",
  components: {
    GetBlockLogo,
    OkxLogo,
    Gatelogo,
    WhiteCoinLogo,
    KukoinLogo,
    HuobiLogo

  },
}
</script>

<style lang="scss">
.partner-list {
  .technical-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 120px;

    @media (max-width: 1079px) {
      margin-bottom: 50px;
    }

    .title {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      color: #FFFFFF;
    }

    &_block {
      display: flex;
      flex-direction: row;
      max-width: 800px;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;

      @media (max-width: 1079px) {
        gap: 16px 24px;
        justify-content: space-between;
        padding: 0 25px;
        box-sizing: border-box;
      }


      .item {
        min-width: 180px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        box-sizing: border-box;
        border-radius: 8px;
        filter: grayscale(100%);
        transition: all .3s ease-in-out;

        @media (max-width: 1079px) {
          width: 100%;
          min-width: 1px;
          max-width: 142px;
        }

        svg {
          path {
            transition: all .3s ease-in-out;
            fill-opacity: 38%;
          }
        }

        &:hover {
          cursor: pointer;
          background: #1F1E20;
          filter: grayscale(0);

          svg {
            path {
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
