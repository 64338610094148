<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 31.8L26.45 26.45L31.8 24L26.45 21.55L24 16.2L21.55 21.55L16.2 24L21.55 26.45L24 31.8ZM7 39C6.2 39 5.5 38.7 4.9 38.1C4.3 37.5 4 36.8 4 36V12C4 11.2 4.3 10.5 4.9 9.9C5.5 9.3 6.2 9 7 9H41C41.8 9 42.5 9.3 43.1 9.9C43.7 10.5 44 11.2 44 12V36C44 36.8 43.7 37.5 43.1 38.1C42.5 38.7 41.8 39 41 39H7ZM7 36H41V12H7V36Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "placeBannerIconSm"
}
</script>

<style scoped>

</style>
