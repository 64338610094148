<template>
  <div class="container-l">
    <div id="swapuzApi" class="feature-widget">
      <div class="feature-widget_block">
        <div class="feature-widget_block-text">
          <div class="info">
            <div class="info-title">
              Swapuz API
            </div>
            <img class="info-img" src="../../../../assets/img/svg/swapuz-api.svg" alt="">
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-crypto-services/>
                <div class="info-tags__item-text">
                  Crypto services
                </div>
              </div>
              <div class="info-tags__item">
                <tag-aggregators/>
                <div class="info-tags__item-text">
                  Aggregators
                </div>
              </div>
              <div class="info-tags__item">
                <tag-wallets/>
                <div class="info-tags__item-text">
                  Wallets
                </div>
              </div>
              <div class="info-tags__item">
                <tag-payment-systems/>
                <div class="info-tags__item-text">
                  Payment systems
                </div>
              </div>
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
            </div>
            <div class="info-text">
              We provide an API for integrating fixed and floating rate exchange flows. You can manage your profits from
              exchanges by varying exchange rates. Our API is easy to use and transparent — check it out yourself.
            </div>
            <div class="info-list">
              <div v-for="(item, index) in infoList" :key="index" class="info-list--item">
                <list-icon/>
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <img class="feature-widget_block-img" src="../../../../assets/img/svg/swapuz-api.svg" alt="">
      </div>
      <div class="feature-widget_block_bottom">
        <div class="feature-widget_block feature-widget_block-large">
          <div class="feature-widget_block--item">
            <implement-icon-sm/>
            <div class="text">
              <div class="title">
                Implement API
              </div>
              <div class="desc">
                Integrate our API into your system
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob1"/>
          <div class="feature-widget_block--item">
            <share-it-icon-sm/>
            <div class="text">
              <div class="title">
                Share it
              </div>
              <div class="desc">
                Convert your active clients to successful exchanges
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob2"/>
          <div class="feature-widget_block--item">
            <payouts-icon-sm/>
            <div class="text">
              <div class="title">
                Get payouts
              </div>
              <div class="desc">
                Receive from 0.5% to 2% of each transaction
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TagCryptoServices from "@/assets/img/svg/tags/tagCryptoServices.vue";
import TagAggregators from "@/assets/img/svg/tags/tagAggregators.vue";
import TagWallets from "@/assets/img/svg/tags/tagWallets.vue";
import TagPaymentSystems from "@/assets/img/svg/tags/tagPaymentSystems.vue";
import TagCoinsWebsites from "@/assets/img/svg/tags/tagCoinsWebsites.vue";
import ListIcon from "@/assets/img/svg/listIcon.vue";
import ArrowRight from "@/assets/img/svg/arrowRight.vue";
import ImplementIconSm from "@/assets/img/svg/implementIconSm.vue";
import ShareItIconSm from "@/assets/img/svg/shareItIconSm.vue";
import PayoutsIconSm from "@/assets/img/svg/payoutsIconSm.vue";
import ArrowRightMob from "@/assets/img/svg/arrowRightMob.vue";

export default {
  name: "SwapuzApi",
  components: {
    ArrowRightMob,
    PayoutsIconSm,
    ShareItIconSm,
    ImplementIconSm,
    ArrowRight, ListIcon, TagCoinsWebsites, TagPaymentSystems, TagWallets, TagAggregators, TagCryptoServices},
  data() {
    return {
      infoList: [
        'Opportunity for users that no one else will provide.',
        'Set any % from your side for your users and regulate your profits.',
        'You can visually simplify or detail the process on your site.'
      ]
    }
  }
}
</script>
