<template>
  <div class="api-block basic-block">
    <div class="api-block-title">
      Basic info
    </div>

    <div class="desc">
      All request should use the domain:
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript " id="getApi">GET 'https://api.swapuz.com/api/'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>

    </div>

    <div class="desc desc-padding">
      This API endpoint returns the paginated list of available currencies.
    </div>
    <div class="table-title">
      Parameters:
    </div>
    <div class="table_block">
      <table class="table table_parameters">
        <thead>
        <tr>
          <th>Headers</th>
          <th></th>

        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Accept</td>
          <td>application/json</td>
        </tr>
        <tr>
          <td>Content-Type</td>
          <td>application/json</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>

import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "BasicInfo",
  components: {CopyText},
  data() {
    return {}
  },
  methods: {
    copyFunction() {
      const copyText = document.getElementById("getApi").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style lang="scss">
  .basic-block {
    margin-bottom: 96px;
  }
</style>
