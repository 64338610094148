<template>
  <div class="api-block">
    <div class="title">
      Create Order
    </div>
    <div class="desc">
      This request is using API Key from collection partner
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="createOrder">curl --location --request POST 'https://api.swapuz.com/api/home/v1/order'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>

    <div class="api-block_code-title">
      Body:
    </div>

    <div class="api-block_code">
      <pre v-highlightjs><code class="json">{
  "from": "string",
  "fromNetwork": "string",
  "to": "string",
  "toNetwork": "string",
  "address": "string",
  "memo": "string",
  "amount": 0,
  "amountResult": 0,
  "mode": "fix|float",
  "addressUserFrom": "string",
  "memoUserFrom": "string",
  "extraIdReceive": "string",
  "addressRefund": "string",
  "extraIdRefound": "string"
}'</code></pre>
    </div>

    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json">{
  "result": {
    "uid": "mxdba8ac5e",
    "from": {
      "id": 4,
      "name": "XMR",
      "shortName": "XMR",
      "image": "/img/coin/user/67b1d2c4-0adf-4493-87ad-1c90da2f2427.png",
      "isMemo": false,
      "isDeposit": true,
      "isWithdraw": true,
      "network": [
        {
          "id": 94,
          "name": "Monero",
          "shortName": "XMR",
          "regexAddress": "^[48][a-zA-Z|\\d]{94}([a-zA-Z|\\d]{11})?$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": true,
          "withdrawFee": 0.0001,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 3
        }
      ]
    },
    "to": {
      "id": 1,
      "name": "Bitcoin",
      "shortName": "BTC",
      "image": "/img/coin/user/22fe07ba-1188-44d1-92c6-00994cfc92e2.png",
      "isMemo": false,
      "isDeposit": true,
      "isWithdraw": true,
      "network": [
        {
          "id": 505,
          "name": "BTC(SegWit)",
          "shortName": "SEGWITBTC",
          "regexAddress": "",
          "isDeposit": true,
          "isWithdraw": false,
          "isDefault": false,
          "withdrawFee": 0.0005,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 1
        },
        {
          "id": 506,
          "name": "Bitcoin",
          "shortName": "BTC",
          "regexAddress": "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": true,
          "withdrawFee": 0.0005,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 1
        },
        {
          "id": 564,
          "name": "Ethereum (ERC20)",
          "shortName": "ETH",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.00065,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 12
        },
        {
          "id": 591,
          "name": "Binance Chain (BEP2)",
          "shortName": "BNB",
          "regexAddress": "^(bnb1)[0-9a-z]{38}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.0000056,
          "memoRegex": "^[0-9A-Za-z\\-_]{1,120}$",
          "isMemo": true,
          "isActive": true,
          "minConfirmations": 1
        },
        {
          "id": 592,
          "name": "Binance Smart Chain (BEP20)",
          "shortName": "BSC",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.0000056,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 15
        }
      ]
    },
    "amount": 104,
    "status": 6,
    "createDate": "2021-12-14T19:02:11.550758",
    "addressFrom": "8BWxprT3iu7AYdeR5Z58ro2zGXSLRLt46dTSzyQoaNVmiPgDNsiej2jUVX14xRVPrYcYnTZewow2d8WgMh2vWDgU8iLeNfi",
    "addressTo": "bc1qf60z47hau83pf5gzpk5x0a2tw3kfejayrlneww",
    "addressFromNetwork": null,
    "addressToNetwork": "BTC",
    "memoFrom": "",
    "memoTo": null,
    "amountResult": 0.40113,
    "modeCurs": null,
    "finishPayment": "2021-12-14T22:02:11.550761",
    "currentDate": "2021-12-14T21:42:22.3839689+02:00",
    "extraIdReceive": null,
    "addressRefund": null,
    "extraIdRefund": null,
    "withdrawalTransactionID": "https://blockchair.com/search?q=ca087b3a5184d57cef4116085b97a7d7d7ebd2dcd1a2c58c9590a737bc363068",
    "wTxId": "ca087b3a5184d57cef4116085b97a7d7d7ebd2dcd1a2c58c9590a737bc363068"
  },
  "status": 200
}</code></pre>
    </div>
  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "CreateOrder",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("createOrder").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style scoped>

</style>
