<template>
  <div class="feature">
    <swapuz-api/>
    <exchange-widget/>
    <referral-links/>
    <banners-widget/>
  </div>
</template>

<script>
import SwapuzApi from "@/pages/landing/partials/features/SwapuzApi.vue";
import ExchangeWidget from "@/pages/landing/partials/features/ExchangeWidget.vue";
import ReferralLinks from "@/pages/landing/partials/features/ReferralLinks.vue";
import BannersWidget from "@/pages/landing/partials/features/BannersWidget.vue";

export default {
  name: "FeaturesBlock",
  components: {BannersWidget, ReferralLinks, ExchangeWidget, SwapuzApi}
}
</script>

<style lang="scss">
@import "../../../assets/styles/components/feature-widget";
</style>
