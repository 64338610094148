<template>
    <div class="avatar">
        <img v-if="img" :src="getImage()">
        <img v-else src="~@/assets/img/icon-plug.svg">
    </div>
</template>

<script>
    import {MAIN_DOMAIN} from "../store/config";

    export default {
        name: "Avatar",
        props: {
            img: String,
        },
        data() {
            return {
                mainDomain: MAIN_DOMAIN
            }
        },
        methods: {
            getImage() {
                if (this.img.search('data:image') >= 0) {
                    return this.img;
                } else {
                    return this.mainDomain + this.img;
                }
            }
        }
    }
</script>

<style scoped>

</style>