<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.2667 32.9332L31.0667 18.7332L45.2667 4.5332L59.4667 18.7332L45.2667 32.9332ZM8 28.1332V8.06654H28.0667V28.1332H8ZM35.8667 55.9999V35.9332H55.9333V55.9999H35.8667ZM8 55.9999V35.9332H28.0667V55.9999H8ZM12 24.1332H24.0667V12.0665H12V24.1332ZM45.4667 27.5332L54.0667 18.9332L45.4667 10.3332L36.8667 18.9332L45.4667 27.5332ZM39.8667 51.9999H51.9333V39.9332H39.8667V51.9999ZM12 51.9999H24.0667V39.9332H12V51.9999Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "toolsWidget"
}
</script>

<style scoped>

</style>
