<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.0088 32.1319L23.9984 38.1424C22.042 40.0987 19.685 41.0769 16.9273 41.0769C14.1696 41.0769 11.8126 40.0987 9.85622 38.1424C7.8999 36.186 6.92173 33.829 6.92173 31.0713C6.92173 28.3136 7.8999 25.9565 9.85622 24.0002L15.8666 17.9898L17.988 20.1111L11.9775 26.1215C10.6105 27.4886 9.92693 29.1385 9.92693 31.0713C9.92693 33.004 10.6105 34.654 11.9775 36.021C13.3446 37.3881 14.9945 38.0716 16.9273 38.0716C18.8601 38.0716 20.51 37.3881 21.877 36.021L27.8874 30.0106L30.0088 32.1319ZM19.5789 30.541L17.4576 28.4196L28.4178 17.4595L30.5391 19.5808L19.5789 30.541ZM32.1301 30.0106L30.0088 27.8893L36.0192 21.8789C37.3862 20.5118 38.0698 18.8619 38.0698 16.9292C38.0698 14.9964 37.3862 13.3465 36.0192 11.9794C34.6521 10.6123 33.0022 9.9288 31.0694 9.9288C29.1367 9.92879 27.4868 10.6123 26.1197 11.9794L20.1093 17.9898L17.988 15.8685L23.9984 9.85809C25.9547 7.90176 28.3117 6.92359 31.0694 6.92359C33.8271 6.92359 36.1842 7.90176 38.1405 9.85809C40.0968 11.8144 41.075 14.1714 41.075 16.9292C41.075 19.6869 40.0968 22.0439 38.1405 24.0002L32.1301 30.0106Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "addLinkIconSm"
}
</script>

<style scoped>

</style>
