<template>
  <svg width="145" height="42" viewBox="0 0 145 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2470_348)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M58.8468 18.6269C58.5487 17.0461 57.7073 15.6193 56.4684 14.5933C55.2294 13.5672 53.6708 13.0065 52.0622 13.0082C50.4445 12.9779 48.8696 13.528 47.6224 14.5587C46.3753 15.5893 45.5385 17.0325 45.2635 18.6269H58.8468ZM45.2214 22.3212C45.5784 23.9961 46.4948 25.4996 47.8197 26.5846C49.1446 27.6695 50.7993 28.2714 52.5117 28.2911C55.1529 28.2326 57.6694 27.1556 59.5351 25.2851L62.3445 28.6704C59.7716 31.2967 56.2717 32.8097 52.596 32.8845C50.9945 32.8827 49.4093 32.5636 47.9319 31.9457C46.4544 31.3278 45.1141 30.4232 43.9882 29.2843C42.8624 28.1454 41.9733 26.7947 41.3725 25.3102C40.7717 23.8258 40.4709 22.237 40.4876 20.6356C40.4724 19.1006 40.7649 17.578 41.3479 16.1579C41.9309 14.7378 42.7925 13.4489 43.8819 12.3673C44.9713 11.2858 46.2664 10.4335 47.6907 9.86078C49.115 9.28808 50.6397 9.00659 52.1746 9.03294C58.8749 9.03294 63.9177 14.3567 63.9177 22.4336L45.2214 22.3212Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M85.9994 20.8455C85.9994 19.4119 85.5743 18.0106 84.7778 16.8186C83.9814 15.6267 82.8494 14.6977 81.525 14.1491C80.2005 13.6005 78.7432 13.4569 77.3372 13.7366C75.9312 14.0163 74.6397 14.7066 73.626 15.7203C72.6123 16.7339 71.922 18.0254 71.6423 19.4314C71.3627 20.8374 71.5062 22.2948 72.0548 23.6192C72.6034 24.9437 73.5324 26.0757 74.7244 26.8721C75.9163 27.6685 77.3177 28.0936 78.7512 28.0936C80.6652 28.09 82.5003 27.331 83.8576 25.9816C85.2149 24.6322 85.9846 22.8015 85.9994 20.8876M85.9994 11.7431V0H90.5786V32.3077H86.6596V29.4983C84.5367 31.5847 81.6855 32.7635 78.7091 32.7853C75.5425 32.7853 72.5055 31.5273 70.2664 29.2882C68.0272 27.049 66.7693 24.0121 66.7693 20.8455C66.7693 17.6789 68.0272 14.6419 70.2664 12.4028C72.5055 10.1636 75.5425 8.90569 78.7091 8.90569C81.389 8.91557 83.9707 9.9162 85.9572 11.7151" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M112.632 20.8464C112.638 19.4049 112.215 17.9943 111.418 16.7934C110.62 15.5926 109.484 14.6556 108.153 14.1014C106.823 13.5472 105.357 13.4007 103.943 13.6806C102.529 13.9604 101.23 14.654 100.211 15.6733C99.1915 16.6926 98.4979 17.9917 98.2181 19.4057C97.9382 20.8198 98.0847 22.2851 98.6389 23.6158C99.1931 24.9465 100.13 26.0826 101.331 26.88C102.532 27.6774 103.942 28.1001 105.384 28.0946C107.302 28.0799 109.137 27.3115 110.493 25.9554C111.849 24.5993 112.617 22.7642 112.632 20.8464V20.8464ZM117.211 29.7662C117.211 38.5876 111.803 42.0009 106.578 42.0009C104.242 42.0171 101.955 41.3267 100.018 40.0203L101.83 36.1856C103.168 37.0441 104.721 37.5067 106.311 37.52C110.146 37.52 112.773 34.8933 112.773 29.8083C110.762 31.7036 108.105 32.7631 105.342 32.7722C102.175 32.7722 99.1382 31.5142 96.8991 29.2751C94.6599 27.036 93.402 23.999 93.402 20.8324C93.402 17.6658 94.6599 14.6288 96.8991 12.3897C99.1382 10.1505 102.175 8.89258 105.342 8.89258C106.802 8.89099 108.248 9.17948 109.596 9.74133C110.944 10.3032 112.167 11.1272 113.194 12.1655V9.35613H117.211V29.7662Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M138.661 18.6269C138.363 17.0461 137.521 15.6193 136.282 14.5933C135.044 13.5672 133.485 13.0065 131.876 13.0082C130.259 12.9779 128.684 13.528 127.437 14.5587C126.189 15.5893 125.353 17.0325 125.078 18.6269H138.661ZM125.035 22.3212C125.395 24.0054 126.319 25.516 127.656 26.6021C128.992 27.6881 130.66 28.2841 132.382 28.2911C134.989 28.2077 137.466 27.1325 139.307 25.2851L142.116 28.6704C139.544 31.2967 136.044 32.8097 132.368 32.8845C130.766 32.8827 129.181 32.5636 127.704 31.9457C126.226 31.3278 124.886 30.4232 123.76 29.2843C122.634 28.1454 121.745 26.7947 121.144 25.3102C120.544 23.8258 120.243 22.237 120.26 20.6356C120.244 19.1006 120.537 17.578 121.12 16.1579C121.703 14.7378 122.564 13.4489 123.654 12.3673C124.743 11.2858 126.038 10.4335 127.463 9.86078C128.887 9.28808 130.412 9.00659 131.947 9.03294C138.647 9.03294 143.69 14.3567 143.69 22.4336L125.035 22.3212Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1358 4.73438L24.8816 32.9264H1.14246L6.4662 18.2615H15.8214L20.7378 4.73438H35.1358Z" fill="#66EDA8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6027 0L13.2508 14.693H0.538452L5.90434 0H18.6027Z" fill="#66EDA8"/>
    </g>
    <defs>
      <clipPath id="clip0_2470_348">
        <rect width="144.2" height="42" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "edgeImg"
}
</script>

<style scoped>

</style>
