<template>
  <div class="api-block">
    <div class="api-block-title">
      Exchange
    </div>
    <div class="title">
      Get Coin
    </div>
    <div class="desc">
      This request is using API Key from collection partner
    </div>
    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript " id="getCoin">curl --location --request GET 'https://api.swapuz.com/api/home/v1/coins'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>

    </div>

    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs ><code class="json">{
  "result": [
    {
      "id": 1,
      "name": "Bitcoin",
      "shortName": "BTC",
      "image": "https://swapuz.com/img/coin/user/22fe07ba-1188-44d1-92c6-00994cfc92e2.png",
      "isMemo": false,
      "isDeposit": true,
      "isWithdraw": true,
      "network": [
        {
          "id": 506,
          "name": "Bitcoin",
          "shortName": "BTC",
          "fullName": "Bitcoin",
          "regexAddress": "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": true,
          "withdrawFee": 0.0002,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 1
        },
        {
          "id": 564,
          "name": "Ethereum (ERC20)",
          "shortName": "ETH",
          "fullName": "Ethereum (ERC20)",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.00018,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 12
        },
        {
          "id": 1162,
          "name": "BNB Smart Chain (BEP20)",
          "shortName": "BSC",
          "fullName": "BNB Smart Chain (BEP20)",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.0000042,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 15
        },
        {
          "id": 1163,
          "name": "BNB Beacon Chain (BEP2)",
          "shortName": "BNB",
          "fullName": "BNB Beacon Chain (BEP2)",
          "regexAddress": "^(bnb1)[0-9a-z]{38}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.0000071,
          "memoRegex": "^[0-9A-Za-z-_]{1,120}$",
          "isMemo": true,
          "isActive": true,
          "minConfirmations": 1
        }
      ],
      "coinAmount": 0
    },
    {
      "id": 3,
      "name": "Ethereum",
      "shortName": "ETH",
      "image": "https://swapuz.com/img/coin/user/1d5d6d6f-15d2-43f7-b78f-e22afe390b1e.png",
      "isMemo": false,
      "isDeposit": true,
      "isWithdraw": true,
      "network": [
        {
          "id": 385,
          "name": "Ethereum (ERC20)",
          "shortName": "ETH",
          "fullName": "Ethereum (ERC20)",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": true,
          "withdrawFee": 0.000768,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 12
        },
        {
          "id": 967,
          "name": "Optimism",
          "shortName": "OPTIMISM",
          "fullName": "Optimism",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 50
        },
        {
          "id": 1106,
          "name": "BNB Smart Chain (BEP20)",
          "shortName": "BSC",
          "fullName": "BNB Smart Chain (BEP20)",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.000058,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 15
        },
        {
          "id": 1107,
          "name": "Arbitrum One",
          "shortName": "ARBITRUM",
          "fullName": "Arbitrum One",
          "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0,
          "memoRegex": "",
          "isMemo": false,
          "isActive": true,
          "minConfirmations": 200
        },
        {
          "id": 1108,
          "name": "BNB Beacon Chain (BEP2)",
          "shortName": "BNB",
          "fullName": "BNB Beacon Chain (BEP2)",
          "regexAddress": "^(bnb1)[0-9a-z]{38}$",
          "isDeposit": true,
          "isWithdraw": true,
          "isDefault": false,
          "withdrawFee": 0.000097,
          "memoRegex": "^[0-9A-Za-z-_]{1,120}$",
          "isMemo": true,
          "isActive": true,
          "minConfirmations": 1
        }
      ],
      "coinAmount": 5.3675
    }
  ],
  "status": 200
}</code></pre>
    </div>

  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "GetCoin",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("getCoin").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style scoped>

</style>
