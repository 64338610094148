<template>
  <div class="container-l">
    <div id="exchangeWidget" class="feature-widget">
      <div class="feature-widget_block">
        <div class="feature-widget_block-text">
          <div class="info">
            <div class="info-title">
              Exchange widget
            </div>
            <img class="info-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-crypto-services/>
                <div class="info-tags__item-text">
                  Crypto services
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-payment-systems/>
                <div class="info-tags__item-text">
                  Payment systems
                </div>
              </div>
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
            </div>
            <div class="info-text">
              Integrate a multi-customizable Swapuz widget that doesn't require much programming knowledge.
              <br>
              <br>
              Match the appropriate design and dimensions to your site.
            </div>
            <div class="info-list">
              <div v-for="(item, index) in infoList" :key="index" class="info-list--item">
                <list-icon/>
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <img class="feature-widget_block-img" src="../../../../assets/img/svg/featuresImg.svg" alt="">
      </div>
      <div class="feature-widget_block_bottom">
        <div class="feature-widget_block feature-widget_block-large">
          <div class="feature-widget_block--item">
            <add-widget-icon-sm/>
            <div class="text">
              <div class="title">
                Add widget
              </div>
              <div class="desc">
                Integrate widget Swapuz into your site
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob1"/>
          <div class="feature-widget_block--item">
            <share-it-icon-sm/>
            <div class="text">
              <div class="title">
                Share it
              </div>
              <div class="desc">
                Convert your active clients to successful exchanges
              </div>
            </div>
          </div>
          <arrow-right class="feature-widget_block--item_arrow"/>
          <arrow-right-mob class="feature-widget_block--item_arrowMob2"/>
          <div class="feature-widget_block--item">
            <payouts-icon-sm/>
            <div class="text">
              <div class="title">
                Get payouts
              </div>
              <div class="desc">
                Receive from 0.5% to 2% of each transaction
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TagCryptoServices from "@/assets/img/svg/tags/tagCryptoServices.vue";
import TagPaymentSystems from "@/assets/img/svg/tags/tagPaymentSystems.vue";
import TagCoinsWebsites from "@/assets/img/svg/tags/tagCoinsWebsites.vue";
import ListIcon from "@/assets/img/svg/listIcon.vue";
import ArrowRight from "@/assets/img/svg/arrowRight.vue";
import ShareItIconSm from "@/assets/img/svg/shareItIconSm.vue";
import PayoutsIconSm from "@/assets/img/svg/payoutsIconSm.vue";
import TagNewsWebsites from "@/assets/img/svg/tags/tagNewsWebsites.vue";
import AddWidgetIconSm from "@/assets/img/svg/addWidgetIconSm.vue";
import ArrowRightMob from "@/assets/img/svg/arrowRightMob.vue";

export default {
  name: "ExchangeWidget",
  components: {
    ArrowRightMob,
    AddWidgetIconSm,
    TagNewsWebsites,
    PayoutsIconSm,
    ShareItIconSm,
    ArrowRight, ListIcon, TagCoinsWebsites, TagPaymentSystems, TagCryptoServices},
  data() {
    return {
      infoList: [
        'The user stays on your site.',
        'No knowledge required to swap cryptocurrencies.',
      ]
    }
  }
}
</script>
