<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#29282A"/>
    <path d="M12.8857 11L11.0001 12.8856L27.9707 29.8562L29.8563 27.9706L12.8857 11Z" fill="#02C076"/>
    <path d="M29.8564 12.8857L27.9708 11.0001L11.0003 27.9707L12.8859 29.8563L29.8564 12.8857Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "closeIcon"
}
</script>

<style scoped>

</style>
