<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9987 2.33301C7.5587 2.33301 2.33203 7.55967 2.33203 13.9997C2.33203 20.4397 7.5587 25.6663 13.9987 25.6663C20.4387 25.6663 25.6654 20.4397 25.6654 13.9997C25.6654 7.55967 20.4387 2.33301 13.9987 2.33301ZM8.5737 21.583C10.102 20.4863 11.9687 19.833 13.9987 19.833C16.0287 19.833 17.8954 20.4863 19.4237 21.583C17.8954 22.6797 16.0287 23.333 13.9987 23.333C11.9687 23.333 10.102 22.6797 8.5737 21.583ZM21.162 19.973C19.1904 18.433 16.7054 17.4997 13.9987 17.4997C11.292 17.4997 8.80703 18.433 6.83536 19.973C5.48203 18.3513 4.66536 16.2747 4.66536 13.9997C4.66536 8.84301 8.84203 4.66634 13.9987 4.66634C19.1554 4.66634 23.332 8.84301 23.332 13.9997C23.332 16.2747 22.5154 18.3513 21.162 19.973Z" fill="#1B1A1C"/>
    <path d="M13.9987 6.99967C11.747 6.99967 9.91537 8.83134 9.91537 11.083C9.91537 13.3347 11.747 15.1663 13.9987 15.1663C16.2504 15.1663 18.082 13.3347 18.082 11.083C18.082 8.83134 16.2504 6.99967 13.9987 6.99967ZM13.9987 12.833C13.0304 12.833 12.2487 12.0513 12.2487 11.083C12.2487 10.1147 13.0304 9.33301 13.9987 9.33301C14.967 9.33301 15.7487 10.1147 15.7487 11.083C15.7487 12.0513 14.967 12.833 13.9987 12.833Z" fill="#1B1A1C"/>
  </svg>
</template>

<script>
export default {
  name: "profileBtnMob"
}
</script>

<style scoped>

</style>
