<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.1215 30.912C43.9268 30.912 44.6018 30.6396 45.1465 30.0948C45.6912 29.5501 45.9636 28.8751 45.9636 28.0698C45.9636 27.2646 45.6912 26.5896 45.1465 26.0448C44.6018 25.5001 43.9268 25.2277 43.1215 25.2277C42.3162 25.2277 41.6412 25.5001 41.0965 26.0448C40.5518 26.5896 40.2794 27.2646 40.2794 28.0698C40.2794 28.8751 40.5518 29.5501 41.0965 30.0948C41.6412 30.6396 42.3162 30.912 43.1215 30.912ZM20.3847 23.8067H34.5952V19.5435H20.3847V23.8067ZM10.4373 59.333C8.82677 53.933 7.23993 48.5448 5.67677 43.1685C4.11361 37.7922 3.33203 32.2856 3.33203 26.6488C3.33203 22.2909 4.84782 18.5962 7.8794 15.5646C10.911 12.533 14.6057 11.0172 18.9636 11.0172H33.1741C34.5478 9.21722 36.2176 7.81985 38.1833 6.82511C40.1491 5.83038 42.2689 5.33301 44.5426 5.33301C45.7268 5.33301 46.7334 5.74748 47.5623 6.57643C48.3912 7.40538 48.8057 8.41196 48.8057 9.59617C48.8057 9.88038 48.7702 10.1646 48.6991 10.4488C48.6281 10.733 48.5452 10.9935 48.4505 11.2304C48.261 11.7514 48.0834 12.2843 47.9176 12.8291C47.7518 13.3738 47.6215 13.9304 47.5268 14.4988L53.9926 20.9646H60.1741V40.7883L52.1452 43.4172L47.3847 59.333H31.7531V53.6488H26.0689V59.333H10.4373ZM13.6347 55.0698H21.8057V49.3856H36.0162V55.0698H44.1873L48.6636 40.1488L55.911 37.662V25.2277H52.2163L43.1215 16.133C43.1689 14.9488 43.3228 13.8001 43.5834 12.687C43.8439 11.5738 44.1162 10.4488 44.4005 9.31196C42.6005 9.78564 40.8952 10.4843 39.2847 11.408C37.6741 12.3317 36.4426 13.6225 35.5899 15.2804H18.9636C15.8373 15.2804 13.161 16.3935 10.9347 18.6199C8.70835 20.8462 7.59519 23.5225 7.59519 26.6488C7.59519 31.5277 8.28203 36.3001 9.65572 40.9659C11.0294 45.6317 12.3557 50.333 13.6347 55.0698Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "incomesIcon"
}
</script>

<style scoped>

</style>
