<template>
    <div class="order-create">
        <div class="order-create-complete">
            <div class="title">
                <div class="title-text">Done</div>
                <div @click="closeCompletePayment" class="close">
                    <img src="../../../assets/img/close.svg" alt="">
                </div>
            </div>
            <div class="desc">
                Wait for payment from the administrator
            </div>
            <div class="button">
                <router-link :to="{name: 'Payments'}">
                    <div @click="closeCompletePayment" class="confirm">
                        Payments
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OderCompleteModal",
        methods: {
            closeCompletePayment() {
                this.$store.commit('setter', {isCompleteModal: false})
            },
        }
    }
</script>

<style lang="scss">
    .order-create {
        background: rgba(27, 26, 28, 0.12);
        position: fixed;
        width: 100%;
        min-height: 100vh;
        top: 0;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-complete {
            background: #FFFFFF;
            padding: 32px 40px;
            border-radius: 12px;
            width: 100%;
            max-width: 480px;
            margin-top: 64px;

            @media (max-width: 1079px) {
                padding: 32px 25px;
            }


            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                &-text {
                    font-size: 20px;
                    line-height: 24px;
                    color: #1B1A1C;
                    font-weight: 700;
                }

                .close {
                    position: relative;
                    bottom: 15px;
                    right: -15px;

                    img {
                        cursor: pointer;
                    }
                }
            }

            .desc {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #1B1A1C;
                margin-top: 32px;
            }

            .button {
                margin-top: 24px;

                .confirm {
                    background: #673DFD;
                    color: #FFFFFF;
                    border-radius: 8px;
                    padding: 14px;
                    max-width: 192px;
                    width: 100%;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
</style>