<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22" cy="22" r="20" fill="#242325" stroke="#1B1A1C" stroke-width="4"/>
    <path d="M28.475 27.2212L27.2208 25.9962L30.3125 22.9046L11.5 22.9046L11.5 21.1546L30.3417 21.1546L27.25 18.0629L28.475 16.8379L33.6667 22.0296L28.475 27.2212Z" fill="white" fill-opacity="0.12"/>
  </svg>
</template>

<script>
export default {
  name: "arrowRightMob"
}
</script>

