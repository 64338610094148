<template>
  <div class="api-block">
    <div class="title">
      Get Network
    </div>
    <div class="desc">
      This request is using API Key from collection partner
    </div>
    <div class="table-title">
      Parameters:
    </div>
    <div class="table_block">
      <table class="table table_parameters">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required or optional</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Coin</td>
          <td>string (query)</td>
          <td>required</td>
          <td>*</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="code">curl --location --request GET 'https://api.swapuz.com/api/Home/network?coin=BTC'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>

    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json">{
  "result": [
    {
      "id": 506,
      "name": "Bitcoin",
      "shortName": "BTC",
      "fullName": "Bitcoin",
      "regexAddress": "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
      "isDeposit": true,
      "isWithdraw": true,
      "isDefault": true,
      "withdrawFee": 0.0002,
      "memoRegex": "",
      "isMemo": false,
      "isActive": true,
      "minConfirmations": 1
    },
    {
      "id": 564,
      "name": "Ethereum (ERC20)",
      "shortName": "ETH",
      "fullName": "Ethereum (ERC20)",
      "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
      "isDeposit": true,
      "isWithdraw": true,
      "isDefault": false,
      "withdrawFee": 0.00018,
      "memoRegex": "",
      "isMemo": false,
      "isActive": true,
      "minConfirmations": 12
    },
    {
      "id": 1162,
      "name": "BNB Smart Chain (BEP20)",
      "shortName": "BSC",
      "fullName": "BNB Smart Chain (BEP20)",
      "regexAddress": "^(0x)[0-9A-Fa-f]{40}$",
      "isDeposit": true,
      "isWithdraw": true,
      "isDefault": false,
      "withdrawFee": 0.0000042,
      "memoRegex": "",
      "isMemo": false,
      "isActive": true,
      "minConfirmations": 15
    },
    {
      "id": 1163,
      "name": "BNB Beacon Chain (BEP2)",
      "shortName": "BNB",
      "fullName": "BNB Beacon Chain (BEP2)",
      "regexAddress": "^(bnb1)[0-9a-z]{38}$",
      "isDeposit": true,
      "isWithdraw": true,
      "isDefault": false,
      "withdrawFee": 0.0000071,
      "memoRegex": "^[0-9A-Za-z-_]{1,120}$",
      "isMemo": true,
      "isActive": true,
      "minConfirmations": 1
    }
  ],
  "status": 200
}</code></pre>
    </div>
  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "GetNetwork",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("code").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style scoped>

</style>
