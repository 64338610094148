<template>
    <AccountLayout>
        <template v-slot:title>
            <h2>Widget</h2>
        </template>
        <template v-slot:content>
            <div class="widget">
                <div class="title">
                    Set up your widget
                </div>
                <div class="widget-controls">
                    <div class="widget-controls_item">
                        <div class="desc">
                            Language
                        </div>
                        <language-select @update:lang="changeLang($event)" :selected-lang="selectedLang"/>
                    </div>
                    <div v-if="coinsList" class="widget-controls_item">
                        <div class="desc">
                            Default coin
                        </div>
                        <coin-select @update:select="changeDefaultCoin($event)" :selected-coin="selectedCoin"
                                     :data="coinsList"/>
                    </div>
                    <div class="widget-controls_item">
                        <div class="desc">
                            Default amount
                        </div>
                        <div class="input">
                            <input type="text" v-model="defaultAmount">
                            <span class="value">{{ selectedCoin.shortName }}</span>
                        </div>


                    </div>
                    <div class="widget-controls_item widget-controls_item--width"
                         :class="{'widget-controls_item--width_error': widgetWidthError }">
                        <div class="desc">
                            Width
                        </div>
                        <div class="input">
                            <input type="text" v-model="widgetWidth" @input="checkWidgetWidth"
                                   @blur="validateWidgetWidth">
                            <div class="value">
                                px
                            </div>
                            <div class="input-slider">
                                <vue-slider v-model="widgetWidth" @input="checkWidgetWidth" :tooltip="'none'" :min="318"
                                            :max="1600"></vue-slider>
                            </div>
                        </div>
                        <div class="minMax">
                            <span>
                                318
                            </span>
                            <span>
                                1600
                            </span>
                        </div>
                    </div>
                </div>
                <div class="widget_preview">
                    <iframe id="frame"
                            :src="'https://widget.swapuz.com/' + '?amount=' + defaultAmount + '&coin=' + defaultCoin + '&ref=' + User.partnerId + '&lang=' + selectedLang"
                            :width="widgetWidth" frameborder="0" scrolling="no" :height="widgetHeight + 'px'"></iframe>
                </div>
                <div class="widget-buttons">
                    <div @click="resetToDefault" class="widget-buttons--item reset">
                        Reset to default
                    </div>
                    <div @click="loadFrame" class="widget-buttons--item create">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 11.8083L7.35833 9.99997L9.16667 8.19163L7.99167 7.0083L5 9.99997L7.99167 12.9916L9.16667 11.8083Z"
                                  fill="white"/>
                            <path d="M12.009 12.9916L15.0007 9.99997L12.009 7.0083L10.834 8.19163L12.6423 9.99997L10.834 11.8083L12.009 12.9916Z"
                                  fill="white"/>
                            <path d="M15.8333 2.5H12.35L7.65 2.50006L4.16667 2.5C4.05 2.5 3.94167 2.50833 3.83333 2.53333C3.50833 2.6 3.21667 2.76667 2.99167 2.99167C2.84167 3.14167 2.71667 3.325 2.63333 3.525C2.55 3.71667 2.5 3.93333 2.5 4.16667V12.5V13.3333V15.8333C2.5 16.0583 2.55 16.2833 2.63333 16.4833C2.71667 16.6833 2.84167 16.8583 2.99167 17.0167C3.21667 17.2417 3.50833 17.4083 3.83333 17.475C3.94167 17.4917 4.05 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V13.3333V12.5V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 12.5V13.3333V15.8333H4.16667V13.3333V12.5V4.16667H15.8333V12.5Z"
                                  fill="white"/>
                        </svg>
                        Get code
                    </div>
                </div>
            </div>
            <div v-if="generatedCode" class="popup-code">
                <div class="frame">
                    <div @click="generatedCode = ''" class="close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_746_187)">
                                <path d="M18.3007 5.70997C17.9107 5.31997 17.2807 5.31997 16.8907 5.70997L12.0007 10.59L7.1107 5.69997C6.7207 5.30997 6.0907 5.30997 5.7007 5.69997C5.3107 6.08997 5.3107 6.71997 5.7007 7.10997L10.5907 12L5.7007 16.89C5.3107 17.28 5.3107 17.91 5.7007 18.3C6.0907 18.69 6.7207 18.69 7.1107 18.3L12.0007 13.41L16.8907 18.3C17.2807 18.69 17.9107 18.69 18.3007 18.3C18.6907 17.91 18.6907 17.28 18.3007 16.89L13.4107 12L18.3007 7.10997C18.6807 6.72997 18.6807 6.08997 18.3007 5.70997Z"
                                      fill="#9FA2B4"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_746_187">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="title">
                        Informer source code
                    </div>
                    <div class="desc">
                        Сopy the code below and just paste it in the right place on your website
                    </div>
                    <div class="code">
                        <div class="code-title">
                            Code
                        </div>
                        <div class="code-iframe">
                            {{ generatedCode }}
                        </div>
                    </div>
                    <div class="buttons">
                        <div @click="generatedCode = ''" class="buttons--item cancel">
                            Cancel
                        </div>
                        <div @click="copyIframe" class="buttons--item copy">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_746_269)">
                                    <path d="M15 1.66675H7.5C6.58333 1.66675 5.83333 2.41675 5.83333 3.33341V13.3334C5.83333 14.2501 6.58333 15.0001 7.5 15.0001H15C15.9167 15.0001 16.6667 14.2501 16.6667 13.3334V3.33341C16.6667 2.41675 15.9167 1.66675 15 1.66675ZM15 13.3334H7.5V3.33341H15V13.3334ZM2.5 12.5001V10.8334H4.16667V12.5001H2.5ZM2.5 7.91675H4.16667V9.58341H2.5V7.91675ZM8.33333 16.6667H10V18.3334H8.33333V16.6667ZM2.5 15.4167V13.7501H4.16667V15.4167H2.5ZM4.16667 18.3334C3.25 18.3334 2.5 17.5834 2.5 16.6667H4.16667V18.3334ZM7.08333 18.3334H5.41667V16.6667H7.08333V18.3334ZM11.25 18.3334V16.6667H12.9167C12.9167 17.5834 12.1667 18.3334 11.25 18.3334ZM4.16667 5.00008V6.66675H2.5C2.5 5.75008 3.25 5.00008 4.16667 5.00008Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_746_269">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            Copy
                        </div>
                    </div>
                </div>
            </div>
        </template>

    </AccountLayout>

</template>

<script>
import AccountLayout from "@/components/AccountLayout.vue";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import CoinsService from "@/services/coins.service";
import CoinSelect from "@/components/CoinSelect.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";

export default {
    name: 'WidgetPage',
    components: {LanguageSelect, CoinSelect, AccountLayout, VueSlider},
    data() {
        return {
            widgetWidth: 318,
            defaultAmount: 0.1,
            generatedCode: '',
            defaultCoin: 'ETH',
            selectedCoin: '',
            widgetHeight: 420,
            coinsList: null,
            selectedLang: 'en',
            widgetWidthError: false
        }
    },
    watch: {
        widgetWidth(val) {
            if (val <= 517) {
                this.widgetHeight = 420;
            } else if (val >= 518 && val <= 815) {
                this.widgetHeight = 292;
            } else if (val >= 816) {
                this.widgetHeight = 128;
            } else {
                this.widgetHeight = 420;
            }
        }
    },
    computed: {
        User() {
            return this.$store.state.user;
        },
    },
    mounted() {
        CoinsService.getCoinsList().then((res) => {
            this.coinsList = res.data.result
        })
    },
    methods: {
        checkWidgetWidth() {
            if (this.widgetWidth === '') {
                this.widgetWidthError = false;
                return;
            }

            this.widgetWidthError = isNaN(this.widgetWidth) || this.widgetWidth < 318 || this.widgetWidth > 1600;
        },
        validateWidgetWidth() {
            if (this.widgetWidth === '' || this.widgetWidthError) {
                this.widgetWidth = '';
            }

            if (this.widgetWidth < 318) {
                this.widgetWidth = 318;
            }
            if (this.widgetWidth > 1600) {
                this.widgetWidth = 1600
            }
        },
        copyIframe() {
            const iframe = this.generatedCode;
            this.$clipboard(iframe);
            this.$notify.info({
                position: 'top right',
                msg: 'Сopied!',
                timeout: 1000
            })
        },
        resetToDefault() {
            this.widgetWidth = 318;
            this.defaultAmount = 0.1;
            this.selectedCoin = {
                shortName: 'ETH',
                name: 'Ethereum',
                image: 'https://swapuz.com/img/coin/user/1d5d6d6f-15d2-43f7-b78f-e22afe390b1e.png'
            }
            this.defaultCoin = 'ETH';
            this.selectedLang = 'en';
        },
        changeDefaultCoin(el) {
            this.selectedCoin = el
            this.defaultCoin = el.shortName
        },
        changeLang(lang) {
            this.selectedLang = lang.code
        },
        loadFrame() {
            let baseUrl = 'https://widget.swapuz.com/'
            let src = `${baseUrl}?amount=${this.defaultAmount}&coin=${this.defaultCoin}&ref=${this.User.partnerId}&lang=${this.selectedLang}`

            this.generatedCode = `<iframe id="frame" src="${src}" width="${this.widgetWidth}" height="${this.widgetHeight}" frameborder="0" scrolling="no"></iframe>`

        }
    }
}
</script>

<style lang="scss">
.widget {
  background: white;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 32px;
  position: relative;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #1B1A1C;
    padding-bottom: 32px;
    padding-left: 32px;
  }

  &_preview {
    border: 1px solid #DFE0EB;
    height: 480px;
    display: grid;
    place-content: center;
    position: relative;
    overflow: auto;
    z-index: 0;
    background: url("../../assets/img/png/transparentBg.png") no-repeat 100%;
    scrollbar-color: RGB(2, 192, 118) transparent;

    iframe {
      z-index: 9;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: content-box;
    }

    img {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  &-controls {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 0 32px 38px;

    &_item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 200px;
      position: relative;

      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #673DFD;
      }

      .input {
        position: relative;
        background: rgba(103, 61, 253, 0.03);
        border: 1px solid #DFE0EB;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #1B1A1C;
        padding: 11px 16px;
        min-height: 40px;

        &:focus-within {
          border: 1px solid #673DFD;
        }

        input {
          background: none;
          border: none;
          outline: none;
        }

        .vue-slider-process {
          background: #673DFD;
          border-radius: 2px 0 0 0;
        }

        .vue-slider {
          height: 3px !important;
        }

        .arrow {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .value {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto 0;
          right: 11px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #9FA2B4;
          height: 18px;
        }
      }

      .value {
        position: absolute;
        top: 11px;
        right: 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #9FA2B4;
      }

      &--width {
        max-width: 280px;
        width: 100%;

        .input {
          &-slider {
            width: 80%;
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }

        .vue-slider-dot {
          width: 10px !important;
          height: 10px !important;
        }

        .vue-slider-dot-handle {
          background: #FFFFFF;
          border: 2px solid #673DFD;
          box-shadow: none;
        }

        .minMax {
          position: absolute;
          top: calc(100% + 6px);
          width: 100%;
          padding: 0 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #9FA2B4;
          }
        }

        &_error {
          .input {
            border: 1px solid #FF783E;

            &:focus-within {
              border: 1px solid #FF783E;
            }
          }

          .minMax {
            span {
              color: #FF783E;
            }
          }

          .vue-slider-dot-handle {
            border: 2px solid #FF783E;
          }

          .vue-slider-process {
            background: #FF783E !important;
          }
        }
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 20px 24px 24px;

    &--item {
      width: 100%;
      max-width: 160px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 48px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      transition: all .3s ease-in-out;
      cursor: pointer;
    }

    .reset {
      color: #673DFD;

      &:hover {
        background: rgba(103, 61, 253, 0.06);
      }
    }

    .create {
      color: white;
      background: #673DFD;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #5b35de;
      }
    }
  }
}

.popup-code {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background: rgba(27, 26, 28, 0.12);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;

  .frame {
    background: white;
    width: 100%;
    max-width: 480px;
    box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
    border-radius: 12px;
    padding: 32px 40px 40px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: #673DFD;
          }
        }
      }
    }


    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #1B1A1C;
      margin-bottom: 24px;
    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #1B1A1C;
      margin-bottom: 32px;
    }

    .code {
      margin-bottom: 32px;

      &-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #673DFD;
        margin-bottom: 8px;
      }

      &-iframe {
        font-family: 'Roboto Mono', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #DFE0EB;
        border-radius: 4px;
        padding: 16px;
        box-sizing: border-box;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &--item {
        width: 100%;
        max-width: 192px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
      }

      .cancel {
        color: #673DFD;

        &:hover {
          background: rgba(103, 61, 253, 0.06);
        }
      }

      .copy {
        color: white;
        background: #673DFD;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #5b35de;
        }
      }
    }

  }
}
</style>
