import { gsap } from "gsap";

export default {
    beforeRouteEnter (to, from, next) {
        gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
        next();
    },
    beforeRouteUpdate (to, from, next) {
        gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
        next();
    }
};
