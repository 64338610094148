<template>
  <landing-layout>
    <template #landing>
      <div class="api-docs">
        <div class="container-l">
          <div class="api-docs_block">
            <div class="menu menu-desktop">
              <div class="menu_block">
                <div
                    class="menu_item"
                    @click="scrollTrigger('introduction')"
                    :class="{'menu_item--active': activeMenu === 'introduction'}"
                >
                  Introduction
                </div>
                <div
                    class="menu_item"
                    @click="scrollTrigger('basicInfo')"
                    :class="{'menu_item--active': activeMenu === 'basicInfo'}"
                >
                  Basic info
                </div>
                <div
                    class="menu_item"
                    @click="scrollTrigger('authMain')"
                    :class="{'menu_item--active': activeMenu === 'authMain'}"
                >
                  Authorization process
                </div>
                <div
                    class="menu_item menu_item_admin"
                    @click="isShowTabsExchange = !isShowTabsExchange"
                    :class="{'menu_item--active_admin': activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate' || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'}"
                >
                  Exchange
                  <drop-down-arrow/>
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate' || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('getCoin')"
                    :class="{'menu_item--active': activeMenu === 'getCoin'}"
                >
                  Get coin
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate' || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('getLimits')"
                    :class="{'menu_item--active': activeMenu === 'getLimits'}"
                >
                  Get limits
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate' || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('getRate')"
                    :class="{'menu_item--active': activeMenu === 'getRate'}"
                >
                  Get rate
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate'  || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('createOrder')"
                    :class="{'menu_item--active': activeMenu === 'createOrder'}"
                >
                  Create Order
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate'  || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('getOrder')"
                    :class="{'menu_item--active': activeMenu === 'getOrder'}"
                >
                  Get Order
                </div>
                <div
                    v-if="isShowTabsExchange || activeMenu === 'getLimits' || activeMenu === 'getCoin' || activeMenu === 'getRate'  || activeMenu === 'createOrder' || activeMenu === 'getOrder' || activeMenu === 'getNetwork'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('getNetwork')"
                    :class="{'menu_item--active': activeMenu === 'getNetwork'}"
                >
                  Get Network
                </div>

                <div
                    class="menu_item menu_item_admin"
                    @click="isShowTabsAdmin = !isShowTabsAdmin"
                    :class="{'menu_item--active_admin': activeMenu === 'authorization' || activeMenu === 'transactions', 'menu_item--active_admin-arrow': isShowTabsAdmin && activeMenu === 'authorization' && activeMenu === 'transactions' }"
                >
                  Admin
                  <drop-down-arrow/>
                </div>
                <div
                    v-if="isShowTabsAdmin || activeMenu === 'authorization' || activeMenu === 'transactions'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('authorization')"
                    :class="{'menu_item--active': activeMenu === 'authorization'}"
                >
                  Authorization process
                </div>
                <div
                    v-if="isShowTabsAdmin || activeMenu === 'authorization' || activeMenu === 'transactions'"
                    class="menu_item menu_item-lvl"
                    @click="scrollTrigger('transactions')"
                    :class="{'menu_item--active': activeMenu === 'transactions'}"
                >
                  List transactions
                </div>
              </div>
            </div>
            <div class="content">
              <title-block v-view="viewHandler" id="introduction"/>
              <div ref="menuBlockMob" class="menu menu-mobile">
                <div class="menu_block-mobile">
                  <div
                      class="menu_item"
                      id="introductionLink"
                      @click="scrollTrigger('introduction')"
                      :class="{'menu_item--active': activeMenu === 'introduction'}"
                  >
                    Introduction
                  </div>
                  <div
                      class="menu_item"
                      id="basicInfoLink"
                      @click="scrollTrigger('basicInfo')"
                      :class="{'menu_item--active': activeMenu === 'basicInfo'}"
                  >
                    Basic info
                  </div>
                  <div
                      class="menu_item"
                      id="authMainLink"
                      @click="scrollTrigger('authMain')"
                      :class="{'menu_item--active': activeMenu === 'authMain'}"
                  >
                    Authorization process
                  </div>
                  <div
                      class="menu_item"
                      id="getCoinLink"
                      @click="scrollTrigger('getCoin')"
                      :class="{'menu_item--active': activeMenu === 'getCoin'}"
                  >
                    Get coin
                  </div>
                  <div
                      class="menu_item"
                      id="getLimitsLink"
                      @click="scrollTrigger('getLimits')"
                      :class="{'menu_item--active': activeMenu === 'getLimits'}"
                  >
                    Get limits
                  </div>
                  <div
                      class="menu_item"
                      id="getRateLink"
                      @click="scrollTrigger('getRate')"
                      :class="{'menu_item--active': activeMenu === 'getRate'}"
                  >
                    Get rate
                  </div>
                  <div
                      class="menu_item"
                      id="createOrderLink"
                      @click="scrollTrigger('createOrder')"
                      :class="{'menu_item--active': activeMenu === 'createOrder'}"
                  >
                    Create Order
                  </div>
                  <div
                      class="menu_item"
                      id="getOrderLink"
                      @click="scrollTrigger('getOrder')"
                      :class="{'menu_item--active': activeMenu === 'getOrder'}"
                  >
                    Get Order
                  </div>
                  <div
                      class="menu_item"
                      id="getNetworkLink"
                      @click="scrollTrigger('getNetwork')"
                      :class="{'menu_item--active': activeMenu === 'getNetwork'}"
                  >
                    Get Network
                  </div>
                  <div
                      class="menu_item"
                      id="authorizationLink"
                      @click="scrollTrigger('authorization')"
                      :class="{'menu_item--active': activeMenu === 'authorization'}"
                  >
                    Authorization process
                  </div>
                  <div
                      class="menu_item"
                      id="transactionsLink"
                      @click="scrollTrigger('transactions')"
                      :class="{'menu_item--active': activeMenu === 'transactions'}"
                  >
                    List transactions
                  </div>
                </div>
              </div>
              <basic-info v-view="viewHandler" id="basicInfo"/>
              <authorization-main v-view="viewHandler" id="authMain"/>
              <div class="content_block">
                <get-coin v-view="viewHandler" id="getCoin"/>
                <get-limits v-view="viewHandler" id="getLimits"/>
                <get-rate v-view="viewHandler" id="getRate"/>
                <create-order v-view="viewHandler"  id="createOrder"/>
                <get-order v-view="viewHandler" id="getOrder"/>
                <get-network v-view="viewHandler"  id="getNetwork"/>
                <authorization-block v-view="viewHandler" id="authorization"/>
                <list-transactions v-view="viewHandler" id="transactions"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </landing-layout>
</template>

<script>

import TitleBlock from "@/pages/apiDocumentation/partials/TitleBlock.vue";
import LandingLayout from "@/components/LandingLayout.vue";
import AuthorizationBlock from "@/pages/apiDocumentation/partials/AuthorizationBlock.vue";
import ListTransactions from "@/pages/apiDocumentation/partials/ListTransactions.vue";
import 'highlight.js/styles/monokai-sublime.css'
import DropDownArrow from "@/assets/img/svg/dropDownArrow.vue";
import GetCoin from "@/pages/apiDocumentation/partials/GetCoin.vue";
import GetLimits from "@/pages/apiDocumentation/partials/GetLimits.vue";
import GetRate from "@/pages/apiDocumentation/partials/GetRate.vue";
import CreateOrder from "@/pages/apiDocumentation/partials/CreateOrder.vue";
import GetOrder from "@/pages/apiDocumentation/partials/GetOrder.vue";
import GetNetwork from "@/pages/apiDocumentation/partials/GetNetwork.vue";
import {gsap} from "gsap";
import BasicInfo from "@/pages/apiDocumentation/partials/BasicInfo.vue";
import AuthorizationMain from "@/pages/apiDocumentation/partials/AuthorizationMain.vue";

export default {
  name: "ApiDocs",
  components: {
    AuthorizationMain,
    BasicInfo,
    GetNetwork,
    GetOrder,
    CreateOrder,
    GetRate,
    GetLimits,
    GetCoin,
    DropDownArrow,
    ListTransactions,
    AuthorizationBlock,
    LandingLayout,
    TitleBlock
  },
  data() {
    return {
      activeMenu: 'introduction',
      isShowTabsAdmin: false,
      isShowTabsExchange: false,
    }
  },
  watch: {
    activeMenu(val) {
      console.log(val);
      let mobNav = document.querySelector('.menu-mobile')
      const target = document.getElementById(val + 'Link');
      const scrollTarget = (mobNav.offsetWidth - target.offsetWidth) / 2;

      gsap.to(mobNav, {duration: 1, scrollTo: {x: target, offsetX: scrollTarget}, ease: "power2.inOut"});
    }
  },
  methods: {
    viewHandler(e) {
      if (e.percentInView >= 0.9) {
        this.activeMenu = e.target.element.id
      }
    },
    scrollTrigger(id) {
      const target = document.getElementById(id);
      gsap.to(window, {duration: 1, scrollTo: {y: target, offsetY: 70}, ease: "power2.inOut"});
    }
  }

}

</script>

<style lang="scss">
@import "../../assets/styles/pages/api-docs";
</style>
