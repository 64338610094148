<template>
  <svg width="155" height="36" viewBox="0 0 155 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.26242 27.1922H0V0.165023H8.2145L13.1285 21.4164L18.0059 0.165023H26.1287V27.1922H20.8663V8.91126C20.8663 8.38563 20.8724 7.6522 20.8846 6.71095C20.8969 5.75748 20.903 5.02405 20.903 4.51064L15.7872 27.1922H10.3048L5.22574 4.51064C5.22574 5.02405 5.23186 5.75748 5.24408 6.71095C5.2563 7.6522 5.26242 8.38563 5.26242 8.91126V27.1922Z" fill="white"/>
    <path d="M49.8716 9.73638C51.5585 11.8511 52.4019 14.3509 52.4019 17.2358C52.4019 20.1695 51.5585 22.6815 49.8716 24.7718C48.1847 26.8499 45.6237 27.889 42.1888 27.889C38.7539 27.889 36.1929 26.8499 34.506 24.7718C32.8191 22.6815 31.9757 20.1695 31.9757 17.2358C31.9757 14.3509 32.8191 11.8511 34.506 9.73638C36.1929 7.62164 38.7539 6.56426 42.1888 6.56426C45.6237 6.56426 48.1847 7.62164 49.8716 9.73638ZM42.1705 10.9832C40.6425 10.9832 39.4629 11.5272 38.6316 12.6151C37.8126 13.6908 37.4031 15.231 37.4031 17.2358C37.4031 19.2405 37.8126 20.7868 38.6316 21.8748C39.4629 22.9627 40.6425 23.5067 42.1705 23.5067C43.6985 23.5067 44.872 22.9627 45.691 21.8748C46.51 20.7868 46.9195 19.2405 46.9195 17.2358C46.9195 15.231 46.51 13.6908 45.691 12.6151C44.872 11.5272 43.6985 10.9832 42.1705 10.9832Z" fill="white"/>
    <path d="M67.8203 11.0749C66.0478 11.0749 64.8315 11.8267 64.1714 13.3302C63.8291 14.1248 63.658 15.1394 63.658 16.374V27.1922H58.4506V7.24269H63.493V10.1581C64.1653 9.13129 64.8009 8.39174 65.3999 7.93946C66.4756 7.13268 67.8386 6.72929 69.4888 6.72929C71.5547 6.72929 73.2416 7.27325 74.5495 8.36119C75.8697 9.43689 76.5298 11.2277 76.5298 13.7336V27.1922H71.1757V15.0355C71.1757 13.9842 71.0351 13.1774 70.754 12.6151C70.2406 11.5883 69.2627 11.0749 67.8203 11.0749Z" fill="white"/>
    <path d="M100.731 21.3247C100.597 22.5104 99.9793 23.7145 98.8791 24.9369C97.1678 26.8805 94.7719 27.8523 91.6915 27.8523C89.1489 27.8523 86.9058 27.0333 84.9622 25.3953C83.0185 23.7573 82.0467 21.0924 82.0467 17.4008C82.0467 13.9414 82.9208 11.2888 84.6688 9.443C86.429 7.59719 88.7088 6.67428 91.5081 6.67428C93.1705 6.67428 94.668 6.98599 96.0004 7.60941C97.3328 8.23283 98.433 9.21686 99.3009 10.5615C100.083 11.7472 100.59 13.1224 100.823 14.6871C100.957 15.6039 101.012 16.9241 100.988 18.6476H87.3092C87.3825 20.6524 88.012 22.0581 89.1978 22.8649C89.919 23.3661 90.7869 23.6167 91.8015 23.6167C92.8772 23.6167 93.7512 23.3111 94.4235 22.6999C94.7902 22.3698 95.1142 21.9114 95.3953 21.3247H100.731ZM95.5603 15.2005C95.4748 13.8192 95.053 12.774 94.2952 12.065C93.5495 11.3438 92.6205 10.9832 91.5081 10.9832C90.2979 10.9832 89.3567 11.3622 88.6844 12.1201C88.0243 12.8779 87.6086 13.9047 87.4375 15.2005H95.5603Z" fill="white"/>
    <path d="M116.828 12.0284C114.725 12.0284 113.313 12.7129 112.592 14.082C112.189 14.8521 111.987 16.0378 111.987 17.6392V27.1922H106.725V7.20602H111.712V10.6898C112.519 9.35744 113.222 8.44675 113.821 7.95779C114.799 7.13879 116.07 6.72929 117.635 6.72929C117.732 6.72929 117.812 6.7354 117.873 6.74762C117.946 6.74762 118.099 6.75373 118.331 6.76596V12.1201C118.001 12.0834 117.708 12.0589 117.451 12.0467C117.195 12.0345 116.987 12.0284 116.828 12.0284Z" fill="white"/>
    <path d="M140.332 9.73638C142.019 11.8511 142.863 14.3509 142.863 17.2358C142.863 20.1695 142.019 22.6815 140.332 24.7718C138.645 26.8499 136.084 27.889 132.65 27.889C129.215 27.889 126.654 26.8499 124.967 24.7718C123.28 22.6815 122.436 20.1695 122.436 17.2358C122.436 14.3509 123.28 11.8511 124.967 9.73638C126.654 7.62164 129.215 6.56426 132.65 6.56426C136.084 6.56426 138.645 7.62164 140.332 9.73638ZM132.631 10.9832C131.103 10.9832 129.924 11.5272 129.092 12.6151C128.273 13.6908 127.864 15.231 127.864 17.2358C127.864 19.2405 128.273 20.7868 129.092 21.8748C129.924 22.9627 131.103 23.5067 132.631 23.5067C134.159 23.5067 135.333 22.9627 136.152 21.8748C136.971 20.7868 137.38 19.2405 137.38 17.2358C137.38 15.231 136.971 13.6908 136.152 12.6151C135.333 11.5272 134.159 10.9832 132.631 10.9832Z" fill="white"/>
    <path d="M149.113 7.2977H154.339V29.3375C154.339 31.3544 153.941 32.8458 153.147 33.8115C152.352 34.7772 150.794 35.26 148.471 35.26C148.312 35.26 148.074 35.2539 147.756 35.2417C147.451 35.2294 147.066 35.2172 146.601 35.205V31.0244C146.894 31.0488 147.102 31.0611 147.224 31.0611C147.347 31.0733 147.457 31.0794 147.554 31.0794C148.068 31.0794 148.453 30.9511 148.71 30.6943C148.979 30.4499 149.113 29.967 149.113 29.2458V7.2977ZM149.113 0H154.339V4.82235H149.113V0Z" fill="#EC5B37"/>
  </svg>
</template>

<script>
export default {
  name: "monerojImg"
}
</script>

<style scoped>

</style>
