<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.7987 16.3851L14.8945 12.3747L8.7987 8.34134V16.3851ZM3.20703 19.2497C2.84036 19.2497 2.51953 19.1122 2.24453 18.8372C1.96953 18.5622 1.83203 18.2413 1.83203 17.8747V5.49967H7.33203V3.20801C7.33203 2.84134 7.46953 2.52051 7.74453 2.24551C8.01953 1.97051 8.34036 1.83301 8.70703 1.83301H13.2904C13.657 1.83301 13.9779 1.97051 14.2529 2.24551C14.5279 2.52051 14.6654 2.84134 14.6654 3.20801V5.49967H20.1654V17.8747C20.1654 18.2413 20.0279 18.5622 19.7529 18.8372C19.4779 19.1122 19.157 19.2497 18.7904 19.2497H3.20703ZM3.20703 17.8747H18.7904V6.87467H3.20703V17.8747ZM8.70703 5.49967H13.2904V3.20801H8.70703V5.49967ZM3.20703 17.8747V6.87467V17.8747Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagMediaBuyers"
}
</script>

<style scoped>

</style>
