<template>
  <div class="AccountLayout">
    <div class="container-fluid">
      <div class="row">
        <OrderPaymentModal v-if="IsPaymentModal"/>
        <OrderCompleteModal v-if="ConfirmModal"/>
        <div class="mobNav d-xl-none">
          <div class="mobNav-block">
            <div @click="toggleNav" class="burger">
              <img src="~@/assets/img/icons/burger.svg">
            </div>
            <router-link :to="{ name: 'Dashboard'}">
              <img src="~@/assets/img/logoMob.svg">
            </router-link>

          </div>
          <div class="account-mob">
            <div class="account-mob-icon">
              <router-link :to="{ name: 'Profile'}" class="btn-link">
                <avatar :img="User.partnerProfile.avatar"/>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-10 no-padding" :class="{ 'blur': IsPaymentModal || ConfirmModal}">
          <div v-if="mobNav" class="dashboard-nav-mob d-block d-md-none" :class="{ 'mob': mobNav = true }">
            <div class="logo">
              <div class="close">
                <img @click="toggleNav" src="~@/assets/img/icons/close.svg">
              </div>
              <router-link :to="{ name: 'Dashboard'}">
                <img src="~@/assets/img/logoMob.svg">
              </router-link>
            </div>
            <div class="nav-menu">
              <router-link :to="{ name: 'Dashboard'}" class="btn-link">
                <img alt="Dashboard" src="~@/assets/img/icons/dashboard.svg">
                Dashboard
              </router-link>
              <router-link :to="{ name: 'Profile'}" class="btn-link">
                <img alt="Profile" src="~@/assets/img/icons/profile.svg">
                Profile
              </router-link>
              <router-link :to="{ name: 'Payments'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/payments.svg">
                Payments
              </router-link>
              <router-link :to="{ name: 'Last Transactions'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/lastTransactions.svg">
                All transactions
              </router-link>
              <router-link :to="{ name: 'Promo'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/promos.svg">
                Promo
              </router-link>
              <router-link :to="{ name: 'Widget'}" class="btn-link">
                  <img alt="Widget" src="~@/assets/img/icons/promos.svg">
                  Widget
              </router-link>
            </div>
            <div class="logout">
              <a href="#" class="btn-link" @click="logout">
                <img alt="Logout" src="~@/assets/img/icons/logout.svg">
                Logout
              </a>
            </div>
          </div>
          <div class="dashboard-nav d-none d-xl-block">
            <div class="logo">
              <router-link :to="{ name: 'Dashboard'}">
                <img alt="Swapuz logo" src="~@/assets/img/logo.svg">
              </router-link>
            </div>
            <div class="nav-menu">
              <router-link :to="{ name: 'Dashboard'}" class="btn-link">
                <img alt="Dashboard" src="~@/assets/img/icons/dashboard.svg">
                Dashboard
              </router-link>
              <router-link :to="{ name: 'Profile'}" class="btn-link">
                <img alt="Profile" src="~@/assets/img/icons/profile.svg">
                Profile
              </router-link>
              <router-link :to="{ name: 'Payments'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/payments.svg">
                Payments
              </router-link>
              <router-link :to="{ name: 'Last Transactions'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/lastTransactions.svg">
                All transactions
              </router-link>
              <router-link :to="{ name: 'Promo'}" class="btn-link">
                <img alt="Payments" src="~@/assets/img/icons/promos.svg">
                Promo
              </router-link>
                <router-link :to="{ name: 'Widget'}" class="btn-link">
                    <img alt="Widget" src="~@/assets/img/icons/widget.svg">
                    Widget
                </router-link>
            </div>
            <div class="logout">
              <a href="#" class="btn-link" @click="logout">
                <img alt="Logout" src="~@/assets/img/icons/logout.svg">
                Logout
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-10 content" :class="{ 'blur': mobNav || IsPaymentModal || ConfirmModal }">
          <div class="row justify-content-between block-margin">
            <div class="col-lg-3">
              <div class="title">
                <slot name="title"></slot>
                <div class="marker d-none d-md-block">
                  <img alt="" src="~@/assets/img/marker.svg">
                </div>
              </div>
            </div>
            <div class="col-3 d-none d-xl-block">
              <div class="account">
                <div class="account-title">
                  <div class="name">
                    {{ User.login }}
                  </div>
                  <div class="subscribe">
                    {{ User.partnerProgramName }}
                  </div>
                </div>
                <div class="account-icon">
                  <router-link :to="{ name: 'Profile'}" class="btn-link">
                    <avatar :img="User.partnerProfile.avatar"/>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar";
import OrderPaymentModal from "../pages/dashboard/partials/OrderPaymentModal";
import OrderCompleteModal from "../pages/dashboard/partials/OrderCompleteModal";

export default {
  name: "AccountLayout",
  components: {OrderPaymentModal, OrderCompleteModal, Avatar},
  data() {
    return {
      isActive: false,
      mobNav: false
    }
  },
  computed: {
    User() {
      return this.$store.state.user;
    },
    IsPaymentModal() {
      return this.$store.state.isPaymentModal;
    },
    ConfirmModal() {
      return this.$store.state.isCompleteModal;
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.$store.dispatch('getProfile');
    },
    logout() {
      localStorage.token = '';
      this.$router.push({name: 'Login'});
    },
    toggleNav() {
      this.mobNav = !this.mobNav;
    }
  }
}
</script>

<style lang="scss">
.AccountLayout {
  min-height: 100vh;
  background: #E5E5E5;
  position: relative;

  .no-padding {
    padding: 0;
  }

  .mob {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 80%;
  }

  .mobNav {
    background: #1B1A1C;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 0;
    min-height: 66px;


    &-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;


      .burger {
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .account-mob {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &-icon {
        border-radius: 90px;
        border: 2px solid #E5E5E5;
        outline: 1.5px solid #02C076;
        opacity: 100%;
        transition: .7s ease-in-out;
        max-width: 36px;
        width: 100%;
        height: 36px;

        img {
          width: 100%;
          height: 100%;
          -webkit-border-radius: 90px;
          -moz-border-radius: 90px;
          border-radius: 90px;
          -khtml-border-radius: 90px;
        }

      }
    }
  }

  .dashboard-nav, .dashboard-nav-mob {
    min-height: 100%;
    background-image: url('~@/assets/img/bg-form.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .logo {
      padding: 32px 32px 60px 32px;

      img {
        max-width: 100%;
      }
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #403C4E;

      .btn-link, a {
        color: white;
        opacity: 0.5;
        padding: 20px 0 20px 35px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        transition: .5s ease-in-out;

        img {
          margin-right: 20px;
        }

        &:hover {
          opacity: 1;
          background: rgba(255, 255, 255, 0.05);
          border-left: 3px solid #02C076;
          padding-left: 32px;
          font-size: 17px;
        }
      }

      .router-link-exact-active {
        opacity: 1;
        background: rgba(255, 255, 255, 0.05);
        border-left: 3px solid #02C076;
        padding-left: 32px;
        transition: .5s;
      }
    }

    .logout {
      .btn-link, a {
        color: white;
        opacity: 0.5;
        padding: 20px 0 20px 35px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        transition: .5s ease-in-out;

        img {
          margin-right: 20px;
        }

        &:hover {
          opacity: 1;
          background: rgba(255, 255, 255, 0.05);
          border-left: 3px solid #02C076;
          padding: 17px 0 17px 36px;
          font-size: 18px;
        }
      }
    }
  }

  .dashboard-nav-mob {
    .logo {
      padding: 0 15px 0 0;
      min-height: 66px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;


      .close {
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .account {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &-title {
      display: flex;
      flex-direction: column;
      margin-right: 14px;
      align-items: flex-end;

      .name {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.2px;
      }

      .subscribe {
        font-size: 12px;
        font-weight: 500;
        color: #02C076;
        letter-spacing: 0.2px;
      }
    }

    &-icon img {
      -webkit-border-radius: 90px;
      -moz-border-radius: 90px;
      border-radius: 90px;
      -khtml-border-radius: 90px;
      border: 2px solid #02C076;
      box-shadow: inset 0 0 0 3px white;
      height: 48px;
      max-width: 100%;
      width: 48px;
      max-height: 48px;
    }
  }

  .content {
    padding: 30px;
    min-height: 100vh;

    @media (max-width: 1079px) {
      padding: 0 12px 20px;
    }

    .block-margin {
      margin-bottom: 40px;

      @media (max-width: 1079px) {
        margin-bottom: 12px;
      }
    }

    .title {
      font-weight: 700;

      @media (max-width: 1079px) {
        padding: 15px 0;
      }

      h2 {
        font-weight: 700;
        margin: 0;
        line-height: 1;
        font-size: 24px;
      }
    }
  }

  .blur {
    filter: blur(10px);
  }
}
</style>
