<template>
  <svg width="207" height="48" viewBox="0 0 207 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M156.091 19.7199L151.434 19.2693V13.4863H147.454V15.6643C147.454 20.1706 149.256 21.7477 151.359 22.4237H147.454V27.6809C147.454 32.8631 150.007 34.966 154.964 34.966C159.921 34.966 162.474 32.9382 162.474 27.6809V27.005C162.474 20.8465 159.17 20.0204 156.091 19.7199ZM158.494 27.6809V31.2859H151.434V22.4237C152.185 22.649 153.011 22.7992 153.837 22.9494L158.494 23.6253V27.6809ZM168.032 13.4863C166.755 13.4863 165.929 14.3125 165.929 15.5892C165.929 16.866 166.755 17.6921 168.032 17.6921C169.309 17.6921 170.135 16.866 170.135 15.5892C170.135 14.3125 169.309 13.4863 168.032 13.4863ZM181.25 19.6448H177.72V16.0399H173.74C173.74 21.222 176.293 23.3249 181.25 23.3249H182.903V19.6448H181.25Z" fill="#EABC4B"/>
    <path d="M177.721 31.2856V22.7989H173.74V27.6806C173.74 32.8628 176.294 34.9657 181.25 34.9657H182.903V31.2856H177.721ZM166.004 19.6445H169.985V34.9657H166.004V19.6445Z" fill="#EABC4B"/>
    <path d="M111.479 13.4863C110.202 13.4863 109.376 14.3125 109.376 15.5892C109.376 16.866 110.202 17.6921 111.479 17.6921C112.756 17.6921 113.582 16.866 113.582 15.5892C113.582 14.3125 112.756 13.4863 111.479 13.4863ZM136.789 19.6448H129.278V21.5224C129.278 26.7046 131.832 28.8075 136.789 28.8075H144.299V26.9299C144.299 21.6726 141.671 19.6448 136.789 19.6448ZM133.259 25.4278V22.9494H140.319V25.4278H133.259ZM121.167 19.6448V16.0399H117.187C117.187 21.222 119.74 23.3249 124.697 23.3249H126.349V19.6448H121.167Z" fill="white"/>
    <path d="M121.167 31.2859V22.7992H117.187V27.6809C117.187 32.8631 119.74 34.966 124.697 34.966H126.349V31.2859H121.167ZM109.451 19.6448H113.432V34.966H109.451V19.6448ZM99.2369 19.7199L94.5805 19.2693V13.4863H90.6V15.6643C90.6 20.1706 92.4025 21.7477 94.5054 22.4237H90.6V34.966H94.5805V22.4237C95.3315 22.649 96.1577 22.7992 96.9838 22.9494L101.64 23.6253V34.966H105.621V27.005C105.621 20.8465 102.241 20.0204 99.2369 19.7199ZM82.4888 31.2859H77.6822V19.6448H73.7017V27.6809C73.7017 32.8631 76.2552 34.966 81.212 34.966H86.4693V19.6448H82.4888V31.2859Z" fill="white"/>
    <path d="M69.0454 31.2856V19.6445H65.0649V27.6806C65.0649 32.8628 67.6185 34.9657 72.5753 34.9657H74.2276V31.2856H69.0454ZM133.259 31.2856V28.2815H129.279C129.279 32.8628 131.832 34.9657 136.789 34.9657H143.473V31.2856H133.259Z" fill="white"/>
    <path d="M24.7343 19.1182C24.1334 20.0945 23.833 21.2211 23.833 22.4978H32.0193L35.399 19.1182H24.7343Z" fill="#EABC4B"/>
    <path d="M38.7037 15.7393H30.5174C28.0389 15.7393 25.8609 17.0911 24.7344 19.1189H35.324L38.7037 15.7393Z" fill="#24BCFF"/>
    <path d="M61.8355 8.9794H53.6492C49.9691 8.9794 46.965 11.9835 46.965 15.6636H38.7787L45.538 8.9043H37.3517C33.6716 8.9043 30.6675 11.9084 30.6675 15.5885V27.3798C30.6675 34.064 36.1501 39.5466 42.8343 39.5466H55.1513V15.7387L61.8355 8.9794ZM46.4393 26.4786C46.1713 26.2132 45.9586 25.8974 45.8135 25.5493C45.6683 25.2013 45.5936 24.8279 45.5936 24.4508C45.5936 24.0737 45.6683 23.7003 45.8135 23.3522C45.9586 23.0042 46.1713 22.6883 46.4393 22.423L50.4949 26.4786C50.2295 26.7465 49.9137 26.9592 49.5656 27.1044C49.2176 27.2495 48.8442 27.3243 48.4671 27.3243C48.09 27.3243 47.7166 27.2495 47.3685 27.1044C47.0205 26.9592 46.7046 26.7465 46.4393 26.4786Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "whiteCoinLogo"
}
</script>

<style scoped>

</style>
