import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import Clipboard from 'v-clipboard'
import VueNotify from 'vuejs-notify'
import VueLazyLoad from 'vue-lazyload'
import ScrollMixin from './mixins/scroll.mixin'
import VueHighlightJS from 'vue-highlightjs'
import checkView from 'vue-check-view'

Vue.use(VueNotify)
Vue.use(Vuelidate)
Vue.use(Clipboard)
Vue.use(VueHighlightJS)
Vue.use(VueLazyLoad)
Vue.mixin(ScrollMixin)
Vue.use(checkView)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
