<template>
  <AccountLayout>
    <template v-slot:title>
      <h2>Promo materials</h2>
    </template>
    <template v-slot:content>
      <div class="promo-block">
        <div class="promo-block-title">
          Banners
        </div>
        <div class="promo-block-row">
          <div class="col-4">
            <div class="promo-block-titles">
              Size
            </div>

          </div>
          <div class="col-2 center">
            <div class="promo-block-titles">
              Preview
            </div>
          </div>
          <div class="col-2">
            <div class="promo-block-titles end">
              Link to download
            </div>
          </div>
        </div>
        <div class="promo-block-items">
          <div class="promo-block-item" v-for="(item, index) in items" :key="index">
            <div class="promo-block-row">
              <div class="col-4 promo-block-item-info">
                <div class="promo-block-item-info-date">{{ item.size }}</div>
              </div>
              <div class="col-2 center">
                <div class="preview">
                  <img :src="siteLocation+item.preview" @click="showModal(item)">
                </div>
                <LightBox v-if="item.isShow" :showThumbs="false" :media="[{thumb: siteLocation+item.preview, src: siteLocation+item.preview }]"></LightBox>
              </div>
              <div class="col-2">
                <div class="promo-block-item-download">
                  <button class="link" @click="openPopUp(item)">
                    <img src="~@/assets/img/icons/code.svg">
                    Get code
                  </button>
                  <div v-if="item.isPopUp" class="popup">
                    <div class="popup-title">Code</div>
                    <div class="popup-code">
                      {{getLink(item)}}
                    </div>
                    <button class="btn-copy" @click="copyLink(item)">
                      <img src="~@/assets/img/icons/copy.svg">Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/AccountLayout";
import LightBox from 'vue-image-lightbox'

export default {
  name: "Promo",
  components: {
    AccountLayout,
    LightBox
  },
  data() {
    return {
      siteLocation : location.origin,
      items: [
        {
          size: '120x600',
          preview: '/img/banner/120x600.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '125x125',
          preview: '/img/banner/125x125.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '160x600',
          preview: '/img/banner/160x600.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '180x150',
          preview: '/img/banner/180x150.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '200x200',
          preview: '/img/banner/200x200.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '230x100',
          preview: '/img/banner/230x100.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '240x400',
          preview: '/img/banner/240x400.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '250x250',
          preview: '/img/banner/250x250.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '300x250',
          preview: '/img/banner/300x250.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '300x600',
          preview: '/img/banner/300x600.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '320x50',
          preview: '/img/banner/320x50.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '336x280',
          preview: '/img/banner/336x280.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '468x60',
          preview: '/img/banner/468x60.png',
          isPopUp:false,
          isShow:false,
        },
        {
          size: '728x90',
          preview: '/img/banner/728x90.png',
          isPopUp:false,
          isShow:false,
        },
      ],
    }
  },
  computed: {
    User() {
      return this.$store.state.user;
    }
  },
  methods: {
    getLink(item) {
      let partnerId = this.User.partnerId;

      return `
        <a href="https://swapuz.com/?ref=${partnerId}" target="_blank">
        <img src="https://swapuz.com${item.preview}"></a>
      `;
    },
    openPopUp(item) {
      this.items = this.items.map((el) => {
        el.isPopUp = !el.isPopUp && el.size === item.size;

        return el;
      })
    },
    showModal(item) {
      this.items = this.items.map((el) => {
        el.isShow = !el.isShow && el.size === item.size;

        return el;
      })
    },
    copyLink(item) {
      const html = this.getLink(item);
      this.$clipboard(html);
      this.$notify.info({
        position: 'top right',
        msg: 'Сopied!',
        timeout: 1000
      })
    }
  }
}
</script>

<style lang="scss">
@import '~vue-image-lightbox/dist/vue-image-lightbox.min.css';

.promo-block {
  background: white;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 32px;
  position: relative;

  .popup {
    position: absolute;
    z-index: 2;
    right: -16px;
    top: 50px;
    border: 1px solid #DFE0EB;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
    border-radius: 12px;

    &-title {
      color: #673DFD;
      padding-bottom: 10px;
    }

    &-code {
      max-width: 400px;
      padding: 5px;
      border: 1px solid #DFE0EB;
      border-radius: 12px;
      margin-bottom: 12px;
    }

    .btn-copy {
      background: #673DFD;
      border-radius: 8px;
      padding: 8px 42px 8px 8px;
      color: #F7F8FC;
      border: none;

      img {
        margin-right: 14px;
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
    align-items: center;

    .end {
      text-align: right;
    }

    .center {
      text-align: center;

      .preview {
        img {
          max-width: 100px;
          max-height: 56px;
        }
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    color: #1B1A1C;
    padding-bottom: 32px;
    padding-left: 32px;
  }

  &-titles {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;
    padding-bottom: 12px;
  }

  &-item {
    border-top: 1px solid #DFE0EB;
    padding: 20px 0;

    &-info {
      &-date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1B1A1C;
      }

      &-time {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }
    }

    &-download {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .link {
        padding: 10px 20px 10px 12px;
        color: #673DFD;
        display: flex;
        flex-direction: row;
        outline: none;
        border: none;
        background: none;
        border-radius: 4px;

        &:hover {
          background: rgba(103, 61, 253, 0.05);
        }

        img {
          padding-right: 6px;
        }
      }
    }
  }
}
</style>