<template>
    <div class="icon">
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33333 9.33366L12.6667 6.00033L9.33333 2.66699L8.39333 3.60699L10.1133 5.33366H4V6.66699H10.1133L8.39333 8.39366L9.33333 9.33366Z" fill="#DFE0EB"/>
            <path d="M10.6667 10.6667H1.33333V1.33333H10.6667V2.66667H12V1.33333C12 0.6 11.4067 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4067 12 12 11.4 12 10.6667V9.33333H10.6667V10.6667Z" fill="#DFE0EB"/>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "TokenHoverTo"
    }
</script>

<style scoped>

</style>