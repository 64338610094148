<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.47917 19.25V17.1875H5.5V15.8125H7.5625V6.1875H5.5V4.8125H8.47917V2.75H9.85417V4.8125H12.375V2.75H13.75V4.88125C14.575 5.04931 15.2396 5.45417 15.7437 6.09583C16.2479 6.7375 16.5 7.45556 16.5 8.25C16.5 8.67778 16.416 9.10174 16.2479 9.52187C16.0799 9.94201 15.8354 10.3201 15.5146 10.6562C16.1104 10.9618 16.5764 11.3934 16.9125 11.951C17.2486 12.5087 17.4167 13.1083 17.4167 13.75C17.4167 14.6972 17.0844 15.5069 16.4198 16.1792C15.7552 16.8514 14.9417 17.1875 13.9792 17.1875H13.75V19.25H12.375V17.1875H9.85417V19.25H8.47917ZM8.9375 10.3125H13.0625C13.6431 10.3125 14.1319 10.1101 14.5292 9.70521C14.9264 9.30035 15.125 8.81528 15.125 8.25C15.125 7.66944 14.9264 7.18056 14.5292 6.78333C14.1319 6.38611 13.6431 6.1875 13.0625 6.1875H8.9375V10.3125ZM8.9375 15.8125H13.9792C14.5597 15.8125 15.0486 15.6101 15.4458 15.2052C15.8431 14.8003 16.0417 14.3153 16.0417 13.75C16.0417 13.1694 15.8431 12.6806 15.4458 12.2833C15.0486 11.8861 14.5597 11.6875 13.9792 11.6875H8.9375V15.8125Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagCryptoServices"
}
</script>

<style scoped>

</style>
