<template>
    <div class="cards">
        <div @click="openOrderPayment" class="cards-item unpaid">
            <div class="cards-item-title">
                <div class="get">Get payment</div>
                <div class="cards-item-title-desc">BTC</div>

            </div>
            <div class="cards-item-info">
                <div class="cards-item-info-title">{{ User.unpaid }}</div>
                <div class="unpaid">Unpaid</div>
            </div>
        </div>
        <div @click="getLink" class="cards-item">
            <div class="cards-item-info-desc-mob">
                Copy
            </div>
            <div class="cards-item-title">Get link</div>
            <div class="cards-item-info">
                <div class="cards-item-info-title">
                    <img alt="Vue logo" src="~@/assets/img/icons/link.svg">
                </div>
                <div class="cards-item-info-desc d-none d-xl-block">{{ this.getUrl().slice(0, 40) + '...' }}</div>
            </div>
        </div>
        <div @click="getApiKey" class="cards-item">
            <div class="cards-item-info-desc-mob">
                Copy
            </div>
            <div class="cards-item-title">API key</div>
            <div class="cards-item-info">
                <div class="cards-item-info-title">
                    <img alt="Vue logo" src="~@/assets/img/icons/api-key.svg">
                </div>
                <div class="cards-item-info-desc d-none d-xl-block">{{ User.partnerId }}</div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Cards",
        data() {
            return {}
        },
        computed: {
            User() {
                return this.$store.state.user;
            },
        },

        methods: {
            getUrl() {
                return 'https://swapuz.com/?ref=' + this.User.partnerId;
            },
            getLink() {
                this.$clipboard(this.getUrl());
                this.copiedInfo();
            },
            getApiKey() {
                this.$clipboard(this.User.partnerId);
                this.copiedInfo();
            },
            copiedInfo() {
                this.$notify.info({
                    position: 'top right',
                    msg: 'Сopied!',
                    timeout: 1000
                })
            },
            openOrderPayment() {
                this.$store.commit('setter', {isPaymentModal: true})
            }
        },
    }
</script>

<style lang="scss">
    .cards {
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;

        &-item {
            display: flex;
            flex-direction: column;
            background: #673DFD;
            border-radius: 16px;
            padding: 20px 24px;
            cursor: pointer;
            width: 100%;
            color: white;

            @media (max-width: 1079px) {
                padding: 21px 10px;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: end;
            }

            &:nth-child(2) {
                margin: 0 30px;

                @media (max-width: 1079px) {
                    margin: 0 6px;
                }
            }

            &-title {
                font-size: 20px;
                font-weight: 700;
            }

            &-info {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: flex-end;

                @media (max-width: 1079px) {
                    flex-direction: column;
                    align-items: center;
                }

                &-icon {
                    text-align: right;
                }

                &-title {
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 48px;
                    text-align: right;

                    @media (max-width: 1079px) {
                        font-size: 24px;
                        padding-bottom: 9px;
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                &-desc {
                    opacity: 0.6;
                    font-size: 14px;
                    font-weight: 500;

                    &-mob {
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(255, 255, 255, 0.6);
                        font-weight: 400;
                        display: none;

                        @media (max-width: 1079px) {
                            display: block;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }

        .unpaid {
            background: #F64E60;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: 1079px) {
                flex-direction: column-reverse;
                align-items: center;
            }

            .cards-item-title {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                &-desc {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.6);

                    @media (max-width: 1079px) {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 500;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.6);
                        margin-top: 10px;
                    }
                }

                .get {
                    font-size: 20px;
                    line-height: 24px;

                    @media (max-width: 1079px) {
                        text-align: center;
                    }
                }
            }

            .cards-item-info {
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;

                @media (max-width: 1079px) {
                    flex-direction: column-reverse;
                }

                .unpaid {
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, 0.6);
                    font-weight: 400;
                }

                &-title {
                    line-height: 48px;
                }
            }
        }
    }
</style>
