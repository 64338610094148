<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 17.9378L7 10.9378L8.25417 9.68359L14 15.4586L19.7458 9.71276L21 10.9669L14 17.9378Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "faqArrow"
}
</script>

<style scoped>

</style>
