<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_864_4316)">
            <path d="M15.5001 13.9996H14.7101L14.4301 13.7296C15.6301 12.3296 16.2501 10.4196 15.9101 8.38965C15.4401 5.60965 13.1201 3.38965 10.3201 3.04965C6.09014 2.52965 2.53014 6.08965 3.05014 10.3196C3.39014 13.1196 5.61014 15.4396 8.39014 15.9096C10.4201 16.2496 12.3301 15.6296 13.7301 14.4296L14.0001 14.7096V15.4996L18.2501 19.7496C18.6601 20.1596 19.3301 20.1596 19.7401 19.7496C20.1501 19.3396 20.1501 18.6696 19.7401 18.2596L15.5001 13.9996ZM9.50014 13.9996C7.01014 13.9996 5.00014 11.9896 5.00014 9.49965C5.00014 7.00965 7.01014 4.99965 9.50014 4.99965C11.9901 4.99965 14.0001 7.00965 14.0001 9.49965C14.0001 11.9896 11.9901 13.9996 9.50014 13.9996Z"
                  fill="#673DFD"/>
        </g>
        <defs>
            <clipPath id="clip0_864_4316">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'SearchIcon'
}
</script>
<style scoped>

</style>
