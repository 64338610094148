<template>
    <div class="statistics">
        <div class="statistics-title">
            Transactions and payouts
            <date-picker
                    :clearable="false"
                    :format="dateFormat"
                    default-panel="day"
                    range-separator=" - "
                    value-type="format"
                    @change="getStats"
                    :editable="false"
                    v-model="dates" range>
                <template v-slot:input>
                </template>
            </date-picker>

        </div>
        <div class="statistics-chart">
            <div class="statistics-chart-info">
                <div class="statistics-chart-info-transactions">
                    <img src="~@/assets/img/icons/transactions.svg">
                    Transactions
                </div>
                <div class="statistics-chart-info-payouts">
                    <img src="~@/assets/img/icons/payouts.svg">
                    Payouts
                </div>
            </div>
            <highcharts :options="chartOptions"></highcharts>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import PartnerService from '../../../services/partner.service'
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment';

    export default {
        name: "Statistics",
        components: {
            highcharts: Chart,
            DatePicker
        },
        data() {
            return {
                dateFormat: 'DD.MM.YYYY',
                queryDateFormat: 'YYYY-MM-DD',
                dates: [],
                formatLocale: {},
                chartOptions: {
                    chart: {
                        type: 'areaspline',
                    },
                    series: [],
                    title: '',
                    credits: {
                        enabled: false
                    },
                    colors: ['#02C076', '#673DFD'],
                    xAxis: {
                        title: false,
                        crosshair: true,
                        labels: {
                            format: '{value}'
                        },
                        maxPadding: 0.05,
                    },
                    yAxis: [
                        {
                            title: false,
                            labels: {
                                format: '{value}'
                            },
                        },
                    ],
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '{point.x}<br/>',
                        pointFormat: '{point.value}',
                        footerFormat: '{point.value} BTC <br> <b>{point.y} transactions</b>',
                        backgroundColor: '#DCDFFF',
                        borderRadius: 10,
                        borderWidth: 0,
                        shadow: false,
                        stickOnContact: true,
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            lineWidth: 3,
                            fillColor: {
                                linearGradient: [0, 0, 1100, 0],
                                stops: [
                                    [0, 'rgba(146,112,210,0.14)'],
                                    [1, 'rgba(250,250,250,0)']
                                ]
                            },
                        },
                    },
                }
            }
        },
        mounted() {
            this.setDefaultDates();
            this.getStats();
        },
        methods: {
            setDefaultDates() {
                this.dates = [moment().subtract(1, 'month').format(this.dateFormat), moment().format(this.dateFormat)];
            },
            getStats() {
                const dates = this.dates;
                const startDate = this.parseDate(dates[0]);
                const endDate = this.parseDate(dates[1]);
                const range = `?start=${startDate}&end=${endDate}`;

                PartnerService.getStats(range).then((result) => {
                    if (result.status === 200 && result.data) {
                        const data = result.data.result;
                        const categories = [];
                        const series = [
                            {
                                name: 'Transaction',
                                data: [],
                            },
                            {
                                name: 'Payouts',
                                data: [],
                            },
                        ];

                        data.forEach((item) => {
                            series[0].data.push(item.cnt);
                            series[1].data.push({y: parseFloat(item.profit) * 1000, 'value': parseFloat(item.profit)});
                            categories.push(moment(item.createDate).format('DD.MM.YYYY'));
                        });

                        this.chartOptions.series = series;
                        this.chartOptions.xAxis.categories = categories;
                    }
                })
            },
            parseDate(date) {
                const changeFormat = date.split('.');

                return changeFormat[2] + '-' + changeFormat[1] + '-' + changeFormat[0]
            }
        }
    }
</script>

<style lang="scss">
    $default-color: #1B1A1C;
    $primary-color: #673DFD;

    @import "~vue2-datepicker/scss/index.scss";

    .mx-datepicker-main {
        font-family: 'Inter', sans-serif;
    }

    .mx-datepicker-popup {
        border-radius: 12px;
        padding: 24px 0;
        box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
    }

    .mx-calendar + .mx-calendar {
        border: none;
    }

    .mx-calendar {
        padding: 0 24px;
    }

    .mx-datepicker-range {
        width: 100%;
        max-width: 202px;
        margin-left: 20px;

        @media (max-width: 1079px) {
            margin-left: 0;
        }
    }

    .mx-datepicker svg {
        fill: #3751FF;
    }

    .mx-input {
        background: rgba(103, 61, 253, 0.04);
        border-radius: 8px;
        border: none;
        outline: none;
        box-shadow: none;
        color: $primary-color;
        font-weight: 500;
        font-size: 14px;
        padding: 8px 0 8px 37px;

        @media (max-width: 764px) {
            margin-left: 0;
            margin-top: 10px;
        }
    }

    .mx-icon-calendar, .mx-icon-clear {
        left: 9px;
        right: unset;

        @media (max-width: 764px) {
            margin-top: 5px;
        }
    }

    .statistics {
        background: white;
        padding: 32px;
        border-radius: 16px;
        border: 1px solid #DFE0EB;
        margin-bottom: 20px;

        @media (max-width: 764px) {
            padding: 12px;
        }

        &-title {
            font-size: 20px;
            color: #1B1A1C;
            font-weight: 700;
            display: flex;
            flex-direction: row;
            padding-bottom: 32px;

            @media (max-width: 764px) {
                flex-direction: column;
            }
        }

        &-chart {
            &-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 14px;
                line-height: 16px;
                color: #9FA2B4;
                padding-bottom: 24px;
            }
        }
    }
</style>
