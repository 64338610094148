<template>
    <div class="polygon">
        <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5119 0.745743C9.71431 -0.17518 8.28569 -0.17518 7.48814 0.745743L1.47364 7.69069C0.351888 8.98598 1.27199 11 2.9855 11H15.0145C16.728 11 17.6481 8.98598 16.5264 7.69069L10.5119 0.745743Z" fill="#1B1A1C"/>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "polygonHover"
    }
</script>

<style lang="scss">

</style>