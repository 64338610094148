<template>
  <div class="tools">
    <div class="container-l">
      <div class="h1">
        Tools
      </div>
      <div class="tools-items">
        <div class="tools-items--item">
          <div class="icon">
            <tools-api/>
          </div>
          <div class="info">
            <div class="info-title">
              Swapuz API
            </div>
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-crypto-services/>
                <div class="info-tags__item-text">
                  Crypto services
                </div>
              </div>
              <div class="info-tags__item">
                <tag-aggregators/>
                <div class="info-tags__item-text">
                  Aggregators
                </div>
              </div>
              <div class="info-tags__item">
                <tag-wallets/>
                <div class="info-tags__item-text">
                  Wallets
                </div>
              </div>
              <div class="info-tags__item">
                <tag-payment-systems/>
                <div class="info-tags__item-text">
                  Payment systems
                </div>
              </div>
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
            </div>
            <div class="info-text">
              A wide range of functions in one API. <br>
              24/7 developer support and easy implementation into your services.
            </div>
            <div @click="anchorScroll('swapuzApi')" class="info-button">
              Details
              <button-arrow/>
            </div>
          </div>
        </div>
        <div  class="tools-items--item">
          <div class="icon">
            <tools-widget/>
          </div>
          <div class="info">
            <div class="info-title">
              Exchange widget
            </div>
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-crypto-services/>
                <div class="info-tags__item-text">
                  Crypto services
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-payment-systems/>
                <div class="info-tags__item-text">
                  Payment systems
                </div>
              </div>
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
            </div>
            <div class="info-text">
              Extended functionality for the user - good earnings for the service.
            </div>
            <div @click="anchorScroll('exchangeWidget')" class="info-button">
              Details
              <button-arrow/>
            </div>
          </div>
        </div>
        <div class="tools-items--item">
          <div class="icon">
            <tools-referal/>
          </div>
          <div class="info">
            <div class="info-title">
              Referral links
            </div>
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-coins-websites/>
                <div class="info-tags__item-text">
                  Coins websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
              <div class="info-tags__item">
                <tag-media-buyers/>
                <div class="info-tags__item-text">
                  Media buyers
                </div>
              </div>
            </div>
            <div class="info-text">
              No need to program anything, just share the link.
            </div>
            <div @click="anchorScroll('referralLinks')" class="info-button">
              Details
              <button-arrow/>
            </div>
          </div>
        </div>
        <div class="tools-items--item">
          <div class="icon">
            <tools-banners/>
          </div>
          <div class="info">
            <div class="info-title">
              Banners
            </div>
            <div class="info-desc">
              Best for
            </div>
            <div class="info-tags">
              <div class="info-tags__item">
                <tag-media-buyers/>
                <div class="info-tags__item-text">
                  Media buyers
                </div>
              </div>
              <div class="info-tags__item">
                <tag-news-websites/>
                <div class="info-tags__item-text">
                  News websites
                </div>
              </div>
            </div>
            <div class="info-text">
              An updatable media kit that won't bore your users.
            </div>
            <div @click="anchorScroll('bannersBlock')" class="info-button">
              Details
              <button-arrow/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolsApi from "@/assets/img/svg/toolsApi";
import ToolsWidget from "@/assets/img/svg/toolsWidget";
import ToolsReferal from "@/assets/img/svg/toolsReferal";
import ButtonArrow from "@/assets/img/svg/buttonArrow";
import ToolsBanners from "@/assets/img/svg/toolsBanners";
import TagCryptoServices from "@/assets/img/svg/tags/tagCryptoServices";
import TagAggregators from "@/assets/img/svg/tags/tagAggregators";
import TagWallets from "@/assets/img/svg/tags/tagWallets";
import TagPaymentSystems from "@/assets/img/svg/tags/tagPaymentSystems";
import TagCoinsWebsites from "@/assets/img/svg/tags/tagCoinsWebsites";
import TagNewsWebsites from "@/assets/img/svg/tags/tagNewsWebsites";
import TagMediaBuyers from "@/assets/img/svg/tags/tagMediaBuyers";
import {gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "ToolsBlock",
  components: {
    TagMediaBuyers,
    TagNewsWebsites,
    TagCoinsWebsites,
    TagPaymentSystems,
    TagWallets,
    TagAggregators, TagCryptoServices, ToolsBanners, ButtonArrow, ToolsReferal, ToolsWidget, ToolsApi
  },
  methods: {
    anchorScroll(id){
      gsap.to(window, {duration: 1, scrollTo: {y:  "#" + id , offsetY: 50}, ease: "power1"});
    },
  }
}
</script>

<style lang="scss">
.tools {
  width: 100%;
  margin-bottom: 120px;

  @media (max-width: 1079px) {
    margin-bottom: 48px;
  }

  .h1 {
    margin-bottom: 56px;

    @media (max-width: 1079px) {
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 24px;
    }
  }

  &-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 72px 40px;
    box-sizing: border-box;

    @media (max-width: 1079px) {
      padding: 0 4px;
      gap: 32px 0;
    }

    &--item {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 520px;
      width: 100%;

      @media (max-width: 1079px) {
        flex-direction: row-reverse;
        position: relative;
        max-width: 100%;
      }

      .icon {
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1079px) {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1079px) {
          width: 100%;
        }

        &-title {
          font-weight: 900;
          font-size: 36px;
          line-height: 38px;
          letter-spacing: -0.02em;
          color: #FFFFFF;
          margin-bottom: 12px;

          @media (max-width: 1079px) {
            max-width: 78.4%;
            width: 100%;
            font-size: 28px;
            line-height: 30px;
          }
        }

        &-desc {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.38);
          margin-bottom: 6px;

          @media (max-width: 1079px) {
            font-size: 14px;
            line-height: 22px;
          }
        }

        &-tags {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 6px 20px;
          align-items: center;
          max-width: 361px;

          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            &-text {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #02C076;
            }
          }
        }

        &-text {
          margin: 16px 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #FFFFFF;
          opacity: 0.8;
        }

        &-button {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background: #02C076;
          backdrop-filter: blur(13.5914px);
          border-radius: 8px;
          padding: 8px 12px;
          min-width: 116px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #1B1A1C;
          transition: all .3s ease-in-out;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 2px 32px rgba(2, 192, 118, 0.2);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
