<template>
  <div class="api-block">
    <div class="title">
      Get Limits
    </div>
    <div class="desc">
      This request is using API Key from collection partner
    </div>
    <div class="api-block_code">
      <pre v-highlightjs><code class="javascript" id="getLimits">curl --location -g --request GET 'https://api.swapuz.com/api/home/getLimits?coin={coin}'</code></pre>
      <div @click="copyFunction" class="api-block_code-copy">
        <copy-text />
      </div>
    </div>
    <div class="api-block_code-title">
      Response:
    </div>
    <div class="api-block_code api-block_code--response">
      <pre v-highlightjs><code class="json" >{
  "result": {
    "coin": "BTC",
    "minAmount": 0.009839665168335445,
    "maxAmount": 0.7379748876251584
  },
  "status": 200
}</code></pre>
    </div>
  </div>
</template>

<script>
import CopyText from "@/assets/img/svg/copyText.vue";

export default {
  name: "GetLimits",
  components: {CopyText},
  methods: {
    copyFunction() {
      const copyText = document.getElementById("getLimits").textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style scoped>

</style>
