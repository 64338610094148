import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index';
import UserService from '../services/user.service'
import OrderService from '../services/order.service'
import PaymentService from '../services/payment.service'
import PartnerService from '../services/partner.service'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: '',
        user: {
            partnerProfile: {
                address: '',
                avatar: '',
                email: '',
                telegramm: '',
                minPayment: '',
            }
        },
        transactions: [],
        isPaymentModal: false,
        isCompleteModal: false,
    },
    mutations: {
        setter(state, object) {
            Object.entries(object).forEach(([key, value]) => {
                Vue.set(state, key, value);
            });
        },
    },
    actions: {
        logout() {
            localStorage.token = '';
            router.push({name: 'Home'});
        },
        login({commit}, credential) {
            return UserService.login(credential)
                .then((result) => {
                    if (result.status === 200) {
                        const token = result.data.result.token;
                        localStorage.token = token;
                        commit('setter', {token: token});
                    } else {
                        throw new Error('Invalid login or password');
                    }
                })
                .catch((error) => {
                    throw error;
                });
        },
        registration({commit}, credential) {
            return UserService.register(credential).then((result) => {

                if (result.status === 200) {
                    const token = result.data.result.token;
                    localStorage.token = token;
                    commit('setter', {token: token});
                }
            }).catch((error) => {
                throw new Error('User registration failed ' + error)
            })
        },
        getProfile({commit}) {
            return UserService.getProfile().then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('Get profile failed ' + error)
            })
        },
        editProfile({commit}, data) {
            return UserService.editProfile(data).then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('User updates failed ' + error)
            })
        },
        changePassword({commit}, data) {
            return UserService.changePassword(data).then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('User password change failed ' + error)
            })
        },
        getOrderList({commit}) {
            return OrderService.getOrderList().then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('Get profile failed ' + error)
            })
        },
        createOrder({commit}, data) {
            return PaymentService.createPayment(data).then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('Create error failed ' + error)
            })
        },
        getPartnerPaginator({commit}) {
            return PartnerService.partnerPaginator().then((result) => {

                if (result.status === 200) {
                    commit('setter', {user: result.data.result});
                }
            }).catch((error) => {
                throw new Error('Get partner paginator failed ' + error)
            })
        }
    },
    modules: {}
})
