<template>
    <div class="transactions">
        <div class="transactions-title">
            Transactions and payouts
        </div>
        <Empty v-if="!transactions.length"/>
        <Payouts v-else :transactions="transactions" />

    </div>
</template>

<script>
    import Empty from "./transactions/Empty";
    import Payouts from "./transactions/Payouts";
    import OrderService from '../../../services/order.service'
    import PartnerService from '../../../services/partner.service'

    export default {
        name: "Transactions",
        components: {
            Payouts,
            Empty
        },
        data() {
            return {
                transactions: []
            }
        },
        mounted() {
            this.getTransactions();
            this.getPartnerPage();
        },
        methods: {
            getTransactions() {
                OrderService.getOrderList().then((response) => {
                    this.transactions = response.data.result;
                })
            },
            getPartnerPage() {
                PartnerService.partnerPaginator().then((result) => {
                    console.log(result.data.result);
                })
            }
        }
    }
</script>

<style lang="scss">
    .transactions {
        background: white;
        border-radius: 16px;
        border: 1px solid #DFE0EB;

        &-title {
            font-size: 20px;
            color: #1B1A1C;
            font-weight: 700;
            padding-left: 32px;
            padding-top: 32px;
        }

        .bg {
            display: flex;
            justify-content: center;
            padding: 12px 0 59px;
        }
    }
</style>
