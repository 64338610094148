<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.85911 12.3747H13.0154V10.9997H7.85911V12.3747ZM7.85911 10.3122H16.9112V8.93717H7.85911V10.3122ZM7.85911 8.24967H16.9112V6.87467H7.85911V8.24967ZM5.95703 17.4163C5.59036 17.4163 5.26953 17.2788 4.99453 17.0038C4.71953 16.7288 4.58203 16.408 4.58203 16.0413V3.20801C4.58203 2.84134 4.71953 2.52051 4.99453 2.24551C5.26953 1.97051 5.59036 1.83301 5.95703 1.83301H18.7904C19.157 1.83301 19.4779 1.97051 19.7529 2.24551C20.0279 2.52051 20.1654 2.84134 20.1654 3.20801V16.0413C20.1654 16.408 20.0279 16.7288 19.7529 17.0038C19.4779 17.2788 19.157 17.4163 18.7904 17.4163H5.95703ZM5.95703 16.0413H18.7904V3.20801H5.95703V16.0413ZM3.20703 20.1663C2.84036 20.1663 2.51953 20.0288 2.24453 19.7538C1.96953 19.4788 1.83203 19.158 1.83203 18.7913V4.58301H3.20703V18.7913H17.4154V20.1663H3.20703ZM5.95703 3.20801V16.0413V3.20801Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "tagAggregators"
}
</script>

<style scoped>

</style>
