<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.41797 25.667C5.93186 25.667 5.51866 25.4969 5.17839 25.1566C4.83811 24.8163 4.66797 24.4031 4.66797 23.917V11.2587C4.66797 10.7725 4.83811 10.3594 5.17839 10.0191C5.51866 9.6788 5.93186 9.50866 6.41797 9.50866H8.45964V6.70866C8.45964 5.17255 8.99922 3.86491 10.0784 2.78574C11.1576 1.70658 12.4652 1.16699 14.0013 1.16699C15.5374 1.16699 16.8451 1.70658 17.9242 2.78574C19.0034 3.86491 19.543 5.17255 19.543 6.70866V9.50866H21.5846C22.0707 9.50866 22.4839 9.6788 22.8242 10.0191C23.1645 10.3594 23.3346 10.7725 23.3346 11.2587V23.917C23.3346 24.4031 23.1645 24.8163 22.8242 25.1566C22.4839 25.4969 22.0707 25.667 21.5846 25.667H6.41797ZM6.41797 23.917H21.5846V11.2587H6.41797V23.917ZM14.0013 19.8337C14.6235 19.8337 15.1534 19.6198 15.5909 19.192C16.0284 18.7642 16.2471 18.2489 16.2471 17.6462C16.2471 17.0628 16.0284 16.533 15.5909 16.0566C15.1534 15.5802 14.6235 15.342 14.0013 15.342C13.3791 15.342 12.8492 15.5802 12.4117 16.0566C11.9742 16.533 11.7555 17.0628 11.7555 17.6462C11.7555 18.2489 11.9742 18.7642 12.4117 19.192C12.8492 19.6198 13.3791 19.8337 14.0013 19.8337ZM10.2096 9.50866H17.793V6.70866C17.793 5.65866 17.4235 4.76421 16.6846 4.02533C15.9457 3.28644 15.0513 2.91699 14.0013 2.91699C12.9513 2.91699 12.0569 3.28644 11.318 4.02533C10.5791 4.76421 10.2096 5.65866 10.2096 6.70866V9.50866ZM6.41797 23.917V11.2587V23.917Z" fill="white" fill-opacity="0.38"/>
  </svg>
</template>

<script>
export default {
  name: "PasswordInput"
}
</script>

<style scoped>

</style>
