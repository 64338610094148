<template>
    <AccountLayout>
        <template v-slot:title>
            <h2>All transactions</h2>
        </template>
        <template v-slot:content>
            <div class="lastTransactions">
                <div class="lastTransactions-title">
                    All transactions
                </div>
                <Empty v-if="!Transactions.length"/>
                <Payouts v-else/>
            </div>
            <div v-if="Transactions.length" class="pages">
                <paginate
                        :pageCount="maxPage"
                        active-class="active-page"
                        page-class="default-page"
                        :containerClass="'pagination'"
                        :click-handler="paginateHandler">
                    >

                    <span class="prev" slot="prevContent"><PrevIcon v-if="maxPage > 1"/></span>
                    <span class="next" slot="nextContent"><NextIcon v-if="maxPage > 1"/></span>
                </paginate>
            </div>
        </template>
    </AccountLayout>
</template>

<script>
    import AccountLayout from "../../components/AccountLayout";
    import Empty from "../dashboard/partials/transactions/Empty";
    import Payouts from "../dashboard/partials/transactions/Payouts";
    import PartnerService from '../../services/partner.service'
    import Paginate from 'vuejs-paginate'
    import PrevIcon from "../../icons/PrevIcon";
    import NextIcon from "../../icons/NextIcon";

    export default {
        name: "LastTransactions",
        components: {Payouts, Empty, AccountLayout, Paginate, PrevIcon, NextIcon},
        data() {
            return {
                maxPage: 0,
            }
        },
        mounted() {
            this.getPartnerPage()
        },
        computed: {
            Transactions: {
                get() {
                    return this.$store.state.transactions;
                },
                set(value) {
                    this.$store.commit('setter', {transactions: value});
                }
            },
        },
        methods: {
            getPartnerPage(page = 1) {
                PartnerService.partnerPaginator(page).then((response) => {
                    this.Transactions = response.data.result.result;

                  if (response.data.result.maxPage) {
                        this.maxPage = response.data.result.maxPage;
                    }
                })
            },
            paginateHandler(page) {
                this.getPartnerPage(page);
            }
        }
    }
</script>

<style lang="scss">
    .lastTransactions {
        background: white;
        border-radius: 16px;
        border: 1px solid #DFE0EB;

        &-title {
            font-size: 20px;
            color: #1B1A1C;
            font-weight: 700;
            padding-left: 32px;
            padding-top: 32px;
        }
    }

    .pages {
        width: 100%;
        margin-top: 32px;

        .pagination {
            justify-content: center;
            align-items: center;

            li {
                margin: 0 1px;
            }

            .next, .prev {
                padding: 10.5px 11px;
                border-radius: 8px;
                text-align: center;
                margin: 0 4px;
                transition: all .5s ease-in-out;

                &:empty {
                    display: none;
                }

                &:hover {
                    background: #673DFD;

                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }

            .default-page {
                border-radius: 8px;
                vertical-align: center;
                text-align: center;
                width: 40px;
                padding: 10px 0;
                color: #1B1A1C;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                transition: all .5s ease-in-out;

                a {
                    color: #1B1A1C;

                    &:hover {
                        color: #673DFD;
                    }
                }

                &:hover {
                    background-color: rgba(103, 61, 253, 0.06);
                }
            }

            .active-page {
                background: #673DFD;
                border-radius: 8px;
                vertical-align: center;
                text-align: center;
                width: 40px;
                padding: 10px 0;

                a {
                    color: #FFFFFF;

                    &:hover {
                        color: #FFFFFF;
                        cursor: default;
                    }
                }

                &:hover {
                    background: #673DFD;
                }
            }
        }
    }
</style>
